
import { useAuthState } from '@contexts/AuthContext';
import MentorCourses from './components/MentorCourses';
import StudentCourses from './components/StudentCourses';

const Courses: React.FC = () => {
  const { isMentor } = useAuthState();

  return (
    <div className="h-auto min-h-screen bg-surfaceBackground">
      {isMentor ? <MentorCourses /> : <StudentCourses />}
    </div>
  );
};

export default Courses;
