import { Typography } from 'antd';
import styles from './agora-input-error.module.scss';

type Props = {
  errorText: string;
};

/**
 * @deprecated
 */
const AgoraInputError = ({ errorText }: Props) => {
  return (
    <div className={styles['agora-input-error']}>
      <Typography.Text>{errorText}</Typography.Text>
    </div>
  );
};

export default AgoraInputError;
