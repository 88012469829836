import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/empty-states/sad-calendar.svg';
import MeetingModal from '@components/MeetingModal/MeetingModal';
import { useModal } from '@hooks';
import style from '@styles/views/home.module.scss';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import NextMeetingMentor from './NextMeetingMentor';
import moment from 'moment';
import { MEETINGS_PAGE_LIMIT } from '@shared/constants';
import SkeletonBox from '@components/V4/SkeletonBox';
import DotPagination from '@components/V4/DotPagination';
import { useState } from 'react';
import { useUpcomingMeetings } from '@actions';

type Props = {
  isMentor?: boolean;
};

const NextMeetingsMentor = ({ isMentor }: Props) => {
  const [page, setPage] = useState(1);

  const { data, isLoading } = useUpcomingMeetings(page);

  const { data: meetings, total } = data || {};

  const [Modal, show] = useModal(MeetingModal);

  if (!total && !isLoading) {
    return (
      <>
        {Modal}
        <EmptyStateCard
          icon={<EmptyStateIcon />}
          text={
            <>
              You have no meetings lined up...
              <br />
              Keep things rolling by scheduling your next meeting with your students!
            </>
          }
          buttonText="Add Meeting"
          onClick={() =>
            show({
              date: moment(),
              isOnHomePage: true,
            })
          }
        />
      </>
    );
  }

  return (
    <div className={style.meetings}>
      {isLoading ? (
        <div className="flex flex-col gap-5 mb-14">
          {[...Array(MEETINGS_PAGE_LIMIT).keys()].map((_, index) => (
            <SkeletonBox className="h-18 w-full" key={index} />
          ))}
        </div>
      ) : (
        <>
          {meetings?.map((meeting) => (
            <NextMeetingMentor key={meeting._id} meeting={meeting} isMentor={isMentor} />
          ))}
        </>
      )}
      <DotPagination
        isVisible={isLoading || (!!total && total > MEETINGS_PAGE_LIMIT)}
        itemCount={meetings?.length}
        isDisabled={isLoading}
        page={page}
        noOfPages={Math.ceil((total || 0) / MEETINGS_PAGE_LIMIT)}
        onSetPage={setPage}
      />
    </div>
  );
};

export default NextMeetingsMentor;
