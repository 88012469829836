import { useGetSessionDetails } from '@actions';
import { MeetingModal } from '@components';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import {
  Feedback,
  default as MentorFeedbackModal,
} from '@modules/Mentor/components/FeedbackModal/FeedbackModal';
import SessionDetailsChat from '@modules/SessionDetails/SessionDetailsChat/SessionDetailsChat';
import SessionDetailsFeedback, {
  FeedbackSection,
} from '@modules/SessionDetails/SessionDetailsFeedback';
import SessionDetailsHeader from '@modules/SessionDetails/SessionDetailsHeader/SessionDetailsHeader';
import SessionDetailsNotes from '@modules/SessionDetails/SessionDetailsNotes/SessionDetailsNotes';
import SessionDetailsTranscript from '@modules/SessionDetails/SessionDetailsTranscript/SessionDetailsTranscript';
import { default as StudentFeedbackModal } from '@modules/Student/components/FeedbackModal/FeedbackModal';
import { SessionStatus } from '@shared/constants';
import { useAuthState } from '@contexts/AuthContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { createContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const SessionDetailsContext = createContext({ isLoading: true });

const SessionDetails = () => {
  const { sessionId } = useParams<{ sessionId: string }>();

  const [isMentorFeedbackOpen, setIsMentorFeedbackOpen] = useState(false);
  const [isStudentFeedbackOpen, setIsStudentFeedbackOpen] = useState(false);
  const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
  const [feedback, setFeedback] = useState<Feedback>({});

  const { isMentor } = useAuthState();

  const history = useHistory();
  const toast = useToast();

  const fallbackColor = '#36B3A8';

  const { data: sessionData, isLoading } = useGetSessionDetails(sessionId, {
    onError: (err: any) => {
      history.replace('/home');
      toast.error(err?.response?.data?.error?.message || 'An unexpected error occurred.');
    },
  });

  if (!isLoading && !sessionData) {
    return <></>;
  }

  const accentColor = sessionData?.course.color ?? fallbackColor;

  // FILLED WITH MOCKUP DATA
  const mentorFeedbackSections: FeedbackSection[] = [
    {
      label: 'Session Summary',
      value: sessionData?.mentorFeedback?.sessionSummary,
    },
    {
      label: 'Topics Covered Next',
      value: sessionData?.mentorFeedback?.nextTopics,
    },
    {
      label: 'Homework',
      value: sessionData?.mentorFeedback?.homework?.explanation,
      starRating: sessionData?.mentorFeedback?.homework?.rate,
    },
    {
      label: 'Engagement',
      value: sessionData?.mentorFeedback?.engagement?.explanation,
      starRating: sessionData?.mentorFeedback?.engagement?.rate,
    },
    {
      label: 'Progress',
      value: sessionData?.mentorFeedback?.progress?.explanation,
      starRating: sessionData?.mentorFeedback?.progress?.rate,
    },
    {
      label: 'Parent Help',
      value: sessionData?.mentorFeedback?.parentHelp,
    },
  ];

  const studentFeedbackSections: FeedbackSection[] = [
    {
      label: 'Session Rating',
      starRating: sessionData?.studentFeedback?.rating,
      ratingPosition: 'below',
    },
    // { label: 'Mentor Traits', value: 'NOT IMPLEMENTED YET' },
    {
      label: 'Feedback',
      value: sessionData?.studentFeedback?.message,
    },
  ];

  return (
    <section className="flex flex-col gap-4 px-4 py-4 tablet:px-6 tablet:py-6 laptop:m-auto laptop:gap-8 largeLaptop:px-0 max-w-[1126px]">
      <SessionDetailsContext.Provider value={{ isLoading }}>
        <SessionDetailsHeader
          onMeetingModalOpen={() => setIsMeetingModalOpen(true)}
          setIsMentorFeedbackOpen={setIsMentorFeedbackOpen}
          {...sessionData}
          abbreviation={sessionData?.course?.abbreviation}
          accentColor={accentColor}
        />

        <section className="flex flex-col gap-4 laptop:flex-row laptop:gap-8 largeLaptop:gap-14">
          <aside className="flex flex-col gap-4 tablet:order-2 laptop:gap-8 laptop:w-1/3 largeLaptop:min-w-[440px]">
            <SessionDetailsNotes
              notes={sessionData?.notes}
              meetingId={sessionData?.relevantMeetingId}
            />

            <SessionDetailsChat
              chatMessages={sessionData?.chatHistory}
              startDate={sessionData?.startDate}
              accentColor={accentColor}
            />

            {isMentor && (
              <SessionDetailsTranscript
                transcript={sessionData?.transcript}
                accentColor={accentColor}
                startDate={sessionData?.startDate}
              />
            )}
          </aside>

          <section className="flex flex-col gap-4 tablet:order-1 laptop:gap-8 laptop:w-2/3 largeLaptop:min-w-[630px]">
            <SessionDetailsFeedback
              isMentorFeedback
              className="tablet:order-2"
              feedbackSections={mentorFeedbackSections}
              needsFeedback={
                sessionData?.sessionStatus
                  ? sessionData.sessionStatus === SessionStatus.NEEDS_FEEDBACK
                  : false
              }
              accentColor={accentColor}
              hasFeedback={!!sessionData?.mentorFeedback?.engagement}
              setIsMentorFeedbackOpen={setIsMentorFeedbackOpen}
              setIsStudentFeedbackOpen={setIsStudentFeedbackOpen}
            />
            <SessionDetailsFeedback
              className="tablet:order-1"
              feedbackSections={studentFeedbackSections}
              accentColor={accentColor}
              needsFeedback={!!sessionData?.relevantMeetingId}
              areasOfImprovement={sessionData?.areasOfImprovement}
              hasFeedback={!!sessionData?.studentFeedback?.message}
              setIsMentorFeedbackOpen={setIsMentorFeedbackOpen}
              setIsStudentFeedbackOpen={setIsStudentFeedbackOpen}
            />
          </section>
        </section>
      </SessionDetailsContext.Provider>

      {isMentorFeedbackOpen && sessionData?.relevantMeetingId && (
        <MentorFeedbackModal
          title={`${sessionData.title} / ${sessionData.student.fullName}`}
          feedback={feedback}
          meetingId={sessionData.relevantMeetingId}
          setFeedback={setFeedback}
          onClose={() => setIsMentorFeedbackOpen(false)}
        />
      )}

      {isStudentFeedbackOpen && sessionData?.relevantMeetingId && (
        <StudentFeedbackModal
          meetingId={sessionData.relevantMeetingId}
          onClose={() => setIsStudentFeedbackOpen(false)}
          isOpen={isStudentFeedbackOpen}
        />
      )}

      {isMeetingModalOpen && !!sessionData && (
        <MeetingModal
          visible
          hide={() => setIsMeetingModalOpen(false)}
          defaultStudent={{
            id: sessionData.student._id ?? '',
            fullName: sessionData.student.fullName,
          }}
          defaultEnrollment={{
            _id: sessionData.enrollmentId ?? '',
            course: sessionData.course,
          }}
        />
      )}
    </section>
  );
};

export default SessionDetails;
