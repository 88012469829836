import { ReactComponent as ExternalLinkBlack } from '@assets/icons/link_black.svg';
import Button from '@components/V4/Button';
import { useAuthState } from '@contexts/AuthContext';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';

interface CoursesSectionWrapperProps extends BasicProps {
  title: string;
  showButton?: boolean;
}

const CoursesSectionWrapper = (props: CoursesSectionWrapperProps) => {
  const { title, children, showButton, className } = props;
  const { isMentor } = useAuthState();

  return (
    <section
      className={mergeClassNames(
        'h-full flex flex-col gap-4 w-full max-w-[1564px] m-auto px-4 tablet:px-6 laptop:px-24',
        className
      )}
    >
      <div className="flex justify-between items-center">
        <h1 className="font-raleway font-semibold text-2xl">{title}</h1>
        {isMentor && showButton && (
          <a href="https://lms.upgrade.education/author/welcome" target="_blank" rel="noreferrer">
            <Button buttonText="LMS Dashboard" icon={<ExternalLinkBlack className="w-3.5" />} />
          </a>
        )}
      </div>
      {children}
    </section>
  );
};

export default CoursesSectionWrapper;
