import { ADMIN_ROLE, MENTOR_ROLE, STUDENT_ROLE } from '@shared/common';
import { useMobileState } from '../MobileContext';
import { useNavigationState } from '../NavigationContext';
import * as tabs from '../tabs';
import { useAuthState } from '@contexts/AuthContext';

const MenuTabs = () => {
  const { roles } = useAuthState();
  const { menuType } = useNavigationState();
  const isMobile = useMobileState();
  return (
    <>
      {menuType === STUDENT_ROLE && tabs.studentTabs(isMobile)}
      {menuType === MENTOR_ROLE && tabs.mentorTabs(isMobile)}
      {menuType === ADMIN_ROLE && tabs.adminTabs(roles)}
    </>
  );
};

export default MenuTabs;
