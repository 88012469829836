export const getProgressPercentage = (
  noOfCompletedSessions: number,
  totalNoOfSessions: number,
  noOfScheduledOrInProgressSessions?: number
) => {
  if (!noOfCompletedSessions || !totalNoOfSessions) return '0%';

  if (noOfScheduledOrInProgressSessions) {
    return `${(
      ((noOfScheduledOrInProgressSessions + noOfCompletedSessions) / totalNoOfSessions) *
      100
    ).toFixed(2)}%`;
  }

  return `${((noOfCompletedSessions / totalNoOfSessions) * 100).toFixed(2)}%`;
};
