import FlagIconFilled from '@assets/icons/icon-awesome-flag-filled.png';
import { FLAG_TYPE } from '@shared/constants';
import { getValueOrNA } from 'apps/agora/src/utils/helpers';

interface FlagCategory {
  count?: number;
  value: keyof typeof FLAG_TYPE;
}

type Props = {
  noOfFlagTypes: FlagCategory[];
};

const TotalFlagsBanner = ({ noOfFlagTypes }: Props) => {
  return (
    <div className="flex justify-between items-center gap-8 p-8 rounded-4xl bg-darkGrey">
      {/*FLAG CARDS*/}
      {noOfFlagTypes?.map((flagCategory, index: number) => (
        <div key={index} className="flex flex-col gap-1.5">
          <div className="flex items-end gap-1.5">
            <h4 className="text-2xl text-white font-bold mr-3">
              {getValueOrNA(flagCategory?.count)}
            </h4>
            <img className="w-6" src={FlagIconFilled} alt="flag" />
          </div>
          <div className="">{getValueOrNA(FLAG_TYPE[flagCategory.value as unknown as number])}</div>
        </div>
      ))}
    </div>
  );
};

export default TotalFlagsBanner;
