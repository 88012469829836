import { ZoomContext } from '@modules/zoom/contexts/ZoomContext';
import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import VideoMeetingLoading from '../VideoMeeting/VideoMeetingLoading';
import VideoMeetingDetails from './VideoMeetingDetails';
import VideoMeetingPreview from './VideoMeetingPreview';

type Props = {
  setIsWaitingRoom: (isWaitingRoom: boolean) => void;
};

const VideoWaitingRoom = (props: Props) => {
  const { setIsWaitingRoom } = props;

  const { isMeetingLoading } = useContext(ZoomContext);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    params.delete('inMeeting');

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  }, []);

  if (isMeetingLoading) {
    return <VideoMeetingLoading />;
  }

  return (
    <section
      className="
        max-w-screen-largeLaptop m-auto flex flex-col gap-12
        tablet:p-14
        laptop:flex-row laptop:h-screen laptop:items-center laptop:gap-[clamp(4rem,7.2vw,6.5rem)]
        largeLaptop:pl-[4.875rem] largeLaptop:pr-[9.5rem]
      "
    >
      <VideoMeetingPreview />
      <VideoMeetingDetails setIsWaitingRoom={setIsWaitingRoom} />
    </section>
  );
};

export default VideoWaitingRoom;
