import classNames from 'classnames';
import React from 'react';
import './LoadingSpinner.style.scss';
import { mergeClassNames } from '../../utils/helpers';

interface LoadingSpinnerProps {
  size?: 'normal' | 'large';
  text?: string;
  color?: 'light';
  className?: string;
  inheritColor?: boolean;
}

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { size = 'large', color = 'light', text, className, inheritColor } = props;

  return (
    <div className={mergeClassNames('flex flex-col items-center justify-center gap-4', className)}>
      <div
        className={classNames('loading-spinner', size, color, { 'inherit-color': inheritColor })}
      />
      {!!text && <span className="text-white font-bold text-xsm leading-4">{text}</span>}
    </div>
  );
};
