import CourseProgressCard from '@components/V4/CourseProgressCard';
import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import ProfileMainSection from '../ResumeSection';
import { useAuthState } from '@contexts/AuthContext';
import { ProfileContext } from '../../AccountProfile';
import { useContext } from 'react';
import SkeletonBox from '@components/V4/SkeletonBox';

interface YourCoursesSectionProps {
  userProfile?: Partial<UserProfileDTO>;
}

const YourCoursesSection = (props: YourCoursesSectionProps) => {
  const { userProfile } = props;

  const { userId } = useAuthState();
  const { isLoading: isProfileLoading } = useContext(ProfileContext);

  return (
    <ProfileMainSection title="Your Courses">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 largeLaptop:grid-cols-3">
        {isProfileLoading
          ? [...Array(6).keys()].map((_, index) => (
              <SkeletonBox key={index} className="h-[297px] w-full rounded-lg" />
            ))
          : userProfile?.enrollments
              ?.filter((enrollment) => enrollment.mentor?._id === userId)
              .map((enrollment) => (
                <CourseProgressCard key={enrollment._id} enrollment={enrollment} />
              ))}
      </div>
    </ProfileMainSection>
  );
};

export default YourCoursesSection;
