import { useUserCourses } from '@actions';
import CoursesSection from './CoursesSection';
import CoursesSectionWrapper from './CoursesSectionWrapper';

const StudentCourses = () => {
  const { data: courses, isLoading } = useUserCourses();
  return (
    <CoursesSectionWrapper title="Your Courses" className="py-8 tablet:py-12">
      <CoursesSection courses={courses} isLoading={isLoading} />
    </CoursesSectionWrapper>
  );
};

export default StudentCourses;
