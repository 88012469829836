import { MeetingWithHomework } from '@shared/common';
import styles from '@styles/components/calendar.module.scss';
import moment, { Moment } from 'moment';
import { useRef, useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import CalendarMeetingsModal from './CalendarMeetingsModal';

const CalendarItem = ({
  day,
  isToday,
  allMeetings,
  displayedMonth,
}: {
  day: moment.Moment;
  isToday: boolean;
  allMeetings: MeetingWithHomework[] | undefined;
  displayedMonth: Moment;
}) => {
  const dayRef = useRef<HTMLDivElement>(null);
  const [selectedDay, setSelectedDay] = useState<Moment>(day);

  const handleSelectedDay = (day: Moment) => {
    setSelectedDay(day);
  };

  const goToPreviousDay = () => {
    setSelectedDay((currentDay) => moment(currentDay).subtract(1, 'days'));
  };

  const goToNextDay = () => {
    setSelectedDay((currentDay) => moment(currentDay).add(1, 'days'));
  };

  const isCurrentMonth = (date: Moment) => date.isSame(displayedMonth, 'month');

  const meetingsForDate = (date: Moment) => {
    const startOfDay = date.startOf('day').toISOString();
    const endOfDay = date.endOf('day').toISOString();

    return (allMeetings || []).filter(
      (mt) => mt.startDate >= startOfDay && mt.startDate <= endOfDay
    );
  };

  const dayMeetings = meetingsForDate(day);
  const dayMeetingsModal = meetingsForDate(selectedDay);

  const [isHovered, setIsHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setSelectedDay(day);
    setShowModal(!showModal);
  };

  const visibleMeetings = dayMeetings.slice(0, 2);
  const extraMeetingsCount = dayMeetings.length - 2;

  return (
    <>
      {showModal && (
        <CalendarMeetingsModal
          showModal={showModal}
          handleShowModal={handleShowModal}
          date={selectedDay}
          isOnHomePage={true}
          dayMeetingsModal={dayMeetingsModal}
          day={day}
          handleSelectedDay={handleSelectedDay}
          goToPreviousDay={goToPreviousDay}
          goToNextDay={goToNextDay}
        />
      )}
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={dayRef}
        onClick={(e: any) => {
          if (dayRef.current && dayRef.current.contains(e.target)) {
            e.stopPropagation();
            handleShowModal();
          }
        }}
        className={styles.calendarItem}
        style={{
          backgroundColor: isToday || isHovered ? COLORS.DARK_GREY : COLORS.COMPONENT_BACKGROUND,
          opacity: isCurrentMonth(day) ? 1 : 0.33,
        }}
      >
        <div className={styles.calendarItemBody}>
          <div className={styles.calendarItemDate}>
            <div style={{ color: COLORS.TEXT_PRIMARY }} className={styles.calendarHeaderDayText}>
              {day.format('ddd')}
            </div>
            <div style={{ color: COLORS.TEXT_PRIMARY }} className={styles.calendarHeaderDayNum}>
              {day.format('DD')}
            </div>
          </div>
          {visibleMeetings && (
            <div className={styles.calendarItemMeetings}>
              {visibleMeetings.map((meeting) => (
                <div
                  style={{ color: COLORS.TEXT_PRIMARY }}
                  className={styles.event}
                  key={meeting._id}
                >
                  <div
                    className={styles.eventIcon}
                    style={{
                      backgroundColor:
                        meeting?.enrollment?.title?.toLowerCase().includes('coach') ||
                        meeting?.enrollment?.title?.toLowerCase().includes('training')
                          ? COLORS.PRIMARY
                          : meeting?.enrollment?.title?.toLowerCase().includes('academic')
                          ? COLORS.PURPLE
                          : COLORS.GOLD,
                    }}
                  ></div>
                  <div>
                    <span className={styles.calendarBodyMeetingText}>
                      {moment(meeting.startDate).format('HH:mm')}{' '}
                      <span style={{ color: COLORS.TEXT_SECONDARY }}>
                        -{' '}
                        {meeting.students.length === 1
                          ? meeting.students[0].fullName
                          : meeting.students.map((student: any, index: number) =>
                              index === meeting.students.length - 1
                                ? student.fullName
                                : student.fullName + ', '
                            )}
                      </span>
                    </span>
                  </div>
                </div>
              ))}
              {extraMeetingsCount > 0 && (
                <div
                  style={{ color: COLORS.TEXT_SECONDARY }}
                  className={styles.calendarBodyMeetingText}
                >
                  +{extraMeetingsCount} more
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CalendarItem;
