import { SearchOutlined } from '@ant-design/icons';
import { ElectronicInvoiceModal } from '@components';
import SideBySideModal from '@components/SideBySideModal';
import { ChevronLeftIcon, ChevronRightIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import { useDebounce } from '@hooks';
import ActivityReportTable from '@modules/Admin/ActivityReport/ActivityReportTable';
import { Report } from '@shared/common';
import { ReportStatus } from '@shared/constants';
import {
  useGetExchangeRateByMonth,
  useGetNoOfActiveMentorsByMonth,
  useGetNoOfSessionsByMonth,
  useGetTotalPaymentByMonth,
  useMarkBulkReportsAsPaid,
  useMarkBulkReportsReadyForPayment,
  useGetTotalPaymentForExtraServiceByMonth,
  useSearchReports,
} from '@actions';
import styles from '@styles/components/calendar.module.scss';
import button from '@styles/components/reusables/buttons.module.scss';
import dropdown from '@styles/components/reusables/dropdown.module.scss';
import reportsStyles from '@styles/pages/admin/activity-report.module.scss';
import { Button, Checkbox, Dropdown, Input, Select } from 'antd';
import { stringify } from 'csv-stringify/sync';
import JSZip from 'jszip';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useCalendar from '../../../hooks/useCalendar';
import InformationCard from '../../Mentor/SessionHistory/InformationCard';
import apiClient from '@contexts/AuthContext/apiClient';
import useToast from 'apps/agora/src/hooks/useToast';
import Pagination from '@components/V4/Pagination';

interface CurrencySelectorProps {
  currencies: string[];
  goLeft: any;
  goRight: any;
  selectedCurrency: number;
}

const CurrencySelector = (props: CurrencySelectorProps) => {
  return (
    <div className={reportsStyles.currencySelectorContainer}>
      <ChevronLeftIcon
        className={`${styles.icon} ${reportsStyles.directionIcon}`}
        onClick={props.goLeft}
      />
      {props.currencies[props.selectedCurrency]}
      <ChevronRightIcon
        className={`${styles.icon} ${reportsStyles.directionIcon}`}
        onClick={props.goRight}
      />
    </div>
  );
};

const suffix = <SearchOutlined />;

const TARGET_DATE_FORMAT = 'YYYY-MM';

const ActivityReport = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const enrollmentId = searchParams.get('enrollmentId');
  const sessionId = searchParams.get('sessionId');
  const selectedDateParams = searchParams.get('selectedDate');

  const toast = useToast();

  if (!selectedDateParams) {
    const today = moment().format(TARGET_DATE_FORMAT);
    searchParams.set('selectedDate', today);

    history.replace(`${location.pathname}?${searchParams.toString()}`);
  }

  const [selectedDate, setSelectedDate] = useState(
    selectedDateParams || moment().format(TARGET_DATE_FORMAT)
  );

  const { goForward, goBackward, selected } = useCalendar(moment(selectedDate));

  const currenciesM = ['US Dollars', 'Euro', 'Pounds', 'Romanian Leu'];
  const currenciesES = ['US Dollars', 'Euro', 'Pounds', 'Romanian Leu'];

  const [selectedCurrency, setSelectedCurrency] = useState(0);
  const [selectedESCurrency, setSelectedESCurrency] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(searchQuery, 500);
  const [selectedStatus, setSelectedStatus] = useState<number>();
  const [isTestUserFilter, setIsTestUserFilter] = useState(false);

  const [selectedItems, setSelectedItems] = useState<Report[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const [renderKey, setRenderKey] = useState(0);
  const [loadingCSVData, setLoadingCSVData] = useState(false);

  const [rowTouched, setRowTouched] = useState(0);

  const [selectedReportId, setSelectedReportId] = useState('');

  const [showSideBySide, setShowSideBySide] = useState(false);
  const [showElectronicInvoice, setShowElectronicInvoice] = useState(false);

  const [nextReportId, setNextReportId] = useState<string | null>(selectedReportId);
  const [prevReportId, setPrevReportId] = useState<string | null>(selectedReportId);

  const [sortType, setSortType] = useState('sessions');
  const [sortValue, setSortValue] = useState<'desc' | 'asc'>('desc');

  const [showAll, setShowAll] = useState(false);

  const [isLoadingDownloadBulkInvoices, setIsLoadingDownloadBulkInvoices] = useState(false);
  const [isLoadingDownloadBulkActivityReports, setIsLoadingDownloadBulkActivityReports] =
    useState(false);
  const [downloadableInvoices, setdownloadableInvoices] = useState<number>(0);
  const [downloadableActivityReports, setDownloadableActivityReports] = useState<number>(0);

  const [currentReport, setCurrentReport] = useState<Report | null>(null);

  const handleRowTouched = () => {
    setRowTouched(rowTouched + 1);
  };

  const handleSelected = (report: Report) => {
    setSelectedItems((prevSelectedItems) => {
      const isSelected = prevSelectedItems.includes(report);

      if (isSelected) {
        return prevSelectedItems.filter((selectedItem) => selectedItem !== report);
      } else {
        return [...prevSelectedItems, report];
      }
    });
  };

  const unselectAll = () => {
    setSelectedItems([]);
    setSelectAll(false);
  };

  const handleSelectAll = (reports: Report[]) => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(reports);
    }

    setSelectAll(!selectAll);
  };

  const goMLeft = () => {
    setSelectedCurrency((prevIndex) => (prevIndex === 0 ? currenciesM.length - 1 : prevIndex - 1));
  };

  const goMRight = () => {
    setSelectedCurrency((prevIndex) => (prevIndex === currenciesM.length - 1 ? 0 : prevIndex + 1));
  };

  const goESLeft = () => {
    setSelectedESCurrency((prevIndex) =>
      prevIndex === 0 ? currenciesES.length - 1 : prevIndex - 1
    );
  };

  const goESRight = () => {
    setSelectedESCurrency((prevIndex) =>
      prevIndex === currenciesES.length - 1 ? 0 : prevIndex + 1
    );
  };

  const { data: noOfActiveMentors, isLoading: isLoadingNoOfActiveMentorsByMonth } =
    useGetNoOfActiveMentorsByMonth({
      date: selectedDate,
    });
  const { data: noOfSessions, isLoading: isLoadingNoOfSessionsByMonth } = useGetNoOfSessionsByMonth(
    {
      date: selectedDate,
    }
  );
  const { data: totalPayment, isLoading: isLoadingTotalPaymentByMonth } = useGetTotalPaymentByMonth(
    {
      date: selectedDate,
    }
  );

  const { data: totalPaymentExtraService, isLoading: isLoadingTotalPaymentForExtraServiceByMonth } =
    useGetTotalPaymentForExtraServiceByMonth({
      date: selectedDate,
    });

  const { data: rateByMonth } = useGetExchangeRateByMonth({
    date: selectedDate,
  });

  const { mutate: markAsReadyForPayment } = useMarkBulkReportsReadyForPayment({
    onSuccess: () => {
      toast.success("Selected records are now marked as 'READY FOR PAYMENT'");
      unselectAll();

      handleRowTouched();
    },
  });

  const { mutate: markReportsAsPaid } = useMarkBulkReportsAsPaid({
    onSuccess: () => {
      toast.success("Selected records are now marked as 'PAID'");
      unselectAll();

      handleRowTouched();
    },
  });

  const { data: allMonthlyReports, isLoading } = useSearchReports({
    status: selectedStatus,
    page: currentPage,
    limit: currentPageSize,
    search: debouncedSearch,
    sortValue,
    sortType,
    date: selectedDate,
    isTestUser: isTestUserFilter,
  });

  const convertToCSV = (data: string[]) => stringify(data, { header: false });

  const getCSVData = async () => {
    setLoadingCSVData(true);
    try {
      const response = await apiClient.get(`/reports/csv/accepted-invoices?date=${selectedDate}`);

      const currencyTypes = ['EUR', 'GBP', 'USD', 'RON'];

      const zip = new JSZip();
      currencyTypes.forEach((currencyType) => {
        const csvContent = convertToCSV(response.data[currencyType]);
        zip.file(`accepted-invoices-${currencyType}.csv`, csvContent);
      });

      zip.generateAsync({ type: 'blob' }).then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `accepted-invoices.zip`;
        link.click();
      });
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoadingCSVData(false);
    }
  };

  const handleMarkAsReadyForPayment = (items: Report[]) => {
    if (
      items.some(
        (item: Report) =>
          item.status !== ReportStatus.INVOICE_UPLOADED &&
          item.status !== ReportStatus.INVALID_E_INVOICE &&
          item.status !== ReportStatus.INVOICE_ACCEPTED
      ) ||
      !items.length
    ) {
      toast.error(
        "In order to mark as 'READY FOR PAYMENT', all selected records must be have 'INVOICE UPLOADED', 'INVALID E INVOICE or 'INVOICE_ACCEPTED' status"
      );
      return;
    } else {
      toast.info("Marking records as 'READY FOR PAYMENT'...");
      markAsReadyForPayment({
        reportIds: items.map((item: Report) => item._id),
      });
    }
  };

  const handleMarkAsPaid = (items: Report[]) => {
    if (
      items.some((item: Report) => item.status !== ReportStatus.READY_FOR_PAYMENT) ||
      !items.length
    ) {
      toast.error(
        "In order to mark as 'PAID', all selected records must be have an 'READY FOR PAYMENT' status"
      );

      return;
    } else {
      toast.info("Marking reports and meetings as 'PAID'...");
      markReportsAsPaid({
        reportIds: items.map((item: Report) => item._id),
      });
    }
  };

  const downloadBulkInvoices = async () => {
    setIsLoadingDownloadBulkInvoices(true);
    try {
      const response = await apiClient.post(
        `/reports/bulk/download-invoices`,
        {
          invoiceIds: selectedItems
            .filter((item: any) => item.invoiceId)
            .map((item: any) => item._id),
          date: selectedDate,
          mentor: searchQuery,
          status: selectedStatus,
          isTestUser: isTestUserFilter,
        },
        { responseType: 'blob', timeout: 1000000 }
      );

      const blob = new Blob([response.data], { type: 'application/zip' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `invoices-${selectedDate}.zip`;
      link.click();
      setIsLoadingDownloadBulkInvoices(false);
    } catch (error) {
      setIsLoadingDownloadBulkInvoices(false);
      console.log('error: ', error);
    }
  };

  const downloadBulkActivityReports = async () => {
    setIsLoadingDownloadBulkActivityReports(true);
    try {
      const response = await apiClient.post(
        `/reports/bulk/download-activity-reports`,
        {
          reportIds: selectedItems.map((item: Report) => item._id),
          date: selectedDate,
          mentor: searchQuery,
          status: selectedStatus,
          isTestUser: isTestUserFilter,
        },
        { responseType: 'blob', timeout: 1000000 }
      );

      const blob = new Blob([response.data], { type: 'application/zip' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `activity_reports-${selectedDate}.zip`;
      link.click();
      setIsLoadingDownloadBulkActivityReports(false);
    } catch (error) {
      setIsLoadingDownloadBulkActivityReports(false);
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    if (!!allMonthlyReports && selectedReportId && (showSideBySide || showElectronicInvoice)) {
      const selectedIndex = allMonthlyReports?.reports.findIndex(
        (report: Report) => report._id === selectedReportId
      );

      for (let i = selectedIndex + 1; i < allMonthlyReports?.reports?.length; i++) {
        if (
          allMonthlyReports.reports[i]?.invoiceId &&
          allMonthlyReports.reports[i]?.status !== ReportStatus.PAID
        ) {
          setNextReportId(allMonthlyReports.reports[i]?._id);
          break;
        }
      }
      for (let i = selectedIndex - 1; i >= 0; i--) {
        if (
          allMonthlyReports.reports[i]?.invoiceId &&
          allMonthlyReports.reports[i]?.status !== ReportStatus.PAID
        ) {
          setPrevReportId(allMonthlyReports.reports[i]?._id);
          break;
        }
      }

      const currReport = allMonthlyReports?.reports?.[selectedIndex];

      setCurrentReport(currReport);

      if (!nextReportId) setNextReportId(selectedReportId);
      if (!prevReportId) setPrevReportId(selectedReportId);

      const showElectronicInvoice =
        currReport?.status === ReportStatus.INVOICE_ACCEPTED ||
        currReport?.status === ReportStatus.INVALID_E_INVOICE;

      const showSideBySide =
        currReport?.status === ReportStatus.INVOICE_UPLOADED ||
        currReport?.status === ReportStatus.READY_FOR_PAYMENT ||
        currReport?.status === ReportStatus.PAID ||
        currReport?.status === ReportStatus.REJECTED;

      setShowElectronicInvoice(showElectronicInvoice);
      setShowSideBySide(showSideBySide);
    }
  }, [
    selectedReportId,
    showSideBySide,
    showElectronicInvoice,
    nextReportId,
    prevReportId,
    allMonthlyReports?.reports,
  ]);

  useEffect(() => {
    if (selectedItems.length > 0) {
      const totalInvoices = selectedItems.filter((item: Report) => item.invoiceId).length;
      const totalActivityReports = selectedItems.filter(
        (item: Report) => item.activityReportId
      ).length;

      setdownloadableInvoices(totalInvoices);
      setDownloadableActivityReports(totalActivityReports);
    } else {
      if (allMonthlyReports) {
        setdownloadableInvoices(allMonthlyReports.totalInvoices);
        setDownloadableActivityReports(allMonthlyReports.totalDownloadableActivityReports);
      }
    }
  }, [selectedItems, allMonthlyReports]);

  const getDownloadButtonMessageStatus = (reportType: string) => {
    let suffix;
    switch (reportType) {
      case 'Invoice':
        if (isLoadingDownloadBulkInvoices) return 'Loading...';
        if (downloadableInvoices === 0) return `No ${reportType} to download`;
        suffix = downloadableInvoices > 1 ? 's' : '';
        return `Download ${downloadableInvoices} ${reportType}${suffix}`;
      case 'Activity Report':
        if (isLoadingDownloadBulkActivityReports) return 'Loading...';
        if (downloadableActivityReports === 0) return `No ${reportType} to download`;
        suffix = downloadableActivityReports > 1 ? 's' : '';
        return `Download ${downloadableActivityReports} ${reportType}${suffix}`;
      default:
        return 'Unknown';
    }
  };

  const changeMonthHandler = (callback: () => void) => {
    const newDate = moment(selected)
      .add(callback === goForward ? 1 : -1, 'month')
      .format(TARGET_DATE_FORMAT);

    callback();

    searchParams.set('selectedDate', newDate);
    history.replace(`${location.pathname}?${searchParams.toString()}`);

    setSelectedDate(newDate);
  };

  const showAllChangeHandler = () => {
    if (!allMonthlyReports) {
      return;
    }

    const show = !showAll;

    setShowAll(show);
    setCurrentPageSize(show ? allMonthlyReports?.totalActivityReports : 10);
    setCurrentPage(1);
  };

  return (
    <>
      <SideBySideModal
        visible={showSideBySide && !showElectronicInvoice}
        hide={() => setShowSideBySide(false)}
        reportId={selectedReportId}
        invoiceNo={currentReport?.invoiceNo ?? ''}
        status={currentReport?.status ?? -1}
        handleRowTouched={handleRowTouched}
        nextReport={() => {
          setSelectedReportId(nextReportId as string);
        }}
        prevReport={() => {
          setSelectedReportId(prevReportId as string);
        }}
        isFirst={selectedReportId === prevReportId}
        isLast={selectedReportId === nextReportId}
        rejectionReason={currentReport?.rejectionReason}
      />

      <ElectronicInvoiceModal
        visible={showElectronicInvoice && !showSideBySide}
        hide={() => setShowElectronicInvoice(false)}
        reportId={selectedReportId}
        invoiceNo={currentReport?.invoiceNo ?? ''}
        status={currentReport?.status ?? -1}
        handleRowTouched={handleRowTouched}
        nextReport={() => {
          setSelectedReportId(nextReportId as string);
        }}
        prevReport={() => {
          setSelectedReportId(prevReportId as string);
        }}
        isFirst={selectedReportId === prevReportId}
        isLast={selectedReportId === nextReportId}
        eInvoiceRejectionReason={currentReport?.eInvoiceRejectionReason}
      />

      <div className={reportsStyles.activityReportContainer}>
        <h1 className={reportsStyles.title}>Monthly Activity Report</h1>
        <div className={reportsStyles.selectorContainer}>
          <ChevronLeftIcon
            style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
            className={`${styles.icon} ${reportsStyles.directionIcon}`}
            onClick={isLoading ? () => {} : () => changeMonthHandler(goBackward)}
          />
          <span className={reportsStyles.dateFormat}>
            {moment(selectedDate, TARGET_DATE_FORMAT)?.format('MMMM YYYY')}
          </span>
          <ChevronRightIcon
            style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
            className={`${styles.icon} ${reportsStyles.directionIcon}`}
            onClick={isLoading ? () => {} : () => changeMonthHandler(goForward)}
          />
        </div>
      </div>

      <div style={{ padding: '24px' }}>
        <h2>Overview</h2>
      </div>

      <div className={reportsStyles.cardsContainer}>
        <div>
          <InformationCard
            title={
              isLoadingNoOfActiveMentorsByMonth
                ? 'Loading...'
                : noOfActiveMentors?.noOfActiveMentors?.toString()
            }
            subtitle="Active mentors"
            width="260px"
            height="136px"
          />
          <h4 style={{ margin: '0 20px' }}>Active mentors</h4>
        </div>

        <div>
          <InformationCard
            title={
              isLoadingNoOfSessionsByMonth ? 'Loading...' : noOfSessions?.noOfSessions?.toString()
            }
            subtitle="Sessions this month"
            width="260px"
            height="136px"
          />
          <h4 style={{ margin: '0 20px' }}>Sessions completed</h4>
        </div>

        <div>
          <InformationCard
            subtitle={
              <div>
                {rateByMonth ? rateByMonth[0]?.to?.currency : 'N/A'} ={' '}
                <strong>
                  {rateByMonth ? rateByMonth[0]?.to?.value : 'N/A'}{' '}
                  {rateByMonth ? rateByMonth[0]?.from : 'N/A'}
                </strong>{' '}
                <br />
                {rateByMonth ? rateByMonth[2]?.to?.currency : 'N/A'} ={' '}
                <strong>
                  {rateByMonth ? rateByMonth[2]?.to?.value : 'N/A'}{' '}
                  {rateByMonth ? rateByMonth[2]?.from : 'N/A'}
                </strong>{' '}
                <br />
                {rateByMonth ? rateByMonth[1]?.to?.currency : 'N/A'} ={' '}
                <strong>
                  {rateByMonth ? rateByMonth[1]?.to?.value : 'N/A'}{' '}
                  {rateByMonth ? rateByMonth[1]?.from : 'N/A'}
                </strong>{' '}
                <br />
                <br />
                Date:{' '}
                <strong>
                  {rateByMonth ? moment(rateByMonth[0]?.date).format('MMMM DD YYYY') : 'N/A'}
                </strong>
              </div>
            }
            width="260px"
            height="136px"
          />
          <h4 style={{ margin: '0 20px' }}>Conversion rate</h4>
        </div>

        <div>
          <InformationCard
            title={
              isLoadingTotalPaymentByMonth
                ? 'Loading...'
                : totalPayment?.totalPayment?.totalAmountInEuro !== undefined
                ? '€' + totalPayment?.totalPayment?.totalAmountInEuro?.toFixed(2)?.toString()
                : 'N/A'
            }
            subtitle="Total amount to be payed"
            width="260px"
            height="136px"
          />
          <h4 style={{ margin: '0 20px' }}>Total amount to be payed</h4>
        </div>

        <div>
          <InformationCard
            title={
              isLoadingTotalPaymentByMonth
                ? 'Loading...'
                : selectedCurrency === 0
                ? `${
                    totalPayment?.totalPayment.paidAmountInDollars?.toFixed(2).toString() ?? '0'
                  } / ${
                    totalPayment?.totalPayment?.amountInDollars?.toFixed(2)?.toString() ?? 'N/A'
                  }`
                : selectedCurrency === 1
                ? `${totalPayment?.totalPayment.paidAmountInEuro?.toFixed(2).toString() ?? '0'} / ${
                    totalPayment?.totalPayment?.amountInEuro?.toFixed(2)?.toString() ?? 'N/A'
                  }`
                : selectedCurrency === 2
                ? `${
                    totalPayment?.totalPayment.paidAmountInPounds?.toFixed(2).toString() ?? '0'
                  } / ${
                    totalPayment?.totalPayment?.amountInPounds?.toFixed(2)?.toString() ?? 'N/A'
                  }`
                : `${totalPayment?.totalPayment.paidAmountInRON?.toFixed(2).toString() ?? '0'} / ${
                    totalPayment?.totalPayment?.amountInRON?.toFixed(2)?.toString() ?? 'N/A'
                  }`
            }
            font="normal normal bold 26px/31px Urbane Rounded"
            selector={
              <CurrencySelector
                goLeft={goMLeft}
                goRight={goMRight}
                selectedCurrency={selectedCurrency}
                currencies={currenciesM}
              />
            }
            width="260px"
            height="136px"
          />
          <h4 style={{ margin: '0 20px' }}>Amount payed / Total (Meetings)</h4>
        </div>

        <div>
          <InformationCard
            title={
              isLoadingTotalPaymentForExtraServiceByMonth
                ? 'Loading...'
                : selectedESCurrency === 0
                ? `${
                    totalPaymentExtraService?.totalPaymentForExtraServices.paidAmountInDollars
                      ?.toFixed(2)
                      .toString() ?? '0'
                  } / ${
                    totalPaymentExtraService?.totalPaymentForExtraServices?.amountInDollars
                      ?.toFixed(2)
                      ?.toString() ?? 'N/A'
                  }`
                : selectedESCurrency === 1
                ? `${
                    totalPaymentExtraService?.totalPaymentForExtraServices.paidAmountInEuro
                      ?.toFixed(2)
                      .toString() ?? '0'
                  } / ${
                    totalPaymentExtraService?.totalPaymentForExtraServices?.amountInEuro
                      ?.toFixed(2)
                      ?.toString() ?? 'N/A'
                  }`
                : selectedESCurrency === 2
                ? `${
                    totalPaymentExtraService?.totalPaymentForExtraServices.paidAmountInPounds
                      ?.toFixed(2)
                      .toString() ?? '0'
                  } / ${
                    totalPaymentExtraService?.totalPaymentForExtraServices?.amountInPounds
                      ?.toFixed(2)
                      ?.toString() ?? 'N/A'
                  }`
                : `${
                    totalPaymentExtraService?.totalPaymentForExtraServices.paidAmountInRON
                      ?.toFixed(2)
                      .toString() ?? '0'
                  } / ${
                    totalPaymentExtraService?.totalPaymentForExtraServices?.amountInRON
                      ?.toFixed(2)
                      ?.toString() ?? 'N/A'
                  }`
            }
            font="normal normal bold 26px/31px Urbane Rounded"
            selector={
              <CurrencySelector
                goLeft={goESLeft}
                goRight={goESRight}
                selectedCurrency={selectedESCurrency}
                currencies={currenciesES}
              />
            }
            width="260px"
            height="136px"
          />
          <h4 style={{ margin: '0 20px' }}>Amount payed / Total (Extra Services)</h4>
        </div>
      </div>

      <div style={{ marginTop: '40px' }}></div>

      <div className={reportsStyles.reportsContainer}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2 style={{ margin: 0 }}>Reports</h2>
          <Input
            placeholder="Search by mentor"
            size="middle"
            value={searchQuery}
            onChange={(e) => {
              unselectAll();
              setSearchQuery(e.target.value);
              setCurrentPage(1);
            }}
            suffix={suffix}
            className={reportsStyles.search}
          />
          <div style={{ margin: '0 20px' }} className={styles.filtersItemsItem}>
            <Select
              style={{ width: '200px' }}
              showSearch
              placeholder="Invoice Status"
              optionFilterProp="children"
              onChange={(value: number) => {
                unselectAll();
                setSelectedStatus(value);
                setCurrentPage(1);
              }}
            >
              <Select.Option value={undefined}>ALL</Select.Option>
              {Object.keys(ReportStatus)
                .filter((key) => isNaN(Number(key)))
                .map((key) => (
                  <Select.Option key={key} value={ReportStatus[key as keyof ReportStatus as any]}>
                    {key.replaceAll('_', ' ')}
                  </Select.Option>
                ))}
            </Select>
          </div>

          <div className={styles.filtersItemsItem}>
            <span className="text-white pr-1">Test User</span>
            <Checkbox
              onChange={(e) => {
                unselectAll();
                setIsTestUserFilter(e.target.checked);
                setCurrentPage(1);
              }}
              checked={isTestUserFilter}
            />
          </div>

          <div style={{ margin: '0 20px' }} className={styles.filtersItemsItem}>
            <span className="text-white pr-1">Sort by:</span>
            <Select
              style={{ width: 'auto' }}
              showSearch
              placeholder="Sort by"
              optionFilterProp="children"
              onChange={(value: string) => {
                unselectAll();
                setSortType(value);
                setCurrentPage(1);
              }}
              defaultValue={sortType}
            >
              <Select.Option value={'name'}>NAME</Select.Option>
              <Select.Option value={'sessions'}>SESSIONS</Select.Option>
            </Select>
          </div>

          <div style={{ margin: '0 20px' }} className={styles.filtersItemsItem}>
            <span className="text-white pr-1">Order:</span>
            <Select
              style={{ width: 'auto' }}
              showSearch
              placeholder="Sort order"
              optionFilterProp="children"
              onChange={(value) => {
                unselectAll();
                setSortValue(value);
              }}
              defaultValue={sortValue}
            >
              <Select.Option value={'asc'}>ASCENDING</Select.Option>
              <Select.Option value={'desc'}>DESCENDING</Select.Option>
            </Select>
          </div>
        </div>
        <Button
          type="primary"
          style={{
            border: '2px solid #000000',
            margin: '0px 10px',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {loadingCSVData ? (
              <div>Loading...</div>
            ) : (
              <div onClick={() => getCSVData()}>Generate CSV</div>
            )}
            <Dropdown
              trigger={['click']}
              overlay={
                <div className={dropdown.dropdown}>
                  <div
                    onClick={() => handleMarkAsReadyForPayment(selectedItems)}
                    className={dropdown.dropdownItem}
                  >
                    Mark as ready for payment
                  </div>
                  <div
                    onClick={() => handleMarkAsPaid(selectedItems)}
                    className={dropdown.dropdownItem}
                  >
                    Mark as paid
                  </div>
                </div>
              }
              placement="bottomRight"
            >
              <DotsVerticalIcon className={button.actionButtonIcon} />
            </Dropdown>
          </div>
        </Button>
      </div>

      <div style={{ marginTop: 10 }}>
        <Button
          type="primary"
          style={{
            border: '2px solid #000000',
            margin: '0px 10px',
          }}
          disabled={downloadableInvoices === 0}
          onClick={downloadBulkInvoices}
        >
          {getDownloadButtonMessageStatus('Invoice')}
        </Button>
        <Button
          type="primary"
          style={{
            border: '2px solid #000000',
            margin: '0px 10px',
          }}
          disabled={downloadableActivityReports === 0}
          onClick={downloadBulkActivityReports}
        >
          {getDownloadButtonMessageStatus('Activity Report')}
        </Button>
      </div>

      <div style={{ marginTop: '40px' }}></div>

      {allMonthlyReports && !isLoading && (
        <ActivityReportTable
          handleRowTouched={handleRowTouched}
          selectedDate={selected}
          key={renderKey}
          handleSelected={handleSelected}
          handleSelectAll={handleSelectAll}
          selectedItems={selectedItems}
          selectAll={selectAll}
          isLoading={isLoading}
          reports={allMonthlyReports?.reports}
          setSelectedReportId={setSelectedReportId}
          setShowSideBySideModal={setShowSideBySide}
          setShowElectronicInvoiceModal={setShowElectronicInvoice}
        />
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
        className={reportsStyles.paginationContainer}
      >
        {!showAll && (
          <Pagination
            className="mx-6"
            total={allMonthlyReports?.totalActivityReports}
            currentPage={currentPage}
            pageSize={currentPageSize}
            onPageSizeChange={setCurrentPageSize}
            onCurrentPageChange={setCurrentPage}
          />
        )}
        <div
          className="min-w-max h-20"
          style={{
            marginLeft: '8px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              display: 'inline-block',
              height: '32px',
              marginRight: '8px',
              lineHeight: '30px',
              verticalAlign: 'middle',
              fontSize: '14px',
            }}
          >
            Show All:{' '}
          </span>
          <Checkbox checked={showAll} onChange={showAllChangeHandler} />
        </div>
      </div>
    </>
  );
};

export default ActivityReport;
