import { RenderRoutes } from '@components';
import Admin from '@modules/Admin';
import AccountPage from '../Account';

const Base = () => {
  return (
    <RenderRoutes
      routes={[
        { path: '/account', component: AccountPage },
        { path: '/admin', component: Admin },
      ]}
      redirectPath={'/admin'}
    />
  );
};

export default Base;
