import ArrowDown from '@assets/icons/ic-arrow-narrow-down.svg';
import ArrowUp from '@assets/icons/ic-arrow-narrow-up.svg';
import UsersIcon from '@assets/icons/ic_users.svg';
import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { COLORS } from '../../../v3/global';
import './nps-overview-card.styles.scss';
import { checkNaN } from 'apps/agora/src/utils/helpers';

type Props = {
  title: any;
  info: any;
  type: string;
};

const NPSOverviewCard = ({ title, info, type }: Props) => {
  const gradientId = 'gradientFill';
  const primaryColor = COLORS.PRIMARY;

  const CustomTooltip = ({ active }: { active?: boolean }) => {
    if (active) {
      return null;
    }
    return null;
  };

  const infoData = info && info[type];

  const Chart = () => {
    return (
      <ResponsiveContainer width="100%" height={56}>
        <AreaChart data={infoData?.trendPoints}>
          <defs>
            <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={primaryColor} />
              <stop offset="100%" stopColor="transparent" />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" hide={true} />
          <YAxis hide={true} />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Area
            dot={false}
            activeDot={false}
            type="monotone"
            dataKey="y"
            stroke={`url(#${gradientId})`}
            fill={`url(#${gradientId})`}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  const hasData = checkNaN(infoData?.currMonthData?.responsesNumber) > 0;

  if (!hasData) {
    return (
      <div
        style={{
          minWidth: '224px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: `${COLORS.COMPONENT_BACKGROUND} 0% 0% no-repeat padding-box`,
          boxShadow: '0px 1px 4px #15223214',
          padding: '16px 17px 4px 17px',
          marginBottom: '24px',
        }}
      >
        <div
          style={{
            width: 'calc(100% - 10px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {title}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}
            className="nps-overview-card-details"
          >
            <h2 style={{ color: COLORS.TEXT_PRIMARY }}>No Data</h2>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h2 style={{ color: COLORS.TEXT_PRIMARY }}>0</h2>
              <div>
                <img src={UsersIcon} style={{ height: '24px' }} alt="users" />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4
                style={{
                  color: COLORS.TEXT_SECONDARY,
                }}
                className="nps-overview-card-comparison-value"
              >
                No Data for last month
              </h4>
            </div>
          </div>
        </div>
        <Chart />
      </div>
    );
  }

  return (
    <div
      style={{
        minWidth: '224px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: `${COLORS.COMPONENT_BACKGROUND} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 1px 4px #15223214',
        padding: '16px 17px 4px 17px',
        marginBottom: '24px',
      }}
    >
      <div
        style={{
          width: 'calc(100% - 10px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {title}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '12px',
          }}
          className="nps-overview-card-details"
        >
          <h2 style={{ color: COLORS.TEXT_PRIMARY }}>
            {checkNaN(Number((infoData?.currMonthData?.totalNps * 100)?.toFixed(2)))}
          </h2>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2 style={{ color: COLORS.TEXT_PRIMARY }}>
              {infoData?.currMonthData?.responsesNumber}
            </h2>
            <div>
              <img src={UsersIcon} style={{ height: '24px' }} alt="users" />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4
              style={{
                color: infoData?.changeFromLastMonth < 0 ? COLORS.RED : COLORS.GREEN,
              }}
              className="nps-overview-card-comparison-value"
            >
              {checkNaN(Number((infoData?.changeFromLastMonth * 100)?.toFixed(2)))}%
            </h4>
            <div>
              {infoData?.changeFromLastMonth < 0 ? (
                <img src={ArrowDown} style={{ height: '16px' }} alt="arrow" />
              ) : (
                <img src={ArrowUp} style={{ height: '16px' }} alt="arrow" />
              )}
            </div>
          </div>
          <div>
            <p
              style={{ color: COLORS.TEXT_SECONDARY }}
              className="nps-overview-card-comparison-text"
            >
              than last month
            </p>
          </div>
        </div>
      </div>
      <Chart />
    </div>
  );
};

export default NPSOverviewCard;
