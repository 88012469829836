import StepBanner from '@modules/Account/Profile/components/ProfileBanner/StepBanner';
import { LanguageExam } from '@shared/common';
import { useContext, useState } from 'react';
import { ProfileContext } from '../../../AccountProfile';
import { useDeleteProfileEntry } from '../../../hooks/useDeleteProfileEntry';
import ProfileMainSection from '../../ResumeSection';
import UpcomingExamsCard from './UpcomingExamsCard';
import UpcomingExamsForm from './UpcomingExamsForm';
import SkeletonBox from '@components/V4/SkeletonBox';

export interface ExamsCardProps {
  userId: string;
  languageExam: LanguageExam;
  onDeleteEntry: (id: string | undefined) => void;
}

interface UpcomingExamsSectionProps {
  languageExams: LanguageExam[];
  userId: string;
}

const UpcomingExamsSection = (props: UpcomingExamsSectionProps) => {
  const { languageExams, userId } = props;

  const { isMentorViewer, isLoading } = useContext(ProfileContext);

  const [isAddNewItem, setIsAddNewItem] = useState(false);

  const [deleteEntryHandler] = useDeleteProfileEntry({
    userId,
    profileProperty: 'languageExams',
  });

  const addNewItemCancelHandler = () => {
    setIsAddNewItem(false);
  };

  return (
    <ProfileMainSection
      title="Upcoming Exams"
      onClick={() => setIsAddNewItem((prev) => !prev)}
      isButtonActive={isAddNewItem}
    >
      {isLoading ? (
        <SkeletonBox className="w-full h-[78px]" />
      ) : (
        <>
          {isAddNewItem && (
            <UpcomingExamsForm
              userId={userId}
              isModeAddNew
              setIsAddNewItem={setIsAddNewItem}
              onCancel={addNewItemCancelHandler}
            />
          )}
          {!!languageExams.length &&
            languageExams.map((exam) => (
              <UpcomingExamsCard
                key={exam._id}
                userId={userId}
                languageExam={exam}
                onDeleteEntry={deleteEntryHandler}
              />
            ))}
          {!isAddNewItem && !languageExams.length && !isMentorViewer && (
            <StepBanner
              stepOption={'missingExams'}
              onClick={() => setIsAddNewItem((prev) => !prev)}
            />
          )}
        </>
      )}
    </ProfileMainSection>
  );
};

export default UpcomingExamsSection;
