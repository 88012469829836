import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import Button from '@components/V4/Button';
import Input from '@components/V4/Inputs/Input';
import InputLabel from '@components/V4/Inputs/InputLabel';
import Modal from '@components/V4/Modal/Modal';
import { TrashIcon } from '@heroicons/react/outline';
import { LMSCourse } from '@shared/common';
import { PageHeader, Card, Tree, Select, Checkbox, Popconfirm } from 'antd';

import { useState, useEffect } from 'react';
import { TwitterPicker } from 'react-color';
import { useHistory } from 'react-router-dom';
import IndividualCourseButton from '../Course/IndividualCourseButton';

import ModuleResourcesModal from './ModuleResourcesModal';
import apiClient from '@contexts/AuthContext/apiClient';
import { useLMSCourses, useSoftDeleteCourse } from '@actions';
import useToast from '../../hooks/useToast';

const { DirectoryTree } = Tree;

const CourseManagementPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [treeData, setTreeData] = useState<any>([]);

  // form fields
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [lmsCourseId, setLmsCourseId] = useState(''); // for LMS
  const [lmsCoursePrice, setLmsCoursePrice] = useState(0); // for LMS
  const [abbreviation, setAbbreviation] = useState('');
  const [isFree, setIsFree] = useState(true);
  const [isMentorless, setIsMentorless] = useState(false);
  const [country, setCountry] = useState('');
  const [color, setColor] = useState('#cccccc');
  const [expandedKeys, setExpandedKeys] = useState<any>([]);
  const [selectedKeys, setSelectedKeys] = useState<any>([]);

  const history = useHistory();

  // module resources modal
  const [resourcesCourseId, setResourcesCourseId] = useState('');
  const [resourcesModuleIndex, setResourcesModuleIndex] = useState('');

  // LMS courses
  const { data: lmsCourses } = useLMSCourses();

  const toast = useToast();

  const { mutate: deleteCourse } = useSoftDeleteCourse(resourcesCourseId, {
    onSuccess: () => {
      toast.success('Course deleted successfully.');
      getAllCourses();
    },
  });

  const onSelect = (keys: any, info: any) => {
    setSelectedKeys([...keys]);
    if (keys[0].startsWith('new') || keys[0].startsWith('edit')) {
      history.push(`/admin/lesson/${keys[0]}?refType=Ltext`);
    }
    if (keys[0].startsWith('manage-courses/')) {
      history.push(`/admin/${keys[0]}`);
    }
    if (keys[0].startsWith('resources/')) {
      const info = keys[0].split('/');
      setResourcesCourseId(info[1]);
      setResourcesModuleIndex(info[2]);
    }
    if (keys[0].startsWith('delete-course/')) {
      const info = keys[0].split('/');
      setResourcesCourseId(info[1]);
    }
  };

  const onExpand = (keys: any) => {
    setExpandedKeys([...keys]);
  };

  useEffect(() => {
    getAllCourses();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const getAllCourses = () => {
    apiClient
      .get('/v2/course/list-all')
      .then((response) => {
        const courses = response.data.docs;
        const newTreeData = [];

        for (let i = 0; i < courses?.length; i++) {
          const currentChildren = [];
          currentChildren.push({
            title: 'EDIT COURSE DETAILS',
            key: `manage-courses/${courses[i]._id}`,
            isLeaf: true,
            icon: <EditOutlined />,
          });

          currentChildren.push({
            title: (
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => deleteCourse({})}
                okText="Yes"
                cancelText="No"
                style={{ zIndex: '99999 !important', width: '100px' }}
              >
                DELETE COURSE
              </Popconfirm>
            ),
            key: `delete-course/${courses[i]._id}`,
            isLeaf: true,
            icon: (
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => deleteCourse({})}
                okText="Yes"
                cancelText="No"
                style={{ zIndex: '99999 !important', width: '100px' }}
              >
                <TrashIcon />
              </Popconfirm>
            ),
          });

          newTreeData.push({
            title: courses[i].title,
            key: courses[i]._id,
            children: currentChildren,
          });
        }

        setTreeData(newTreeData);
      })
      .catch((error) => {
        toast.error('An error occured.');
        console.error(error);
      });
  };

  const handleOk = () => {
    apiClient
      .post('/v2/course/new', {
        title,
        subtitle,
        abbreviation,
        lmsCourseId,
        lmsCoursePrice,
        isFree,
        isMentorless,
        country,
        color,
      })
      .then((response) => {
        toast.success(response.data.message);
        handleCancel();
        getAllCourses();
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.data));
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setTitle('');
    setAbbreviation('');
    setLmsCourseId('');
    setLmsCoursePrice(0);
    setSubtitle('');
    setIsFree(true);
    setIsMentorless(false);
    setCountry('');
    setColor('#cccccc');
  };

  return (
    <div>
      <div>
        <PageHeader ghost={false} title="Manage Courses"></PageHeader>
      </div>

      <Card style={{ margin: 15, minHeight: 300 }}>
        <Button
          className="mb-2"
          buttonText="Add New Course"
          icon={<PlusOutlined />}
          onClick={showModal}
        />
        <DirectoryTree
          multiple
          onSelect={onSelect}
          onExpand={onExpand}
          treeData={treeData}
          expandedKeys={expandedKeys}
          selectedKeys={selectedKeys}
        />
      </Card>

      <Modal size="small" isOpen={isModalVisible} onClose={handleCancel}>
        <Modal.Header title="New Course" />
        <Modal.Body className="flex flex-col gap-1">
          <Input
            placeholder="Title"
            value={title}
            name="title"
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            id="title"
            label="Title"
          />

          <Input
            placeholder="Subtitle"
            value={subtitle}
            name="subtitle"
            onChange={(e) => setSubtitle(e.target.value)}
            type="text"
            id="subtitle"
            label="Subtitle"
          />

          <Input
            placeholder="Abbreviation"
            value={abbreviation}
            name="abbreviation"
            onChange={(e) => setAbbreviation(e.target.value)}
            type="text"
            id="abbreviation"
            label="Abbreviation"
          />

          <div>
            <InputLabel label="LMS Course" className="text-customRed" />
            <Select
              onChange={(val) => {
                setLmsCourseId(val);
                setLmsCoursePrice(lmsCourses?.find((c) => c.id === val)?.price ?? 0);
              }}
              value={lmsCourseId}
              className="w-full"
            >
              <Select.Option value="">No LMS course selected</Select.Option>
              {lmsCourses?.map((course: LMSCourse) => (
                <Select.Option value={course.id} key={course.id}>
                  {course.title} {course?.price ? `${course.price}$` : 'Free'}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div>
            <InputLabel label="Country" />
            <Select className="w-full" onChange={(val) => setCountry(val)} value={country}>
              <Select.Option value="">No targeted country</Select.Option>
              <Select.Option value="US">US</Select.Option>
              <Select.Option value="UK">UK</Select.Option>
              <Select.Option value="EU">EU</Select.Option>
            </Select>
          </div>

          <div className="flex flex-col gap-2">
            <Checkbox
              className="!ml-0"
              checked={isFree}
              onChange={(e) => setIsFree(e.target.checked)}
            >
              <p className="!text-white">Is free (check even if only a few lessons are free)</p>
            </Checkbox>

            <Checkbox
              className="!ml-0"
              checked={isMentorless}
              onChange={(e) => setIsMentorless(e.target.checked)}
            >
              <p>Is Mentorless</p>
            </Checkbox>
          </div>

          <p>Color</p>

          <TwitterPicker color={color} onChange={(c) => setColor(c.hex)} />

          <p className="font-bold">Preview</p>
          <IndividualCourseButton
            clickDisabled={true}
            data={{
              complete: 35,
              color,
              title,
              subtitle,
              nextLesson: 'Lesson Title Here',
            }}
          />
        </Modal.Body>

        <Modal.Footer className="mt-2">
          <div className="flex justify-end gap-4">
            <Button
              className="h-8"
              variant="secondary"
              buttonText="Cancel"
              onClick={handleCancel}
            />
            <Button className="h-8" buttonText="Create Course" onClick={handleOk} />
          </div>
        </Modal.Footer>
      </Modal>

      <ModuleResourcesModal
        courseId={resourcesCourseId}
        moduleIndex={resourcesModuleIndex}
        setCourseId={setResourcesCourseId}
        setModuleIndex={setResourcesModuleIndex}
      />
    </div>
  );
};

export default CourseManagementPage;
