import StepBanner from '@modules/Account/Profile/components/ProfileBanner/StepBanner';
import { Experience } from '@shared/common';
import { useContext, useState } from 'react';
import { ProfileContext } from '../../../AccountProfile';
import { useDeleteProfileEntry } from '../../../hooks/useDeleteProfileEntry';
import ProfileMainSection from '../../ResumeSection';
import ResumeTabWorkForm from './ResumeTabWorkForm';
import ResumeTabWorkItem from './ResumeTabWorkItem';
import SkeletonBox from '@components/V4/SkeletonBox';

interface ResumeWorkSectionProps {
  userId: string;
  experiences: Experience[];
  isMentorProfile: boolean;
}

const ResumeWorkSection = (props: ResumeWorkSectionProps) => {
  const { userId, experiences, isMentorProfile } = props;

  const { isMentorViewer, isLoading } = useContext(ProfileContext);

  const [isAddNewItem, setIsAddNewItem] = useState(false);

  const [deleteEntryHandler] = useDeleteProfileEntry({
    userId,
    profileProperty: 'experiences',
  });

  const addNewItemCancelHandler = () => {
    setIsAddNewItem(false);
  };

  return (
    <ProfileMainSection
      title="Experience"
      onClick={() => setIsAddNewItem((prev) => !prev)}
      isButtonActive={isAddNewItem}
    >
      {isLoading ? (
        <SkeletonBox className="w-full h-[78px]" />
      ) : (
        <>
          {isAddNewItem && (
            <ResumeTabWorkForm
              userId={userId}
              isModeAddNew
              setIsAddNewItem={setIsAddNewItem}
              onCancel={addNewItemCancelHandler}
            />
          )}
          {experiences?.map((exp) => (
            <ResumeTabWorkItem
              key={exp._id}
              userId={userId}
              jobDetails={exp}
              onDeleteEntry={deleteEntryHandler}
            />
          ))}

          {!isAddNewItem && !experiences?.length && (!isMentorViewer || isMentorProfile) && (
            <StepBanner
              stepOption={'missingWork'}
              onClick={() => setIsAddNewItem((prev) => !prev)}
            />
          )}
        </>
      )}
    </ProfileMainSection>
  );
};

export default ResumeWorkSection;
