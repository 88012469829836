import { ReactComponent as ArrowIcon } from '@assets/icons/arrow.svg';
import IconButton from '@components/V4/IconButton';
import { ZoomContext } from '@modules/zoom/contexts/ZoomContext';
import { LiveTranscriptionMessage } from '@zoom/videosdk';
import useObserveElement from 'apps/agora/src/hooks/useObserveElement';
import useToast from 'apps/agora/src/hooks/useToast';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import TranscriptMessages from './TranscriptMessage';
import { scrollToLastElement } from './helpers';

export interface TranscriptMessage {
  author: string;
  timeStamp: number;
  message: string;
  done: boolean;
  userId: number;
  messageId: string;
}

const SidebarTranscriptPage = () => {
  const { zoomClient, isMeetingLoading } = useContext(ZoomContext);
  const [transcriptMessages, setTranscriptMessages] = useState<TranscriptMessage[]>([]);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isTranscriptHistoryLoaded, setIsTranscriptHistoryLoaded] = useState(false);

  const containerRef = useObserveElement({
    id: 'last-scrolled-to-child',
    onIntersect: () => setShouldAutoScroll(true),
  });
  const toast = useToast();

  const getTranscriptionHistory = async () => {
    if (!zoomClient) return;

    const transcriptionClient = zoomClient.getLiveTranscriptionClient();

    try {
      const history = await transcriptionClient.getFullTranscriptionHistory();

      if (history && history.length > 0) {
        const formattedHistory = history.map((entry: LiveTranscriptionMessage) => ({
          author: entry.displayName ?? '',
          timeStamp: entry.timestamp,
          message: entry.text,
          done: !!entry.done,
          userId: entry.userId,
          messageId: entry.msgId,
        }));

        setTranscriptMessages(formattedHistory);
        setIsTranscriptHistoryLoaded(true);
      }
    } catch (error) {
      toast.error('Transcription history could not be loaded.');
    }
  };

  const newTranscriptHandler = (payload: LiveTranscriptionMessage) => {
    const { displayName, text, timestamp, msgId, done, userId } = payload;

    setTranscriptMessages((prevMessages) => {
      const existingMessageIndex = prevMessages.findIndex((message) => message.messageId === msgId);

      if (existingMessageIndex !== -1) {
        const updatedMessage = {
          ...prevMessages[existingMessageIndex],
          author: displayName ?? prevMessages[existingMessageIndex].author,
          timeStamp: timestamp ?? prevMessages[existingMessageIndex].timeStamp,
          message: text ?? prevMessages[existingMessageIndex].message,
          userId: userId,
          done: !!done,
          messageId: msgId,
        };

        const updatedMessages = [...prevMessages];
        updatedMessages[existingMessageIndex] = updatedMessage;

        return updatedMessages;
      } else {
        const newChatMessage: TranscriptMessage = {
          author: displayName ?? '',
          timeStamp: timestamp ?? '',
          message: text ?? '',
          done: !!done,
          userId: userId,
          messageId: msgId,
        };

        return [...prevMessages, newChatMessage];
      }
    });

    // Auto-scroll logic
    setShouldAutoScroll((currentFlag) => {
      if (currentFlag) {
        scrollToLastElement(containerRef.current, 'instant');
      } else {
        // If autoScrollFlag is false, increment the unreadMessage count
        setUnreadCount((prevCount) => prevCount + 1);
      }
      return currentFlag;
    });
  };

  useEffect(() => {
    if (isMeetingLoading || !zoomClient) return;

    getTranscriptionHistory();

    zoomClient.on('caption-message', newTranscriptHandler);
    return () => {
      zoomClient.off('caption-message', newTranscriptHandler);
    };
  }, [isMeetingLoading]);

  useLayoutEffect(() => {
    if (transcriptMessages.length > 0) {
      scrollToLastElement(containerRef.current, 'instant');
    }
  }, [isTranscriptHistoryLoaded]);

  // event listener on scroll
  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const handleScroll = () => {
      const sensibility = 20;
      const isAtBottom =
        container.scrollHeight - container.scrollTop - sensibility <= container.clientHeight;

      // Disable auto-scroll when the user scrolls away from the bottom
      if (!isAtBottom) {
        setShouldAutoScroll(false);
      } else {
        // Re-enable auto-scroll when the user scrolls to the bottom manually
        setShouldAutoScroll(true);
        setUnreadCount(0); // if we are here it means that we are at the bottom of the message container, thus there should be no unread messages
      }
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="relative flex flex-auto flex-col justify-between gap-2 text-white">
      <div className="p-2 bg-surfaceHover rounded-xl">
        <p className="text-xxs font-semibold">
          You will receive a copy of the transcript via email.
        </p>
      </div>

      <div ref={containerRef} className="flex flex-col gap-2 h-full max-h-full overflow-y-auto">
        {transcriptMessages.map((message, index) => (
          <TranscriptMessages
            message={message}
            key={message.messageId}
            shouldShowName={transcriptMessages[index - 1]?.userId !== message?.userId}
            id={transcriptMessages.length - 1 === index ? 'last-scrolled-to-child' : undefined}
          />
        ))}
      </div>

      {!shouldAutoScroll && (
        <div
          className="absolute cursor-pointer top-12 right-5"
          onClick={() => scrollToLastElement(containerRef.current)}
        >
          <IconButton
            variant="secondary"
            size="large"
            icon={<ArrowIcon className="rotate-180" />}
            className="relative bg-surfaceHover"
          />
          {unreadCount !== 0 && (
            <p className="absolute top-0 right-0 bg-customRed rounded-full text-xxs w-5 h-5 text-center p-[3px]">
              {unreadCount}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default SidebarTranscriptPage;
