import { FileAddOutlined } from '@ant-design/icons';
import SectionHeader from '@components/Common/SectionHeader';
import styles from '@styles/pages/mentor/knowledge-base.module.scss';
import { Button, Space } from 'antd';
import { Link } from 'react-router-dom';

import { COLORS } from '../../../v3/global';
import KnowledgeBaseWidget from './Widget';
import { useAuthState } from '@contexts/AuthContext';

const KnowledgeBasePage = () => {
  const { isAdmin } = useAuthState();

  return (
    <div
      style={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
        minHeight: 'calc(100vh - 75px)',
      }}
      className={styles.page}
    >
      <Space style={{ flexWrap: 'wrap' }}>
        <SectionHeader title="Knowledge Base" />
        {isAdmin && (
          <Link to="/admin/knowledge-base">
            <Button icon={<FileAddOutlined />}>New Article</Button>
          </Link>
        )}
      </Space>

      <div style={{ marginTop: '1rem' }}>
        <KnowledgeBaseWidget defaultArticleId="" showAdminButtons={isAdmin} />
      </div>
    </div>
  );
};

export default KnowledgeBasePage;
