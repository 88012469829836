import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import SessionModalFormWrapper from '@modules/Admin/components/DetailsModal/DetailsModalFormWrapper';
import { subjectsOptions, User } from '@shared/common';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useContext } from 'react';
import { useController, useForm } from 'react-hook-form';
import { UseMutateFunction } from 'react-query';
import { z } from 'zod';
import { MentorDetailsPageContext } from '../../../MentorDetailsPage';

interface SubjectsFormProps extends BasicProps {
  isLoading?: boolean;
  updateMyMentorData: UseMutateFunction<User, Error, Partial<any>, unknown>;
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  subjectsCanTeach: z.string().refine((value) => subjectsOptions.includes(value), {
    message: 'Please select a valid subject.',
  }),
});

type FormData = z.infer<typeof formDataSchema>;

const SubjectsForm = (props: SubjectsFormProps) => {
  const { isLoading, className, onCloseEditForm, updateMyMentorData } = props;

  const { mentor } = useContext(MentorDetailsPageContext);

  const majors = mentor?.mentorData?.majors;

  const majorsOptions = subjectsOptions.filter((major: string) => {
    return !majors?.find((m: string) => m === major);
  });

  const { handleSubmit, trigger, setValue, getValues, control } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const { field: subjectsCanTeachField, fieldState: subjectsCanTeachState } = useController({
    name: 'subjectsCanTeach',
    control,
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid && majors) {
      updateMyMentorData({
        majors: [...majors, data.subjectsCanTeach],
        userId: mentor._id,
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <SessionModalFormWrapper
      title="Subject"
      className={className}
      onCancel={onCloseEditForm}
      isLoading={!!isLoading}
      submitHandler={submitHandler}
    >
      <Select
        isRequired
        options={majorsOptions.map((option) => ({
          label: option,
          value: option,
        }))}
        onSelect={subjectsCanTeachField.onChange}
        onBlur={subjectsCanTeachField.onBlur}
        value={subjectsCanTeachField.value}
        size="large"
        id="subjects"
        label="Subjects"
        allowClear={false}
        isTouched={subjectsCanTeachState.isTouched}
        isValid={!subjectsCanTeachState.error}
        errorText={subjectsCanTeachState.error?.message}
      />
    </SessionModalFormWrapper>
  );
};

export default SubjectsForm;
