import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import AgoraTimePicker from '@components/V3/Utils/InputsV3/AgoraTimePicker';
import Button from '@components/V4/Button';
import Modal from '@components/V4/Modal/Modal';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMeeting, useRescheduleMeeting } from '@actions';
import moment from 'moment';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useAnalytics } from '../../contexts/AnalyticsContext';
import useToast from '../../hooks/useToast';
import useTrackMountUnmountEvent from '../../hooks/useTrackMountUnmountEvent';
import { ANALYTICS_EVENT_NAMES } from '../../utils/constants';

type RescheduleModalProps = {
  isOpen: boolean;
  meetingId: string;
  onClose: () => void;
  onReschedule?: () => void;
};

const formDataSchema = z.object({
  date: z.string().min(1, { message: 'Please enter a valid date.' }),
  time: z.string().min(1, { message: 'Please enter a valid time.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const RescheduleModal = (props: RescheduleModalProps) => {
  const { isOpen, meetingId, onClose, onReschedule } = props;

  const { trackEvent } = useAnalytics();

  useTrackMountUnmountEvent(
    ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_MODAL_OPEN,
    ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_MODAL_CLOSE
  );

  const { handleSubmit, trigger, setValue, control, getValues } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const { field: dateField, fieldState: dateFieldState } = useController({
    name: 'date',
    control,
  });

  const { field: timeField, fieldState: timeFieldState } = useController({
    name: 'time',
    control,
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const selectedTime = moment(data.time, 'H:mm');
      const newDateTime =
        data.date &&
        moment(
          moment(data.date).set({
            hour: selectedTime.get('hour'),
            minute: selectedTime.get('minute'),
            second: 0,
            millisecond: 0,
          })
        );

      reschedule({ startDate: moment(newDateTime).toISOString() });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    trackEvent(ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_TRIGGER);

    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  //TODO - think about a meeting being passed directly instead of fetching it inside this modal
  const { data: meeting } = useMeeting(meetingId);

  const toast = useToast();

  const { mutate: reschedule, isLoading } = useRescheduleMeeting(meetingId, {
    onSuccess() {
      trackEvent(ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_SUCCESS);
      toast.success('Meeting successfully rescheduled.');
      onReschedule?.();
    },
    onError(error: any) {
      const errorMessage =
        error?.response?.data?.error?.message ??
        error?.data?.message ??
        error.message ??
        'An error occurred';

      trackEvent(ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_FAIL, errorMessage);

      toast.error(errorMessage);
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small">
      <Modal.Header title="Reschedule Meeting" className="text-center" />
      <Modal.Body className="flex flex-col justify-center overflow-x-hidden mt-2 mb-8 gap-8 text-xs leading-5 text-center">
        <p className="w-max">
          Reschedule <b>{meeting?.name}</b> with <b>{meeting?.students[0]?.fullName}</b>
        </p>
        <div className="flex flex-col tablet:flex-row w-full gap-6">
          <AgoraDatePicker
            value={dateField.value ? moment(dateField.value) : undefined}
            onChange={(value) => dateField.onChange(moment(value).format('YYYY-MM-DD'))}
            disabledDate={(date) => date < moment(moment().format('YYYY-MM-DD'))}
            onBlur={dateField.onBlur}
            allowClear={false}
            isRequired
            label="Date"
            isValid={!dateFieldState.error}
            isTouched={dateFieldState.isTouched}
            errorText={dateFieldState.error?.message}
          />
          <AgoraTimePicker
            label="Time"
            disablePastHours
            date={dateField.value ? moment(dateField.value) : undefined}
            isRequired
            time={timeField.value}
            handleChange={(value: string) => timeField.onChange(value)}
            handleFocus={undefined}
            isValid={!timeFieldState.error}
            isTouched={timeFieldState.isTouched}
            errorText={timeFieldState.error?.message}
            onBlur={timeField.onBlur}
          />
        </div>

        <p className="w-max">
          Times are in your local timezone, it is currently {moment().format('HH:mm')}
        </p>
      </Modal.Body>
      <Modal.Footer className="flex justify-center gap-4">
        <Button
          size="large"
          className="w-full"
          variant="primary"
          buttonText="Reschedule"
          isLoading={isLoading}
          onClick={submitHandler}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default RescheduleModal;
