import { ErrorCodes } from '@shared/constants';
import { message } from 'antd';
import qs from 'qs';

import { QueryKey, QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query';
import apiClient from '@contexts/AuthContext/apiClient';
import { useEnvironmentState } from '@contexts/EnvironmentContext';
import useToast from '../useToast';

function useAuthQueryWithParams<ResponseData>(
  query: QueryKey,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any,
  options?: UseQueryOptions<ResponseData>,
  noCache?: boolean
) {
  const { device } = useEnvironmentState();
  const isWeb = device === 'web';
  const toast = useToast();

  const queryFn = async ({ queryKey }: QueryFunctionContext) => {
    const [endpoint, ...variables] = queryKey;

    let url = endpoint as string;

    variables.forEach((key) => (url = url.replace(/:[a-z]+/, key as string)));

    const queryParams = qs.stringify(params, { arrayFormat: 'indices' });
    url += '?' + queryParams;
    const { data } = await apiClient.get(url);

    return data;
  };

  const defaultOptions: UseQueryOptions<ResponseData> = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (err: any) => {
      if (isWeb) {
        if (
          err?.response?.data?.error?.code === ErrorCodes.TOKEN_EXPIRED ||
          err?.response?.error?.code === ErrorCodes.TOKEN_EXPIRED ||
          err?.error?.code === ErrorCodes.TOKEN_EXPIRED ||
          err?.code === ErrorCodes.TOKEN_EXPIRED
        ) {
          toast.error('Your session has expired. Please login again.');
        } else {
          toast.error(
            err.response?.data?.error?.message ||
              err.response?.error?.message ||
              err.error?.message ||
              err?.message ||
              'An error occurred'
          );
        }
      }
    },
  };

  const queryCache = typeof query === 'string' ? [query] : [...query];
  if (params) queryCache.push(JSON.stringify(params));

  const queryOptions: UseQueryOptions<ResponseData> = {
    ...defaultOptions,
    ...options,
  };

  if (noCache) {
    queryOptions.cacheTime = 0;
  }

  return useQuery<ResponseData>(queryCache, queryFn, queryOptions);
}

export default useAuthQueryWithParams;
