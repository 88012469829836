import { ReactComponent as CloseIcon } from '@assets/icons/V4/close-icon.svg';
import IconButton from '@components/V4/IconButton';
import { PageNameType } from '@modules/zoom/types/PageNameType';
import { ConnectionState } from '@zoom/videosdk';
import { Dispatch, SetStateAction } from 'react';
import NotesPage from './NotesPage';
import SidebarChatPage, { ChatMessage } from './SidebarChatPage';
import SidebarConnectionPage from './SidebarConnectionPage/SidebarConnectionPage';
import SidebarParticipantsPage from './SidebarParticipantsPage';
import SidebarTranscriptPage from './SidebarTranscriptPage';

type Props = {
  pageName: PageNameType;
  isScreenBeingShared?: boolean;
  chatMessages: ChatMessage[];
  unreadCount: number;
  connectionState: ConnectionState;
  networkQuality?: Record<number, { uplink: number; downlink: number }>;
  handleCloseSidebar: () => void;
  onUnreadCountChange: Dispatch<SetStateAction<number>>;
};

const VideoSidebar = (props: Props) => {
  const {
    pageName,
    chatMessages,
    unreadCount,
    networkQuality,
    connectionState,
    isScreenBeingShared = false,
    handleCloseSidebar,
    onUnreadCountChange,
  } = props;

  const displayPageByPageType = () => {
    switch (pageName) {
      case 'chat':
        return (
          <SidebarChatPage
            onUnreadCountChange={onUnreadCountChange}
            unreadCount={unreadCount}
            chatMessages={chatMessages}
          />
        );
      case 'participants':
        return <SidebarParticipantsPage />;
      case 'transcript':
        return <SidebarTranscriptPage />;
      case 'notes':
        return <NotesPage />;
      case 'connection':
        return (
          <SidebarConnectionPage
            connectionState={connectionState}
            networkQuality={networkQuality}
            isScreenBeingShared={isScreenBeingShared}
          />
        );
      default:
        return null;
    }
  };

  return (
    <section className="flex flex-col bg-surfaceBackground min-w-62.5 w-62.5 tablet:min-w-90 tablet:w-90 h-full px-4 py-6 z-10 rounded-tl-xl rounded-bl-xl ">
      <div className="flex justify-between mb-6">
        <h2 className="capitalize">{pageName}</h2>
        <IconButton
          onClick={handleCloseSidebar}
          variant="ghost"
          icon={<CloseIcon className="h-4 fill-white" />}
        />
      </div>
      {displayPageByPageType()}
    </section>
  );
};

export default VideoSidebar;
