import { Enrollment } from '@shared/common';
import { useAddSession } from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import React, { useState } from 'react';
import SessionModalSection, { ActionButton } from '../../DetailsModalSection';
import ChangeMentorForm from './ChangeMentorForm';
import SessionsList from './SessionsList';

interface SessionsListSectionProps {
  enrollment: Enrollment;
  onSessionModalOpen: (session: any) => void;
}

const SessionsListSection = (props: SessionsListSectionProps) => {
  const { enrollment, onSessionModalOpen } = props;

  const [isChangeMentor, setIsChangeMentor] = useState(false);

  const toast = useToast();

  const { mutate: addSession } = useAddSession({
    onSuccess() {
      toast.success('Successfully added a slot.');
    },
    onError() {
      toast.error('Slot could not be added.');
    },
  });

  const buttons: ActionButton[] = [
    {
      buttonText: 'Change Mentor',
      onClick: () => setIsChangeMentor(true),
    },
    {
      buttonText: 'Add New Session Slot',
      onClick: () => addSession({ enrollmentId: enrollment._id }),
    },
  ];

  return isChangeMentor ? (
    <ChangeMentorForm defaultData={enrollment} onCloseEditMode={() => setIsChangeMentor(false)} />
  ) : (
    <SessionModalSection sectionTitle={'Session List'} buttonsArray={buttons}>
      <SessionsList
        enrollmentId={enrollment._id}
        enrollment={enrollment}
        onSessionModalOpen={onSessionModalOpen}
      />
    </SessionModalSection>
  );
};

export default SessionsListSection;
