import CourseCard from '@components/V3/CourseCard';
import SkeletonBox from '@components/V4/SkeletonBox';
import { COURSES } from '@routes';
import { Course } from '@shared/common';
import { useLoginAndRedirectToCourse } from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import { useHistory } from 'react-router-dom';
interface CoursesSectionProps {
  courses?: Partial<Course>[];
  isLoading: boolean;
}
const CoursesSection = (props: CoursesSectionProps) => {
  const { courses, isLoading } = props;
  const history = useHistory();
  const toast = useToast();

  const { mutate: redirectToLMS } = useLoginAndRedirectToCourse({
    onSuccess: (data) => {
      if (data?.url) {
        setTimeout(() => {
          window.open(data.url, '_blank');
        });
      }
    },
    onError: () => {
      toast.error('Failed to redirect to LMS!');
    },
  });

  return (
    <section className="grid gap-4 grid-cols-1 tablet:grid-cols-2 largeLaptop:grid-cols-3">
      {!!courses?.length &&
        !isLoading &&
        courses?.map((course) => (
          <div
            key={course._id}
            className="cursor-pointer"
            onClick={() => {
              if (course?.lmsCourseId) {
                redirectToLMS({ courseId: course.lmsCourseId });
              } else {
                history.push(`${COURSES}/${course._id}`);
              }
            }}
          >
            <CourseCard
              image={course?.headerImage?.['160x160'] ? course.headerImage['160x160'] : null}
              title={course?.title}
              subtitle={course.subtitle}
              color={course.color}
            />
          </div>
        ))}

      {isLoading &&
        [...Array(9).keys()].map((_, index) => (
          <SkeletonBox key={index} className="w-full min-w-17 min-h-28" />
        ))}
    </section>
  );
};

export default CoursesSection;
