import { ReactComponent as ArrowIcon } from '@assets/icons/V4/arrow-icon.svg';
import { ReactComponent as BinIcon } from '@assets/icons/bin-scaled-icon.svg';
import USBigPrimary from '@assets/images/us_big_1.jpg';
import IconButton from '@components/V4/IconButton';
import {
  useReorderCoursesCanTeach,
  useGetAllCoursesCanTeach,
  useUpdateMyMentorData,
} from '@actions';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import ProfileMainSection from '../ResumeSection';
import CoursesCanTeachForm from './CoursesCanTeachForm';
import SkeletonBox from '@components/V4/SkeletonBox';
import { ProfileContext } from '../../AccountProfile';

interface CoursesCanTeachSectionProps {
  userId?: string;
}

const CoursesCanTeachSection = (props: CoursesCanTeachSectionProps) => {
  const { userId } = props;

  const [isAddNewItem, setIsAddNewItem] = useState(false);
  const { isLoading: isProfileLoading } = useContext(ProfileContext);

  const { trackEvent } = useAnalytics();

  const { data: allCourses, isLoading: isLoadingCoursesCanTeach } =
    useGetAllCoursesCanTeach(userId);
  const { mutate: reorderCoursesCanTeach, isLoading: isReorderingCoursesCanTeach } =
    useReorderCoursesCanTeach({
      onSuccess: () => trackEvent(ANALYTICS_EVENT_NAMES.PROFILE_COURSES_CAN_TEACH_CHANGE),
    });

  const toast = useToast();
  const query = useQueryClient();

  const { mutate: updateMyMentorData, isLoading } = useUpdateMyMentorData({
    onSuccess: async () => {
      trackEvent(ANALYTICS_EVENT_NAMES.PROFILE_COURSES_CAN_TEACH_CHANGE);

      toast.success('Successfully updated your profile.');
      await query.invalidateQueries('/users/:id/profile');
      setIsAddNewItem(false);
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const deleteEntryHandler = (_id: string) => {
    if (allCourses) {
      reorderCoursesCanTeach({
        coursesCanTeach: [
          {
            _id,
            delete: true,
          },
        ],
      });
    }
  };

  const decrementOrderHandler = (course: any) => {
    if (!isReorderingCoursesCanTeach) {
      reorderCoursesCanTeach({
        coursesCanTeach: [
          {
            _id: course._id,
            newOrder: course.order - 1,
          },
        ],
      });
    }
  };
  const incrementOrderHandler = (course: any) => {
    if (!isReorderingCoursesCanTeach) {
      reorderCoursesCanTeach({
        coursesCanTeach: [
          {
            _id: course._id,
            newOrder: course.order + 1,
          },
        ],
      });
    }
  };

  return (
    <ProfileMainSection
      title={'Courses Can Teach'}
      onClick={() => setIsAddNewItem((prev) => !prev)}
      isButtonActive={isAddNewItem}
    >
      {isAddNewItem && (
        <CoursesCanTeachForm
          isModeAddNew={true}
          userId={userId ?? ''}
          onCancel={() => setIsAddNewItem(false)}
          setIsAddNewItem={setIsAddNewItem}
          coursesCanTeach={allCourses}
          updateMyMentorData={updateMyMentorData}
          isLoading={isLoading}
        />
      )}
      {!isAddNewItem && (
        <div className="flex flex-col gap-2">
          {isLoadingCoursesCanTeach || isProfileLoading
            ? [...Array(6).keys()].map((_, index) => (
                <SkeletonBox key={index} className="h-16 w-full rounded-lg" />
              ))
            : allCourses?.map((course) => (
                <div key={course._id} className="flex items-center justify-between py-2 px-4">
                  <div className="flex items-center gap-4 ">
                    <img
                      src={
                        course.headerImage?.['160x160'] ||
                        course.headerImage?.['470x320'] ||
                        course.headerImage?.original ||
                        USBigPrimary
                      }
                      className="hidden w-12 h-12 rounded-lg object-cover tablet:block"
                      alt={course.title}
                      crossOrigin={'anonymous'}
                    />
                    <div className="flex">
                      <div className="">
                        <div className="flex gap-3">
                          <p className="text-base text-white font-raleway font-bold">
                            {course.title}
                          </p>

                          {/* FUNCTIONALITY ON HOLD FOR NOW */}
                          {/* <div className="flex items-center gap-2">
                        <StarIcon className="text-customGold w-4 h-4" />
                        <p className="font-raleway font-bold text-base text-customGold">
                          4.76
                        </p>
                      </div> */}
                        </div>
                        <p className="text-sm text-customGrey font-raleway font-bold">
                          {course.activeStudents
                            ? `${course.activeStudents} active students.`
                            : 'No active students.'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    {course.order > 0 && (
                      <IconButton
                        className="flex items-center justify-center"
                        icon={<ArrowIcon />}
                        onClick={() => decrementOrderHandler(course)}
                        isDisabled={isReorderingCoursesCanTeach}
                      />
                    )}

                    {allCourses.length && course.order < allCourses.length - 1 && (
                      <IconButton
                        className="flex items-center justify-center"
                        icon={<ArrowIcon className="rotate-180 " />}
                        onClick={() => incrementOrderHandler(course)}
                        isDisabled={isReorderingCoursesCanTeach}
                      />
                    )}
                    <IconButton
                      onClick={() => deleteEntryHandler(course._id)}
                      variant="ghost"
                      color="danger"
                      disabled={isLoading}
                      className="tablet:mr-4"
                      icon={<BinIcon className="h-4 w-4" />}
                    />
                  </div>
                </div>
              ))}
        </div>
      )}
    </ProfileMainSection>
  );
};

export default CoursesCanTeachSection;
