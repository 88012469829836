import { Tooltip as AntTooltip, TooltipProps as AntTooltipProps } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import { ReactNode } from 'react';
import { mergeClassNames } from '../../utils/helpers';

interface TooltipProps {
  text: ReactNode;
  overlayClassName?: string;
}

const Tooltip = (props: TooltipProps & AntTooltipProps & BasicProps) => {
  const { children, text, placement = 'top', trigger = 'hover', overlayClassName, ...rest } = props;

  return (
    <AntTooltip
      {...rest}
      zIndex={9999}
      placement={placement}
      trigger={trigger}
      overlayClassName={mergeClassNames('custom-tooltip', overlayClassName)}
      title={text}
    >
      {children}
    </AntTooltip>
  );
};

export default Tooltip;
