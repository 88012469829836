import { ApiData, ApiDescription, RequestsMethod, Course } from '../common';

export const getUserCourses: ApiDescription = {
  endpoint: '/v3/courses',
  method: RequestsMethod.Get,
};
export type GetUserCourses = ApiData<never, Course[]>;

export const softDeleteCourse: ApiDescription = {
  endpoint: '/v3/courses/:id/soft-delete',
  method: RequestsMethod.Delete,
};

export const resetDeletedStatusForCourses: ApiDescription = {
  endpoint: '/v3/course/reset-deleted-status-for-courses',
  method: RequestsMethod.Patch,
};
export type ResetDeletedStatusForCourses = ApiData<unknown, any>;

export type SoftDeleteCourse = ApiData<unknown, any>;

export const getCourse: ApiDescription = {
  endpoint: '/courses/:id',
  method: RequestsMethod.Get,
};
export type GetCourse = ApiData<unknown, Course>;

export const getAllCourses: ApiDescription = {
  endpoint: '/v2/course/listAll',
  method: RequestsMethod.Get,
};
export type GetAllCourses = ApiData<unknown, Course[]>;

export const getAllCoursesLite: ApiDescription = {
  endpoint: '/v2/course/listAllTitles',
  method: RequestsMethod.Get,
};
export type GetAllCoursesLite = ApiData<unknown, Course[]>;

export const getCoursesByMentor: ApiDescription = {
  endpoint: '/v2/course/listByMentor',
  method: RequestsMethod.Get,
};
export type GetCoursesByMentor = ApiData<unknown, Course[]>;

export const uploadCourseImage: ApiDescription = {
  endpoint: '/v2/course/upload-image/:id',
  method: RequestsMethod.Post,
};

export type UploadCourseImage = ApiData<unknown, any>;

export const addCourse = {
  endpoint: '/v2/course/new',
  method: RequestsMethod.Post,
};

export type AddCourse = ApiData<unknown, unknown>;

export const editCourse = {
  endpoint: '/course/edit',
  method: RequestsMethod.Post,
};

export type EditCourse = ApiData<unknown, unknown>;

export const getDetails = {
  endpoint: '/course/get-details/:id',
  method: RequestsMethod.Get,
};

export type GetDetails = ApiData<unknown, unknown>;

export const listAvailableCourses = {
  endpoint: '/course/available',
  method: RequestsMethod.Get,
};

export type ListAvailableCourses = ApiData<unknown, unknown>;

export const listAll = {
  endpoint: '/v2/course/list-all',
  method: RequestsMethod.Get,
};

export type ListAll = ApiData<unknown, unknown>;
