import { useCheckPendingNPS } from '@actions';
import { useEffect } from 'react';
import LongNPSModal from './LongNPSModal';
import MonthlyNPSModal from './MonthlyNPSModal';

type Props = {
  handleCurrentModal?: (modalName: string) => void;
};

const PendingNPS = ({ handleCurrentModal }: Props) => {
  const { data, isLoading } = useCheckPendingNPS();
  const firstUpgradeNPS = Math.random() < 0.5;

  useEffect(() => {
    if (handleCurrentModal && !isLoading && !data) {
      handleCurrentModal('mentor-capacity-modal');
    } else if (handleCurrentModal && !isLoading && data) {
      handleCurrentModal('nps-modal');
    }
  }, [isLoading]);

  if (!data) return null;
  if (data?.type === 'monthly')
    return (
      <MonthlyNPSModal
        visible={true}
        firstUpgradeNPS={firstUpgradeNPS}
        handleCurrentModal={handleCurrentModal}
      />
    );
  if (data?.type === 'long')
    return (
      <LongNPSModal
        visible={true}
        courseTitle={data?.courseTitle ?? ''}
        mentorName={data?.mentorName ?? ''}
      />
    );

  return null;
};

export default PendingNPS;
