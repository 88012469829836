import { Enrollment } from '@shared/common';
import { useUploadProfilePicture } from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import moment from 'moment';
import { ChangeEvent, useContext } from 'react';
import { ReactComponent as DriveIcon } from '@assets/icons/V4/drive-icon.svg';
import { useQueryClient } from 'react-query';
import { ProfileContext } from '../AccountProfile';
import Avatar from './Avatar';
import SkeletonBox from '@components/V4/SkeletonBox';
import IconButton from '@components/V4/IconButton';

interface ProfileAsideHeaderProps {
  name: string;
  profileImg: string;
  studentType: string;
  createdAt: string;
  googleDriveLink?: string;
  enrollments: Partial<Enrollment>[];
  graduationYear?: number;
  hasEditPermissions: boolean;
  isMentorProfile: boolean;
}
const ProfileAsideHeader = (props: ProfileAsideHeaderProps) => {
  const {
    name,
    profileImg,
    studentType,
    createdAt,
    enrollments,
    graduationYear,
    googleDriveLink,
    hasEditPermissions,
    isMentorProfile,
  } = props;

  const toast = useToast();
  const query = useQueryClient();

  const { isExternalViewer } = useContext(ProfileContext);

  const { isLoading } = useContext(ProfileContext);

  const { mutate: uploadImage } = useUploadProfilePicture({
    onSuccess: async () => {
      toast.success('Successfully updated your profile.');
      await query.invalidateQueries('/users/:id/profile');
    },
    onError: () => {
      toast.error('Profile image could not be saved.');
    },
  });

  const imageChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      uploadImage(formData);
    }
  };

  const completedEnrollments = enrollments
    .filter((enrollment) => enrollment.ended === true && enrollment.course?.abbreviation?.trim())
    .reduce<Partial<Enrollment>[]>((acc, enrollment) => {
      if (!acc.some((e: Partial<Enrollment>) => e?.course?._id === enrollment?.course?._id)) {
        acc.push(enrollment);
      }
      return acc;
    }, []);

  const startDate = moment(createdAt);
  const endDate = moment();

  const years = endDate.diff(startDate, 'years');
  const months = endDate.diff(startDate.add(years, 'years'), 'months');

  const yearsOnAgora = years ? `${years} ${years > 1 ? 'years,' : 'year,'}` : '';

  const monthsOnAgora = months ? `${months} ${months > 1 ? 'months' : 'month'}` : '';

  const onAgoraSince = `${yearsOnAgora} ${monthsOnAgora}`;

  return (
    <div className="relative flex flex-col items-center gap-6">
      {isLoading ? (
        <>
          <SkeletonBox className="w-16 h-16 rounded-full" />
          <div className="flex flex-col items-center gap-1">
            <SkeletonBox className="h-5 my-1 w-64" />
            {!isMentorProfile && <SkeletonBox className="h-3 my-0.5 w-64" />}
            {isExternalViewer && <SkeletonBox className="h-3 my-1 w-64" />}
            <SkeletonBox className="h-3 my-1 w-64" />

            <div className="flex gap-3 flex-wrap justify-center">
              {[...Array(3).keys()].map((_, index) => (
                <SkeletonBox key={index} className="h-[29px] w-9 rounded-lg" />
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          {!!googleDriveLink && isExternalViewer && (
            <a
              href={`https://drive.google.com/drive/folders/${googleDriveLink}`}
              target="_blank"
              rel="noreferrer"
            >
              <IconButton
                className="w-8 h-8 absolute top-0 right-0"
                icon={<DriveIcon className="w-8 h-8" />}
              />
            </a>
          )}

          <Avatar
            hasEditPermissions={hasEditPermissions}
            profileImg={profileImg}
            onImageChange={imageChangeHandler}
          />
          <div className="flex flex-col items-center gap-1">
            <h1 className="font-raleway text-xl font-bold leading-6">{name}</h1>
            <p className="text-xs font-semibold">{studentType}</p>
            {isExternalViewer && (
              <p className="text-xs leading-5 text-customGrey">Graduation Year: {graduationYear}</p>
            )}
            <p className="text-xs text-customGrey">
              {onAgoraSince}
              {!isMentorProfile && (
                <>
                  <span className="mx-0.5"> •</span>
                  {completedEnrollments.length}
                  {completedEnrollments.length !== 1 ? ' courses ' : ' course '}
                  completed
                </>
              )}
            </p>

            <div className="flex gap-3 flex-wrap justify-center">
              {completedEnrollments.map((enrollment) => (
                <div key={enrollment._id} className=" bg-customGold rounded-lg">
                  <p className="py-1 px-1 text-black font-bold">
                    {enrollment.course?.abbreviation}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileAsideHeader;
