import { BasicProps } from 'apps/agora/src/utils/types';
import { ChangeEvent } from 'react';
import { ReactComponent as DefaultPfp } from '@assets/icons/V4/default-profile-pic.svg';
import { ReactComponent as UploadIcon } from '@assets/icons/V4/upload-icon.svg';

interface AvatarProps extends BasicProps {
  hasEditPermissions: boolean;
  profileImg: string;
  onImageChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Avatar = (props: AvatarProps) => {
  const { hasEditPermissions, profileImg, onImageChange } = props;

  return hasEditPermissions ? (
    <div className="relative group rounded-full cursor-pointer">
      <input
        type="file"
        accept="image/*"
        className="absolute top-0 left-0 opacity-0 z-20 w-16 h-16 rounded-full"
        onChange={onImageChange}
      />
      {profileImg.length ? (
        <img
          src={profileImg}
          className="rounded-full w-16 h-16 object-cover object-center"
          alt=""
          crossOrigin={'anonymous'}
        />
      ) : (
        <DefaultPfp className="rounded-full w-16 h-16" />
      )}
      <div className=" absolute z-10 top-0 left-0 flex items-center justify-center h-full w-full bg-black bg-opacity-65 rounded-full laptop:bg-transparent laptop:group-hover:bg-black laptop:group-hover:bg-opacity-65 ">
        <UploadIcon className="text-white laptop:hidden laptop:group-hover:flex" />
      </div>
    </div>
  ) : (
    <div>
      {profileImg.length ? (
        <img src={profileImg} className="rounded-full w-16 h-16" alt="" crossOrigin={'anonymous'} />
      ) : (
        <DefaultPfp className="rounded-full w-16 h-16" />
      )}
    </div>
  );
};

export default Avatar;
