import { Meeting } from '@shared/common';
import SessionModalEmptyState from '../../../DetailsModalEmptyState';
import SessionMeeting from './SessionMeeting';
import { useMemo } from 'react';

interface SessionMeetingsTabProps {
  meetings: Meeting[];
  name: string;
}

const SessionMeetingsTab = (props: SessionMeetingsTabProps) => {
  const { meetings, name } = props;

  const reversedMeetings = useMemo(() => [...meetings].reverse(), [meetings]);

  return (
    <div>
      <div className="flex flex-col gap-1">
        {!reversedMeetings.length ? (
          <SessionModalEmptyState />
        ) : (
          reversedMeetings.map((meeting, index) => (
            <SessionMeeting
              name={`Meeting ${reversedMeetings.length - index}`}
              key={meeting._id}
              isAccordionExpanded={index === 0}
              meeting={meeting}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default SessionMeetingsTab;
