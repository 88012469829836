import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import { COLORS } from '../../../../v3/global';
import './spinner.styles.scss';

type Props = {
  useBlack?: boolean;
  color?: string;
  fontSize?: number;
  margin?: string;
};

/**
 * @deprecated
 */
const AgoraSpinner = ({ useBlack, color, fontSize, margin }: Props) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: fontSize ?? 16,
        color: color ? color : useBlack ? COLORS.BLACK : COLORS.DARK_WHITE,
        margin: margin ?? '0px',
      }}
    />
  );

  return <Spin className="spinner-container" indicator={antIcon} />;
};

export default AgoraSpinner;
