import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { Enrollment } from '@shared/common';
import useToast from 'apps/agora/src/hooks/useToast';
import { useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import SessionModalFormWrapper from '../../DetailsModalFormWrapper';
import { useDebounce } from '@hooks';
import { useGetAllMentors } from 'apps/agora/src/actions/users.actions';
import { useUpdateEnrollment } from '@actions';

interface ChangeMentorFormProps {
  defaultData: Enrollment;
  onCloseEditMode: () => void;
}

const formDataSchema = z.object({
  mentor: z.string().min(1, { message: 'Please select a mentor.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const ChangeMentorForm = (props: ChangeMentorFormProps) => {
  const { defaultData, onCloseEditMode } = props;

  const toast = useToast();

  const [mentorSearch, setMentorSearch] = useState('');

  const debouncedMentorSearch = useDebounce(mentorSearch, 500);

  const { data: mentorsData, isLoading: mentorsLoading } = useGetAllMentors({
    search: debouncedMentorSearch,
    active: true,
  });

  let mentorsOptions: { label: string; value: string }[] = [];

  if (mentorsData) {
    mentorsOptions = mentorsData.map((user) => ({
      label: user.fullName,
      value: user._id,
    }));
  }

  mentorsOptions?.unshift({
    label: 'No Mentor',
    value: 'TBD',
  });

  const { handleSubmit, trigger, setValue, control, getValues } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const { field: mentorField, fieldState: mentorFieldState } = useController({
    name: 'mentor',
    control,
  });

  const { mutate: updateEnrollment, isLoading } = useUpdateEnrollment({
    onSuccess: async () => {
      toast.success('Successfully updated the mentor.');
      onCloseEditMode();
    },
    onError: () => {
      toast.error('Mentor could not be updated.');
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateEnrollment({
        enrollmentId: defaultData._id,
        mentorId: data.mentor,
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  const mentorSelectorBlurHandler = () => {
    mentorField.onBlur();
    setMentorSearch('');
  };

  return (
    <SessionModalFormWrapper
      title={'Mentor'}
      isLoading={isLoading}
      onCancel={onCloseEditMode}
      submitHandler={submitHandler}
    >
      <Select
        label="Mentor"
        value={mentorField.value}
        filterOption={() => true}
        searchValue={mentorSearch}
        onSearch={setMentorSearch}
        onSelect={(value) => mentorField.onChange(value)}
        onBlur={mentorSelectorBlurHandler}
        options={mentorsOptions}
        notFoundContent={
          mentorsLoading ? <LoadingSpinner className="mx-auto" size="normal" /> : null
        }
        placeholder="Search Mentor"
        variant="primary"
        isLoading={mentorsLoading}
        isValid={!mentorFieldState.error}
        isTouched={mentorFieldState.isTouched}
        errorText={mentorFieldState.error?.message}
        isRequired
      />
    </SessionModalFormWrapper>
  );
};

export default ChangeMentorForm;
