import { RenderRoutes } from '@components';
import PreOnboardingPage from '../Account/PreOnboarding';

const Auth = () => {
  return (
    <RenderRoutes routes={[{ path: '/welcome', component: PreOnboardingPage }]} redirectPath="/" />
  );
};

export default Auth;
