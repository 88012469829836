import SkeletonBox from '@components/V4/SkeletonBox';

interface HeaderLabelAndValueProps {
  isLoading?: boolean;
  label: string;
  value?: string;
}
const HeaderLabelAndValue = (props: HeaderLabelAndValueProps) => {
  const { label, value, isLoading = false } = props;

  if (isLoading) {
    return <SkeletonBox className="h-12 w-40 tablet:h-3.5 tablet:my-0.75 tablet:w-80" />;
  }

  if (!value) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-2 text-sm leading-5 tablet:flex-row ">
      <h3 className="font-bold text-customGrey">{label}</h3>
      <p>{value}</p>
    </div>
  );
};

export default HeaderLabelAndValue;
