import { MoreOutlined } from '@ant-design/icons';
import DeactivateAccountIcon from '@assets/icons/deactivate-account-icon.svg';
import EditIcon from '@assets/icons/edit-icon.svg';
import ImpersonateIconPrimary from '@assets/icons/impersonate-icon-primary.svg';
import ImpersonateIcon from '@assets/icons/impersonate-icon.svg';
import JSONDetailsModal from '@components/V3/JSONDetailsModal';
import ProfileModalUsers from '@components/V3/ProfileModalUsers';
import UserPermissionsModal from '@components/V3/UserPermissionsModal';
import UserStatusModal from '@components/V3/UserStatusModal';

import { ADMIN_ROLE, MENTOR_ROLE, ROLES, STUDENT_ROLE } from '@shared/constants';
import {
  useChangeUserTestState,
  useCheckDriveStatus,
  useCreateUserDriveByAdmin,
  useSendActivationLink,
  useSetActiveStatus,
} from '@actions';
import { Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { COLORS } from '../../../v3/global';
import './users-table-row.styles.scss';
import AgoraSpinner from '@utils/AgoraSpinner';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import usePossessUser from 'apps/agora/src/hooks/usePossessUser';
import { useAuthState } from '@contexts/AuthContext';
import useToast from 'apps/agora/src/hooks/useToast';

type Props = {
  key?: string | number;
  userData?: any;
  refetchSearchUsers: () => void;
  userId: string;
};

const UsersTableRow = ({ key, userData, refetchSearchUsers, userId }: Props) => {
  const { setPossessedUserId } = usePossessUser();
  const { userId: adminUserId } = useAuthState();
  const toast = useToast();

  const { mutate: deactivateUser } = useSetActiveStatus({
    onSuccess: (data, variables) => {
      toast.success(`User  ${variables.deactivate ? 'deactivated' : 'activated'} successfully`);
      refetchSearchUsers();
    },
  });

  const { mutate: changeUserTestState, isLoading: isTestLoading } = useChangeUserTestState({
    onSuccess: (data, variables) => {
      toast.success(
        `User ${userData?.fullName} ${
          variables.isTestUser ? 'is now a Test User.' : 'has been reverted to Normal User.'
        }`
      );

      refetchSearchUsers();
    },
  });

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showJSONDataModal, setShowJSONDataModal] = useState(false);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);

  const [shouldCheckDriveStatus, setShouldCheckDriveStatus] = useState(false);
  const [driveLink, setDriveLink] = useState<any>(null);
  const [driveStatus, setDriveStatus] = useState<any>(null);

  const [driveFolderCreated, setDriveFolderCreated] = useState(false);

  type DriveStatus = {
    status: number;
  };

  const {
    data: driveStatusData,
    refetch: refetchDriveStatusData,
    isLoading: isLoadingDriveStatusData,
  } = useCheckDriveStatus(
    { id: userId, searchBy: 'id' },
    {
      enabled: shouldCheckDriveStatus,
      onSuccess: (response: any) => {
        if (response?.status === 0) {
          setDriveStatus('Not created');
        } else if (response?.status === 1) {
          setDriveStatus('Already created');
        } else if (response?.status === 2) {
          setDriveStatus('Missing');
        }
      },
      onError(err: any) {
        toast.error(err?.message || 'An error has occurred!');
      },
    }
  );

  const [record, setRecord] = useState<any>(null);

  const handleArrowClick = () => {
    setIsClicked(!isClicked);
  };

  const handleShowDeactivateModal = (record?: any) => {
    if (record) {
      setRecord(record);
    }
    setShowDeactivateModal(!showDeactivateModal);
  };

  const handleShowProfileModal = () => {
    setShowProfileModal(!showProfileModal);
  };

  const handleShowPermissionsModal = () => {
    setShowPermissionsModal(!showPermissionsModal);
  };

  const { mutate: sendActivationEmail } = useSendActivationLink({
    onSuccess: () => {
      toast.success('Activation email sent successfully');
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.error?.message ?? err.message ?? err?.data?.error?.message);
    },
  });

  const { mutate: createUserDrive, isLoading: isLoadingCreateUserDrive } =
    useCreateUserDriveByAdmin({
      onSuccess: (data: any) => {
        setDriveLink(data);
        setDriveFolderCreated(true);
        toast.success('Drive created successfully');
      },
      onError: (err: any) => {
        toast.error(`Error: ${err.response?.data?.message || err.message}`);
      },
    });

  const handleShowJSONDataModal = () => {
    setShowJSONDataModal(!showJSONDataModal);
  };

  const handleCheckDriveStatus = () => {
    if (shouldCheckDriveStatus === true) {
      refetchDriveStatusData();
    } else {
      setShouldCheckDriveStatus(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      const tooltipNode = ReactDOM.findDOMNode(tooltipRef.current);
      const isClickedOutside = tooltipNode && !tooltipNode.contains(target);
      const isClickedInsideContent = (target as Element)?.closest('.ant-tooltip-inner');

      if (!isClickedInsideContent && isClickedOutside) {
        setIsClicked(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isClicked]);

  useEffect(() => {
    localStorage.setItem('userId', adminUserId);
  }, []);

  return (
    <div style={{ marginTop: '16px' }}>
      {/*DEACTIVATE ACCOUNT MODAL*/}
      <UserStatusModal
        showModal={showDeactivateModal}
        handleShowModal={() => handleShowDeactivateModal(record)}
        title="Deactivate Account"
        user={record}
        refetch={refetchSearchUsers}
      />

      {/*PROFILE MODAL*/}
      {userData && showProfileModal && (
        <ProfileModalUsers
          showModal={showProfileModal}
          handleShowModal={handleShowProfileModal}
          userData={userData}
          refetchUserData={refetchSearchUsers}
        />
      )}

      {/*PERMISSIONS MODAL*/}
      {userData && showPermissionsModal && (
        <UserPermissionsModal
          showModal={showPermissionsModal}
          handleShowModal={handleShowPermissionsModal}
          userData={userData}
          refetchUserData={refetchSearchUsers}
        />
      )}

      {/*JSON DETAILS MODAL*/}
      {userData && showJSONDataModal && (
        <JSONDetailsModal
          showModal={showJSONDataModal}
          handleShowModal={handleShowJSONDataModal}
          jsonDetails={userData}
          title="All User Data"
        />
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        {/*NAME*/}
        <div
          style={{
            width: '14%',
            display: 'flex',
            alignItems: 'center',
            marginRight: '1%',
          }}
          className="flags-table-row-text"
        >
          <span>{userData?.fullName ?? '-'}</span>
        </div>

        {/*MAIL*/}
        <div
          style={{
            width: '24%',
            display: 'flex',
            alignItems: 'center',
            marginRight: '1%',
          }}
          className="flags-table-row-text"
        >
          <span>{userData?.email ?? '-'}</span>
        </div>

        {/*ROLE*/}
        <div
          style={{
            width: '10%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginRight: '4%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
            className="flags-table-row-text"
          >
            {userData?.roles?.map((role: any, index: number, array: any[]) => (
              <span>
                {Object.values(ROLES)[role]}
                {index !== array.length - 1 && ',\u00A0'}
              </span>
            ))}
          </div>
          <span
            onClick={() => setShowPermissionsModal(!showPermissionsModal)}
            style={{ cursor: 'pointer' }}
          >
            <img style={{ minWidth: '10px', maxWidth: '10px' }} src={EditIcon} />
          </span>
        </div>

        {/*CREATION DATE*/}
        <div style={{ width: '17%', marginRight: '1%' }} className="flags-table-row-text">
          <span>{moment(userData?.createdAt).format('DD.MM.YYYY') ?? '-'}</span>
        </div>

        {/*ACTIONS*/}
        <div
          style={{
            width: '28%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            onClick={() => {
              if (userData?.roles.includes(ADMIN_ROLE)) {
                toast.error('Cannot impersonate admin');
                return;
              }
              localStorage.setItem(
                'menuType',
                userData?.roles.includes(MENTOR_ROLE)
                  ? MENTOR_ROLE.toString()
                  : STUDENT_ROLE.toString()
              );
              localStorage.setItem(
                'userRole',
                userData?.roles.includes(MENTOR_ROLE) ? 'mentor' : 'student'
              );
              setPossessedUserId(userData?._id);
            }}
            style={{
              display: 'flex',
              marginRight: '12px',
              cursor: 'pointer',
              alignItems: 'center',
            }}
            className="users-action"
          >
            <img
              style={{ marginRight: '8px', minWidth: '14px', maxWidth: '14px' }}
              className="external-link-icon"
              src={ImpersonateIcon}
              alt="enrollments"
            />
            <img
              style={{ marginRight: '8px', minWidth: '14px', maxWidth: '14px' }}
              className="external-link-icon-primary"
              src={ImpersonateIconPrimary}
              alt="enrollments"
            />
            <span className="users-table-access-button">Impersonate</span>
          </div>
          <div
            style={{
              display: 'flex',
              marginRight: '12px',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => {
                if (userData.isDezactivated) {
                  deactivateUser({
                    userId: userData._id,
                    deactivate: !userData.isDezactivated,
                  });
                } else {
                  handleShowDeactivateModal(userData);
                }
              }}
              style={{
                display: 'flex',
                marginRight: '12px',
                cursor: 'pointer',
                textDecorationColor: COLORS.ERROR,
              }}
              className="users-action"
            >
              <img
                style={{
                  display: 'flex',
                  marginRight: '8px',
                  minWidth: '14px',
                  maxWidth: '14px',
                }}
                src={DeactivateAccountIcon}
                alt="enrollments"
              />
              <span
                style={{
                  color: COLORS.ERROR,
                  textDecorationColor: COLORS.ERROR,
                }}
                className="users-table-access-button"
              >
                {userData?.isDezactivated ? 'Activate Account' : 'Deactivate Account'}
              </span>
            </div>
            {!!userData?.isDezactivated && (
              <Tooltip
                align={{ offset: [0, 8] }}
                title={`Reason: ${userData?.deactivationReason ?? 'N/A'}`}
              >
                <ExclamationCircleIcon className="exclamation-circle-icon" />
              </Tooltip>
            )}
          </div>
          <div ref={tooltipRef} style={{ display: 'flex', marginRight: '12px' }}>
            <Tooltip
              zIndex={9999}
              color="transparent"
              placement="bottomLeft"
              trigger="click"
              visible={isClicked}
              title={
                <div
                  style={{
                    padding: '8px 16px',
                    color: COLORS.TEXT_PRIMARY,
                    minWidth: '168px',
                  }}
                  className="tooltip-container"
                >
                  <span
                    onClick={() => setShowProfileModal(!showProfileModal)}
                    className="tooltip-button"
                  >
                    View user properties
                  </span>
                  <span
                    onClick={() => {
                      sendActivationEmail({ id: userId });
                    }}
                    className="tooltip-button"
                  >
                    Resend activation link
                  </span>
                  <span
                    onClick={() => {
                      if (!isTestLoading) {
                        changeUserTestState({
                          userId: userId,
                          isTestUser: !userData.isTestUser,
                        });
                      }
                    }}
                    className="tooltip-button"
                  >
                    {userData?.isTestUser ? 'Revert Test User' : 'Make test user'}
                  </span>
                  <span
                    onClick={() => setShowJSONDataModal(!showJSONDataModal)}
                    className="tooltip-button"
                  >
                    Show all data
                  </span>
                  {driveStatus === 'Already created' ? (
                    <span className="tooltip-text">{driveStatus}</span>
                  ) : isLoadingDriveStatusData ? (
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <span style={{ marginRight: '4px', marginTop: '2px' }}>
                        <AgoraSpinner fontSize={13} />{' '}
                      </span>
                      <span className="tooltip-text">Verifying...</span>
                    </span>
                  ) : driveLink ? (
                    <span
                      onClick={() => {
                        handleCheckDriveStatus();
                      }}
                      className="tooltip-button"
                    >
                      Verify Drive folder
                    </span>
                  ) : (driveStatusData as DriveStatus)?.status === 0 ||
                    (driveStatusData as DriveStatus)?.status === 2 ? (
                    <span
                      onClick={() => {
                        if (!isLoadingCreateUserDrive) {
                          createUserDrive({
                            email: userData.email,
                            role: userData.roles.includes(MENTOR_ROLE) ? 'mentor' : 'student',
                          });
                        }
                      }}
                      className="tooltip-button"
                    >
                      Create Drive folder
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        handleCheckDriveStatus();
                      }}
                      className="tooltip-button"
                    >
                      Verify Drive folder
                    </span>
                  )}

                  {/*{((driveStatusData as DriveStatus)?.status === 0 ||*/}
                  {/*  (driveStatusData as DriveStatus)?.status === 2 ||*/}
                  {/*  !!driveLink) && (*/}
                  {/*  <span*/}
                  {/*    onClick={() => {*/}
                  {/*      if (!isLoadingCreateStudentDrive) {*/}
                  {/*        createStudentDrive({*/}
                  {/*          email: userData.email,*/}
                  {/*        });*/}
                  {/*      }*/}
                  {/*    }}*/}
                  {/*    className="tooltip-button"*/}
                  {/*  >*/}
                  {/*    Create Drive folder*/}
                  {/*  </span>*/}
                  {/*)}*/}
                </div>
              }
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={handleArrowClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {isClicked || isHovered ? (
                  <MoreOutlined className="next-meeting-arrow" style={{ color: COLORS.PRIMARY }} />
                ) : (
                  <MoreOutlined
                    className="next-meeting-arrow"
                    style={{ color: COLORS.TEXT_PRIMARY }}
                  />
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>

      {/*DIVIDERS*/}
      <div style={{ borderTopWidth: '1px' }} className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>
    </div>
  );
};

export default UsersTableRow;
