import { HOTJAR_ID } from '@shared/frontendEnv';
import { useAuthState } from '@contexts/AuthContext';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

const useHotjar = () => {
  const { userId, isMentor, isAdmin } = useAuthState();

  useEffect(() => {
    if (HOTJAR_ID) {
      try {
        hotjar.initialize({ id: HOTJAR_ID, sv: 6 });
      } catch (error) {
        console.error('Failed to initialize Hotjar:', error);
      }
    }
  }, []);

  useEffect(() => {
    if (!userId) return;

    if (hotjar.initialized()) {
      hotjar.identify(userId, {
        role: isAdmin ? 'Admin' : isMentor ? 'Mentor' : 'Student',
      });
    }
  }, [userId]);

  return;
};

export default useHotjar;
