import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import CoursesCanTeachSection from './CoursesCanTeachSection';
import OthersCoursesSection from './OthersCoursesSection';
import SubjectsCanTeachSection from './SubjectsCanTeachSection';
import YourCoursesSection from './YourCoursesSection';

interface CoursesTabProps {
  userProfile?: Partial<UserProfileDTO>;
  isMentorProfile: boolean;
}

const CoursesTab = (props: CoursesTabProps) => {
  const { userProfile, isMentorProfile } = props;

  return (
    <section className="flex flex-col gap-4">
      {isMentorProfile ? (
        <>
          <CoursesCanTeachSection userId={userProfile?._id} />
          <SubjectsCanTeachSection majors={userProfile?.mentorData?.majors} />
        </>
      ) : (
        <>
          <YourCoursesSection userProfile={userProfile} />
          <OthersCoursesSection userProfile={userProfile} />
        </>
      )}
    </section>
  );
};

export default CoursesTab;
