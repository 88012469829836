import { MEETINGS_PAGE_LIMIT } from '@shared/constants';
import {
  genericMutation,
  genericMutationWithId,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';
import { enrollmentsApis, meetingsApis } from '@shared/apis';

export const useRescheduleMeeting = genericMutationWithId<meetingsApis.RescheduleMeeting>(
  meetingsApis.rescheduleMeeting,
  meetingsApis.searchMyMeetings.endpoint,
  meetingsApis.getMentorCalendarMeetings.endpoint,
  enrollmentsApis.searchSessionHistory.endpoint
);

export const useMyMeetings = genericQueryWithParams<meetingsApis.SearchMyMeetings>(
  meetingsApis.searchMyMeetings
);

export const useMentorStudentMeetings =
  genericQueryWithParams<meetingsApis.GetMentorStudentMeetings>(
    meetingsApis.getMentorStudentMeetings
  );

export const useFeedbackMeetings = (page?: number) => {
  return useMyMeetings(
    {
      page,
      status: 'feedback',
      limit: MEETINGS_PAGE_LIMIT,
    },
    { refetchInterval: 5000 }
  );
};

export const useUpcomingMeetings = (page?: number) => {
  return useMyMeetings(
    { page, status: 'upcoming', limit: MEETINGS_PAGE_LIMIT },
    { refetchInterval: 5000 }
  );
};

export const useOngoingMeetings = () => {
  return useMyMeetings(
    {
      status: 'started',
    },
    { refetchInterval: 5000 }
  );
};

export const useMeeting = genericQueryWithId<meetingsApis.GetMeeting>(meetingsApis.getMeeting);

export const useGetAIGeneratedFeedback = genericQueryWithId<meetingsApis.GetAIGeneratedFeedback>(
  meetingsApis.getAIGeneratedFeedback
);

export const useRegisterMentorFeedback = genericMutationWithId<meetingsApis.RegisterMentorFeedback>(
  meetingsApis.registerMentorFeedback,
  meetingsApis.searchMyMeetings.endpoint,
  enrollmentsApis.searchSessionHistory.endpoint,
  meetingsApis.noOfCompletedSessionsCurrentMonth.endpoint
);

export const useRegisterStudentFeedback =
  genericMutationWithId<meetingsApis.RegisterStudentFeedback>(
    meetingsApis.registerStudentFeedback,
    meetingsApis.searchMyMeetings.endpoint,
    enrollmentsApis.searchSessionHistory.endpoint
  );

export const useUpdateMeeting = genericMutationWithId<meetingsApis.UpdateMeeting>(
  meetingsApis.updateMeeting,
  meetingsApis.getMeeting.endpoint,
  meetingsApis.getMentorCalendarMeetings.endpoint,
  enrollmentsApis.searchSessionHistory.endpoint
);

export const useCreateMeeting = genericMutation<meetingsApis.CreateMeeting>(
  meetingsApis.createMeeting,
  enrollmentsApis.getSessionDetails.endpoint,
  enrollmentsApis.searchMentorEnrollments.endpoint,
  meetingsApis.getMentorCalendarMeetings.endpoint
);

export const useMentorSessionNo =
  genericQueryWithId<meetingsApis.NoOfCompletedSessionsCurrentMonth>(
    meetingsApis.noOfCompletedSessionsCurrentMonth
  );

export const useGetMeetingNotes = genericQueryWithId<meetingsApis.GetMeetingNotes>(
  meetingsApis.getMeetingNotes
);

export const useUpdateMeetingNotes = genericMutationWithId<meetingsApis.UpdateMeetingNotes>(
  meetingsApis.updateMeetingNotes,
  meetingsApis.getMeetingNotes.endpoint,
  enrollmentsApis.getSessionDetails.endpoint
);

export const useGetMentorCalendarMeetings =
  genericQueryWithParams<meetingsApis.GetMentorCalendarMeetings>(
    meetingsApis.getMentorCalendarMeetings
  );
