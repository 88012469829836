import { BasicProps } from 'apps/agora/src/utils/types';
import IconButton from './IconButton';
import { ReactComponent as ArrowIcon } from '@assets/icons/V4/keyboard-arrow.svg';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useEffect } from 'react';

interface DotPaginationProps extends BasicProps {
  isDisabled?: boolean;
  isVisible?: boolean;
  itemCount?: number;
  page: number;
  noOfPages: number;
  onSetPage: (page: number) => void;
}

const DotPagination = (props: DotPaginationProps) => {
  const {
    page,
    noOfPages,
    className,
    isDisabled = false,
    isVisible = true,
    itemCount,
    onSetPage,
  } = props;

  useEffect(() => {
    if (itemCount === 0 && page > 1) {
      onSetPage(page - 1);
    }
  }, [itemCount, page]);

  return (
    <div
      className={mergeClassNames(
        'flex w-full items-center justify-between',
        { hidden: !isVisible },
        className
      )}
    >
      <IconButton
        isDisabled={page === 1 || isDisabled}
        onClick={() => onSetPage(page - 1)}
        icon={<ArrowIcon className="rotate-180" />}
      />
      <div className="flex max-w-[calc(100%-60px)] flex-wrap justify-center">
        {[...Array(noOfPages).keys()].map((_, index) => (
          <button
            disabled={isDisabled}
            onClick={() => onSetPage(index + 1)}
            key={index}
            className="group flex items-center justify-center w-4 h-4 rounded-full"
          >
            <div
              className={mergeClassNames(
                'group-hover:bg-white w-1.5 h-1.5 bg-customGrey rounded-full',
                {
                  'bg-white': page && index === page - 1,
                  'bg-disabled': isDisabled,
                }
              )}
            />
          </button>
        ))}
      </div>
      <IconButton
        isDisabled={page === noOfPages || isDisabled}
        onClick={() => onSetPage(page + 1)}
        icon={<ArrowIcon />}
      />
    </div>
  );
};

export default DotPagination;
