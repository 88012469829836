import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import { useState } from 'react';
import ProfileMainSection from '../ResumeSection';
import CapacityTabForm from './CapacityTabForm';
import CapacityTabItem from './CapacityTabItem';

interface CapacityTabProps {
  userId: string;
  userProfile?: Partial<UserProfileDTO>;
}

const CapacityTab = (props: CapacityTabProps) => {
  const { userId, userProfile } = props;
  const [isEditMode, setIsEditMode] = useState(false);

  const currentNoOfEnrollments =
    userProfile?.enrollments?.filter(
      (enrollment) => enrollment.user?._id && enrollment.user._id !== userId && !enrollment.ended
    )?.length ?? 0;

  return (
    <section className="flex flex-col gap-4">
      <ProfileMainSection
        title={'Capacity'}
        onClick={() => setIsEditMode((prev) => !prev)}
        isButtonActive={isEditMode}
        isEditMode
      >
        {isEditMode && (
          <CapacityTabForm
            isModeAddNew={isEditMode}
            setIsEditMode={setIsEditMode}
            userId={userId}
            maxEnrollments={userProfile?.mentorData?.mentorCapacity?.capacity ?? 0}
            currentNoEnrollments={currentNoOfEnrollments}
            onCancel={() => setIsEditMode(false)}
          />
        )}
        {!isEditMode && (
          <CapacityTabItem
            mentorData={userProfile?.mentorData}
            currentNoOfEnrollments={currentNoOfEnrollments}
          />
        )}
      </ProfileMainSection>
    </section>
  );
};

export default CapacityTab;
