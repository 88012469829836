import FinishFlagIcon from '@assets/icons/Icon-awesome-flag-checkered.svg';
import ExternalLinkIcon from '@assets/icons/external-link.svg';
import Table from '@components/V4/Table/Table';
import { useCloseMultipleFlags } from '@actions';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import { Flag, FlagTypes, ModelFlagTypes } from '@shared/common';

type Props = {
  key?: string | number;
  flagData?: any;
  refetchSearchFlags: () => void;
  currentUserId: string;
  handleAddFlag: (flag: string) => void;
  handleRemoveFlag: (flag: string) => void;
  showMoreButtonHandler: (modalType: ModelFlagTypes, flagType: FlagTypes, ref: Flag['ref']) => void;
};

const FlagsTableRow = ({
  key,
  flagData,
  refetchSearchFlags,
  currentUserId,
  handleAddFlag,
  handleRemoveFlag,
  showMoreButtonHandler,
}: Props) => {
  const { mutate: markAsClosed } = useCloseMultipleFlags({
    onSuccess: () => {
      refetchSearchFlags();
    },
  });

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const flag = event.target.value;
    if (event.target.checked) {
      handleAddFlag(flag);
    } else {
      handleRemoveFlag(flag);
    }
  };

  return (
    <Table.Row>
      <Table.Cell className="flex items-center gap-2">
        {!flagData?.closedAt && (
          <input
            onChange={handleCheckboxChange}
            value={flagData?._id}
            id="flags"
            aria-describedby="comments-description"
            name="flags"
            type="checkbox"
            className="h-4 w-4 rounded border-customGrey text-customPrimary focus:ring-customPrimary bg-transparent cursor-pointer"
          />
        )}
        <span>{flagData?.description ?? '-'}</span>
      </Table.Cell>
      <Table.Cell>
        {flagData?.ref?.students?.[0]?.fullName ?? flagData?.ref?.user?.fullName ?? '-'}
      </Table.Cell>
      <Table.Cell>{flagData?.ref?.mentor?.fullName ?? 'TBD'}</Table.Cell>
      <Table.Cell>{flagData?.ref?.course?.title ?? '-'}</Table.Cell>
      <Table.Cell>
        {flagData?.createdAt ? moment(flagData?.createdAt).format('DD.MM.YYYY') : '-'}
      </Table.Cell>
      <Table.Cell className="flex gap-4">
        <div
          onClick={() =>
            showMoreButtonHandler(flagData?.modelType, flagData?.flagType, flagData?.ref)
          }
          className="flex gap-1 cursor-pointer"
        >
          <img className="w-3.5" src={ExternalLinkIcon} alt="enrollments" />
          <span className="min-w-max text-white">View More</span>
        </div>
        {!flagData?.closedAt && (
          <div
            onClick={() => {
              markAsClosed({
                flagIds: [flagData?._id],
                closedBy: currentUserId,
              });
            }}
            className="flex cursor-pointer gap-1 "
          >
            <img className="files-icon" src={FinishFlagIcon} alt="flag" />
            <span className="min-w-max text-customRedAccent">Mark as closed</span>
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default FlagsTableRow;
