import { ReactComponent as BinIcon } from '@assets/icons/bin-scaled-icon.svg';
import { ReactComponent as SubjectsCanTeachIcon } from '@assets/icons/profile-icons/subjects-can-teach-icon.svg';
import IconButton from '@components/V4/IconButton';
import { useUpdateMyMentorData } from '@actions';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import ProfileMainSection from '../ResumeSection';
import SubjectsCanTeachForm from './SubjectsCanTeachForm';
import SkeletonBox from '@components/V4/SkeletonBox';
import { ProfileContext } from '../../AccountProfile';

interface SubjectsCanTeachSectionProps {
  majors?: string[];
  userId?: string;
}

const SubjectsCanTeachSection = (props: SubjectsCanTeachSectionProps) => {
  const { majors, userId } = props;

  const [isAddNewItem, setIsAddNewItem] = useState(false);

  const { trackEvent } = useAnalytics();
  const { isLoading: isProfileLoading } = useContext(ProfileContext);

  const toast = useToast();
  const query = useQueryClient();

  const { mutate: updateMyMentorData, isLoading } = useUpdateMyMentorData({
    onSuccess: async () => {
      trackEvent(ANALYTICS_EVENT_NAMES.PROFILE_SUBJECTS_CAN_TEACH_CHANGE);

      toast.success('Successfully updated your profile.');
      await query.invalidateQueries('/users/:id/profile');
      setIsAddNewItem(false);
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const deleteEntryHandler = (entry: string) => {
    if (majors && !isLoading) {
      const newArr = majors.filter((major) => major !== entry);
      updateMyMentorData({
        majors: newArr,
      });
    }
  };

  return (
    <ProfileMainSection
      title={'Subjects Can Teach'}
      onClick={() => setIsAddNewItem((prev) => !prev)}
      isButtonActive={isAddNewItem}
    >
      {isAddNewItem && (
        <SubjectsCanTeachForm
          isModeAddNew={true}
          userId={userId ?? ''}
          onCancel={() => setIsAddNewItem(false)}
          setIsAddNewItem={setIsAddNewItem}
          majors={majors}
          updateMyMentorData={updateMyMentorData}
          isLoading={isLoading}
        />
      )}
      {!isAddNewItem && (
        <div className="flex flex-col gap-2">
          {isProfileLoading
            ? [...Array(3).keys()].map((_, index) => (
                <SkeletonBox key={index} className="h-16 w-full rounded-lg" />
              ))
            : majors?.map((major) => (
                <div key={major} className="flex items-center justify-between py-2 px-4">
                  <div className="flex items-center gap-4 ">
                    <SubjectsCanTeachIcon className="text-customPrimary" />
                    <p className="text-base text-white font-raleway font-bold">{major}</p>
                  </div>
                  <IconButton
                    onClick={() => deleteEntryHandler(major)}
                    variant="ghost"
                    color="danger"
                    disabled={isLoading}
                    className="tablet:mr-4"
                    icon={<BinIcon className="h-4 w-4" />}
                  />
                </div>
              ))}
        </div>
      )}
    </ProfileMainSection>
  );
};

export default SubjectsCanTeachSection;
