import 'tailwindcss/tailwind.css';
import { useCheckParentPendingNPS, useReportParentIssue, useStudentData } from '@actions';
import parentsStyleV3 from '@styles/v3/parents-nps.module.scss';
import { Skeleton, Divider, Tooltip, message } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import UELogo from '../../../assets/icons/ue-logo.svg';
import backgroundImage from '../../../assets/referral/referral-background-web.svg';
import StudentCard from '../../../components/V3/StudentCard';
import StudentFeedbackCard from '@components/V3/StudentFeedbackCard';
import QuestionsCard from './QuestionsCard';
import { Disclosure } from '@headlessui/react';
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/solid';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import './parent-view.styles.scss';
import NeedHelpParentBanner from '@components/V3/NeedHelpParentBanner';
import MonthlyNPSModal from '@modules/Base/PendingNPS/MonthlyNPSModal';

const ParentView = () => {
  const { studentId } = useParams<ParentViewParams>();
  const firstUpgradeNPS = Math.random() < 0.5;

  const { data, error, isLoading } = useStudentData({ userId: studentId });
  const { data: needsNPS } = useCheckParentPendingNPS(studentId);

  const [selectedFeedback, setSelectedFeedback] = useState(0);
  const [selectedTab, setSelectedTab] = useState('All');
  const [selectedData, setSelectedData] = useState<any>([]);
  const [showBanner, setShowBanner] = useState(true);

  const handleShowBanner = (showBanner: boolean) => {
    setShowBanner(showBanner);
  };

  const next = () => {
    if (selectedData && selectedData.length && selectedFeedback < selectedData.length - 1) {
      setSelectedFeedback(selectedFeedback + 1);
    }
  };

  const previous = () => {
    if (selectedFeedback > 0) {
      setSelectedFeedback(selectedFeedback - 1);
    }
  };

  const handleSelectChange = (event: any) => {
    const selectedOption = event.target.value;
    if (selectedOption === 'All') {
      setSelectedTab('All');
      setSelectedFeedback(0);
    } else {
      const selectedTab = data?.enrollments.find((tab) => tab?.course?.title === selectedOption);
      setSelectedTab(selectedOption);
      setSelectedFeedback(0);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      if (selectedTab === 'All') {
        setSelectedData(data?.mentorFeedback);
      } else {
        setSelectedData(data?.mentorFeedback?.filter((item) => item?.course === selectedTab));
      }
    }
  }, [selectedTab, isLoading]);

  if (error) {
    return (
      <div style={ParentViewDivStyle}>
        <h1>Something didn't work</h1>
        <p>Make sure that you're using the link you received via email.</p>
        <p>Error: {(error as any).message}</p>
        <QuestionsCard />
      </div>
    );
  }

  if (!data?.student || !data?.enrollments.length) {
    return (
      <div style={ParentViewDivStyle}>
        <Skeleton active />
      </div>
    );
  }

  const firstName = data.student?.fullName.split(' ')[0];

  const tabs = [
    { name: 'All', current: true },
    { name: 'Example', current: false },
  ];

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className={parentsStyleV3.containerStyle}>
      <div
        style={{ backgroundImage: `url(${backgroundImage})` }}
        className={parentsStyleV3.backgroundImageStyle}
      ></div>
      <div className={parentsStyleV3.contentStyle}>
        <div className={parentsStyleV3.contentAvatarContainer}>
          <img className={parentsStyleV3.ueLogo} src={UELogo} />
          <div>
            <div>
              <StudentCard fullName={data?.student?.fullName} enrollments={data?.enrollments} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '32px',
                }}
              >
                {/*NEED HELP BANNER*/}
                {showBanner && (
                  <NeedHelpParentBanner studentId={studentId} handleShowBanner={handleShowBanner} />
                )}

                {/*{showBanner && (*/}
                {/*  <div*/}
                {/*    className="banner-container"*/}
                {/*    style={{ backgroundColor: COLORS.PRIMARY }}*/}
                {/*  >*/}
                {/*    <div className="banner-image-text-container">*/}
                {/*      <div className="banner-image-container">*/}
                {/*        <img*/}
                {/*          className="banner-image"*/}
                {/*          src={BannerImage}*/}
                {/*          alt="banner-image"*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*      <div className="banner-text-container">*/}
                {/*        <div>*/}
                {/*          <p*/}
                {/*            style={{ color: COLORS.BLACK }}*/}
                {/*            className="banner-title"*/}
                {/*          >*/}
                {/*            Got a question or slight concern? Don't hesitate to*/}
                {/*            reach out.*/}
                {/*          </p>*/}
                {/*          <p*/}
                {/*            style={{ color: COLORS.BLACK }}*/}
                {/*            className="banner-description"*/}
                {/*          >*/}
                {/*            We’re committed to ensuring your experience with us*/}
                {/*            is nothing short of excellent. Let us help with even*/}
                {/*            the smallest things.*/}
                {/*          </p>*/}
                {/*        </div>*/}
                {/*        <div className="get-in-touch-button-container">*/}
                {/*          <PrimaryButton*/}
                {/*            onClick={() => registerIssue({})}*/}
                {/*            margin="0px"*/}
                {/*            width={windowWidth <= 999 ? '100%' : 'auto'}*/}
                {/*          >*/}
                {/*            Get in touch*/}
                {/*          </PrimaryButton>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*      onClick={() => setShowBanner(false)}*/}
                {/*      className="close-button-container"*/}
                {/*    >*/}
                {/*      <img*/}
                {/*        style={{*/}
                {/*          minWidth: '12px',*/}
                {/*          maxWidth: '12px',*/}
                {/*          minHeight: '12px',*/}
                {/*          maxHeight: '12px',*/}
                {/*        }}*/}
                {/*        src={XButton}*/}
                {/*        alt="close"*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
            </div>
            <div className={parentsStyleV3.contentContainer}>
              {needsNPS && (
                <MonthlyNPSModal
                  visible={true}
                  firstUpgradeNPS={firstUpgradeNPS}
                  parentView={true}
                />
              )}
              <h1 className={parentsStyleV3.title}>All Latest Feedbacks</h1>

              <Divider style={{ borderTop: '2px solid #B0B0B0' }} />

              {/*TABS*/}

              <div>
                <div className="sm:hidden">
                  <select
                    id="tabs"
                    name="tabs"
                    style={{
                      color: '#36B3A8',
                      borderColor: '#36B3A8',
                      backgroundColor: 'black',
                    }}
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    onChange={handleSelectChange}
                  >
                    <option value="All">All</option>
                    {data?.enrollments
                      ?.filter((tab) => tab?.mentor !== null)
                      .map((tab) => (
                        <option key={tab?.course?._id} value={tab?.course?.title}>
                          {tab?.course?.title}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="hidden sm:block">
                  <nav
                    style={{ overflowX: 'auto', paddingBottom: '24px' }}
                    className="flex space-x-4"
                    aria-label="Tabs"
                  >
                    <span
                      onClick={() => {
                        setSelectedTab('All');
                        setSelectedFeedback(0);
                      }}
                      // key={tab?.course?._id}
                      style={{
                        cursor: 'pointer',
                        color: selectedTab === 'All' ? '#36B3A8' : '#fff',
                        background:
                          selectedTab === 'All' ? 'rgba(54, 179, 168, 0.33)' : 'transparent',
                        borderRadius: '32px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        // opacity: tab.current ? '0' : '0.33',
                      }}
                      className={classNames(
                        selectedTab === 'All'
                          ? 'bg-indigo-100 text-indigo-700'
                          : 'text-gray-500 hover:text-gray-700',
                        'rounded-md px-3 py-2 text-sm font-medium'
                      )}
                      aria-current={selectedTab === 'All' ? 'page' : undefined}
                    >
                      All
                    </span>

                    {data?.enrollments
                      ?.filter((tab) => tab?.mentor !== null)
                      ?.map((tab) => (
                        <span
                          onClick={() => {
                            setSelectedTab(tab?.course?.title);
                            setSelectedFeedback(0);
                          }}
                          key={tab?.course?._id}
                          style={{
                            // minWidth: '120px',
                            cursor: 'pointer',
                            color: selectedTab === tab?.course?.title ? '#36B3A8' : '#fff',
                            background:
                              selectedTab === tab?.course?.title
                                ? 'rgba(54, 179, 168, 0.33)'
                                : 'transparent',
                            whiteSpace: 'nowrap',
                            borderRadius: '32px',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                          }}
                          className={classNames(
                            selectedTab === tab?.course?.title
                              ? 'bg-indigo-100 text-indigo-700'
                              : 'text-gray-500 hover:text-gray-700',
                            'rounded-md px-3 py-2 text-sm font-medium'
                          )}
                          aria-current={selectedTab === tab?.course?.title ? 'page' : undefined}
                        >
                          {tab?.course?.title}
                        </span>
                      ))}
                  </nav>
                </div>
              </div>

              <div style={{ marginTop: '20px', marginBottom: '32px' }}>
                <div
                  style={{ backgroundColor: '#161616' }}
                  className="overflow-hidden rounded-lg shadow"
                >
                  <div className="px-4 py-5 sm:p-6">
                    {
                      <StudentFeedbackCard
                        mentorFeedback={selectedData ? selectedData[selectedFeedback] : {}}
                        selectedTab={selectedTab}
                      />
                    }

                    <nav
                      style={{ marginTop: '20px', marginBottom: '32px' }}
                      className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0"
                    >
                      <div className="-mt-px flex w-0 flex-1">
                        <a
                          onClick={() => previous()}
                          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        >
                          <ArrowLeftIcon
                            className="mr-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          Previous
                        </a>
                      </div>
                      <div
                        style={{
                          overflowX: 'scroll',
                          width: '70%',
                          paddingBottom: '16px',
                        }}
                        className="hidden md:-mt-px md:flex"
                      >
                        {selectedData?.map((item: any, index: number) => {
                          return (
                            // (item?.course === selectedTab ||
                            //   selectedTab === 'All') && (
                            <a
                              onClick={() => setSelectedFeedback(index)}
                              style={{
                                color: selectedFeedback === index ? '#36B3A8' : '',

                                borderColor: selectedFeedback === index ? '#36B3A8' : '',
                              }}
                              className={
                                selectedFeedback === index
                                  ? 'inline-flex items-center border-t-2 border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600'
                                  : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
                              }
                            >
                              {/*&#8226;*/}
                              {index + 1}
                            </a>
                          );
                        })}
                      </div>
                      <div className="-mt-px flex w-0 flex-1 justify-end">
                        <a
                          onClick={() => next()}
                          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        >
                          Next
                          <ArrowRightIcon
                            className="ml-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </a>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>

              <h1 className={parentsStyleV3.title}>Courses</h1>

              <Divider style={{ borderTop: '2px solid #B0B0B0' }} />

              <div
                style={{ backgroundColor: '#161616' }}
                className="overflow-hidden rounded-lg shadow"
              >
                <div className="px-4 py-5 sm:p-6">
                  <div>
                    <div className="">
                      <div className="">
                        <dl className="">
                          {data.enrollments
                            ?.filter((tab) => tab?.mentor !== null)
                            ?.map((enrollment) => (
                              <Disclosure as="div" key={enrollment?._id}>
                                {({ open }: { open: boolean }) => (
                                  <>
                                    <dt>
                                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                                        <span className="text-base font-normal leading-7">
                                          {enrollment.course?.title}
                                        </span>
                                        <span className="ml-6 flex h-7 items-center">
                                          {open ? (
                                            <MinusSmIcon className="h-6 w-6" aria-hidden="true" />
                                          ) : (
                                            <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
                                          )}
                                        </span>
                                      </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                      <p className="text-base leading-7 text-gray-300">
                                        {enrollment.mentor?.fullName}
                                      </p>
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            ))}
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h1 style={{ marginTop: '24px' }} className={parentsStyleV3.title}>
                Questions?
              </h1>
              <Divider style={{ borderTop: '2px solid #B0B0B0' }} />

              <QuestionsCard />

              <h1 style={{ marginTop: '24px' }} className={parentsStyleV3.title}>
                Refer Someone
              </h1>
              <Divider style={{ borderTop: '2px solid #B0B0B0' }} />

              <div style={{ marginBottom: '120px' }}>
                <h1 style={{ marginBottom: '24px' }} className={parentsStyleV3.referralTitle}>
                  Earn <span style={{ color: '#D7B037' }}>€200</span> and other vouchers and
                  services
                </h1>

                <p className={parentsStyleV3.referralDescription}>
                  <span style={{ color: '#D7B037' }}>Invite your friends to Upgrade</span> and for
                  each qualified referral you will get back a €200 eMAG voucher, an application
                  review package or a 50% discount on purchasing another Upgrade Education course
                </p>

                <a href="https://agora.upgrade.education/referral" target="_blank" rel="noreferrer">
                  <button className={parentsStyleV3.button}>Refer a friend</button>
                </a>
              </div>
            </div>
          </div>

          <div style={{ marginLeft: '88px', width: '126px' }}></div>
        </div>
      </div>
    </div>
  );
};

type ParentViewParams = {
  studentId: string;
};

const ParentViewDivStyle = {
  maxWidth: '800px',
  margin: '20px auto',
};

export default ParentView;
