import { PlusOutlined, DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import apiClient from '@contexts/AuthContext/apiClient';
import { Button, Input } from 'antd';
import { useState, useEffect } from 'react';

const { TextArea } = Input;

const MainTakeawaysEditor = ({ lessonId, courseId }: any) => {
  const [takeaways, setTakeaways] = useState<any>([]);

  useEffect(() => {
    if (lessonId && lessonId !== 'n' && courseId) getFromServer();
  }, []);

  if (!lessonId || lessonId === 'n' || !courseId) {
    return (
      <div>
        <h2>The main takeaways editor will appear here once the lesson is published.</h2>
        <p>Use the "publish" button in the top right then return to this page.</p>
      </div>
    );
  }

  const addTakeaway = (question = '', answer = '') => {
    const newTakeaways: any = [...takeaways];
    newTakeaways.push({
      index: takeaways.length,
      question,
      answer,
    });
    setTakeaways(newTakeaways);
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 10);
  };

  const removeLastTakeaway = () => {
    const newTakeaways = [...takeaways];
    newTakeaways.pop();
    setTakeaways(newTakeaways);
  };

  const changeTakeaway = (index: any, question: any, answer: any) => {
    const newTakeaways: any = [...takeaways];
    if (question !== null) newTakeaways[index].question = question;
    if (answer !== null) newTakeaways[index].answer = answer;
    setTakeaways(newTakeaways);
  };

  const postToServer = () => {
    apiClient
      .post(`/takeaways/set/${courseId}/${lessonId}`, { takeaways })
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getFromServer = () => {
    let i = 0;
    const newTakeaways: any = [];
    apiClient
      .get(`/takeaways/view/${lessonId}`)
      .then((response) => {
        response.data.takeaways.map((takeaway: any) => {
          return newTakeaways.push({
            index: i++,
            question: takeaway.question,
            answer: takeaway.answer,
          });
        });
        setTakeaways(newTakeaways);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      {takeaways.map((takeaway: any) => (
        <div
          key={takeaway.index}
          style={{
            border: '1px solid grey',
            marginBottom: 8,
            padding: 5,
            paddingBottom: 20,
          }}
        >
          <h3>Takeaway {takeaway.index}</h3>
          <p>Question:</p>
          <Input
            onChange={(e) => changeTakeaway(takeaway.index, e.target.value, null)}
            value={takeaways[takeaway.index].question}
          />
          <p>Answer:</p>
          <TextArea
            showCount
            maxLength={500}
            onChange={(e) => changeTakeaway(takeaway.index, null, e.target.value)}
            value={takeaways[takeaway.index].answer}
          />
        </div>
      ))}

      <br />
      <Button type="primary" icon={<PlusOutlined />} onClick={() => addTakeaway()}>
        Add new takeaway
      </Button>

      <Button icon={<DeleteOutlined />} onClick={removeLastTakeaway} style={{ marginLeft: 10 }}>
        Remove last
      </Button>

      <Button icon={<SyncOutlined />} onClick={postToServer} style={{ marginLeft: 10 }}>
        Sync
      </Button>
    </div>
  );
};

export default MainTakeawaysEditor;
