import { MENTOR_ROLE } from '@shared/constants';
import { useGetUserProfile } from '@actions';
import { createContext } from 'react';
import ProfileAside from './ProfileAside/ProfileAside';
import ProfileMain from './ProfileMain/ProfileMain';
import { useAuthState } from '@contexts/AuthContext';

interface AccountProfileProps {
  userId?: string;
  isMentorProfile?: boolean;
}

export const ProfileContext = createContext({
  isMentorViewer: false,
  isExternalViewer: false,
  isLoading: true,
});

const AccountProfile = (props: AccountProfileProps) => {
  const { userId, isMentorProfile = false } = props;

  const user = useAuthState();
  const { data: userProfile, isLoading } = useGetUserProfile(userId ?? user.userId);

  const isExternalViewer = user.userId !== userProfile?._id;
  const isMentorViewer = user.roles.includes(MENTOR_ROLE);

  return (
    <section className="flex flex-col p-4 gap-4 max-w-[1654px] m-auto tablet:flex-row tablet:items-start">
      <ProfileContext.Provider value={{ isMentorViewer, isExternalViewer, isLoading }}>
        <ProfileAside
          hasEditPermissions={!isExternalViewer}
          userProfile={userProfile}
          isMentorProfile={isMentorProfile}
        />
        <ProfileMain userProfile={userProfile} isMentorProfile={isMentorProfile} />
      </ProfileContext.Provider>
    </section>
  );
};

export default AccountProfile;
