export const LANDING = '/';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/reset-password';
export const COURSE_LIST = '/course/list';
export const NEW_ENROLLMENTS = '/enrollments';
export const COURSES = '/courses';
export const MY_ENROLLMENTS = '/my-enrollments';
export const KNOWLEDGE_BASE_ASSISTANT = '/knowledge-base-assistant';
export const SESSIONS = '/sessions';
export const MENTOR_ACTIVITY_REPORT = '/activity-report';
export const COURSE_PAGE = '/course';

export const VIDEO_MEETING = '/video-meeting';
export const STUDENT_PROFILE = '/student-profile';

export const SESSION_DETAILS = '/session-details';

export const MANAGE_USERS_V3 = '/admin/manage-users-v3';
export const MANAGE_MENTORS_V3 = '/admin/mentors-v3';
export const MANAGE_STUDENTS = '/admin/students';
export const MANAGE_COURSES = '/admin/manage-courses';
export const MEETING_MANAGEMENT = '/admin/meeting-management';
export const NPS_DASHBOARD = '/admin/nps-dashboard';
export const ACTIVITY_REPORT = '/admin/activity-report';
export const EXTRA_SERVICES = '/admin/extra-services';
export const FLAGS = '/admin/flags';
export const MANAGE_KNOWLEDGE = '/admin/manage-knowledge-base';
export const KNOWLEDGE_BASE = '/admin/knowledge-base';

export const MENTOR_HOME = '/mentor/home';
export const MENTOR_STUDENTS = '/mentor/students';
export const MENTOR_MEETINGS = '/mentor/meetings';
export const MENTOR_SETTINGS = '/mentor/settings';
export const MENTOR_PAYMENTS = '/mentor/payments';
export const MENTOR_REQUESTS_INBOX = '/mentor/requests';

export const PRIVACY_POLICY = '/privacy-policy';
