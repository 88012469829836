import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { Course, User } from '@shared/common';
import { useGetAllCourses } from '@actions';
import { GetAllCoursesCanTeachDTO } from '@shared/types/DTOs/MentorsDTO';
import { useController, useForm } from 'react-hook-form';
import { UseMutateFunction } from 'react-query';
import { z } from 'zod';
import { ExamFormProps } from '../../types';
import ProfileFormWrapper from '../ProfileFormWrapper';

interface CoursesCanTeachFormProps extends ExamFormProps {
  coursesCanTeach?: GetAllCoursesCanTeachDTO[];
  updateMyMentorData: UseMutateFunction<User, Error, any, unknown>;
  isLoading: boolean;
}

const formDataSchema = z.object({
  coursesCanTeach: z.string().min(1, { message: 'Please select a valid course.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const CoursesCanTeachForm = (props: CoursesCanTeachFormProps) => {
  const {
    setIsEditMode,
    coursesCanTeach,
    setIsAddNewItem,
    onCancel,
    updateMyMentorData,
    isLoading,
  } = props;

  const { data: courses, isLoading: areCoursesLoading } = useGetAllCourses();

  const courseOptions = courses?.filter((course: Course) => {
    return !coursesCanTeach?.map((course) => course._id)?.find((m: string) => m === course._id);
  });

  const { handleSubmit, trigger, setValue, getValues, reset, control } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const { field: coursesCanTeachField, fieldState: coursesCanTeachState } = useController({
    name: 'coursesCanTeach',
    control,
  });

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid && coursesCanTeach) {
      updateMyMentorData({
        coursesCanTeach: [...coursesCanTeach, { _id: data.coursesCanTeach }],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <ProfileFormWrapper
      title="Course"
      isModeAddNew={true}
      onCancel={onCancel}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      submitHandler={submitHandler}
    >
      <Select
        options={courseOptions?.map((course) => ({
          label: course.title,
          value: course._id,
        }))}
        isLoading={areCoursesLoading}
        onSelect={coursesCanTeachField.onChange}
        onBlur={coursesCanTeachField.onBlur}
        value={coursesCanTeachField.value}
        size="large"
        id={'courses'}
        label={'Courses'}
        isRequired
        allowClear={false}
        isTouched={coursesCanTeachState.isTouched}
        isValid={!coursesCanTeachState.error}
        errorText={coursesCanTeachState.error?.message}
      />
    </ProfileFormWrapper>
  );
};

export default CoursesCanTeachForm;
