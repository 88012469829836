import IconButton from '@components/V4/IconButton';
import useToast from 'apps/agora/src/hooks/useToast';
import { ReactComponent as CopyIcon } from '@assets/icons/V4/copy-icon.svg';
import { ReactNode } from 'react';

interface MeetingDetailProps {
  name: string;
  value: string | ReactNode;
  duration?: string;
  showCopyBtn?: boolean;
}

const MeetingDetail = (props: MeetingDetailProps) => {
  const { name, value, duration, showCopyBtn } = props;
  const toast = useToast();

  const meetingIdClickHandler = () => {
    if (!showCopyBtn) return;

    navigator.clipboard.writeText(value as string);
    toast.info('Meeting ID copied to clipboard.');
  };

  return (
    <div className="flex gap-2">
      <p className="font-raleway font-medium text-xs text-customGrey min-w-fit">
        {name}
        {!!duration && (
          <>
            <br />
            <span className="font-bold min-w-fit text-right block">{duration}</span>
          </>
        )}
      </p>

      <p className="flex gap-2 font-raleway font-medium text-xs relative">
        {value}
        {showCopyBtn && (
          <IconButton
            className="absolute right-0 top-1/2 translate-x-6 -translate-y-1/2"
            variant="ghost"
            icon={<CopyIcon className="w-5 h-5" />}
            onClick={meetingIdClickHandler}
          />
        )}
      </p>
    </div>
  );
};

export default MeetingDetail;
