import apiClient from '@contexts/AuthContext/apiClient';
import { message } from 'antd';

const create = async ({ title, keywords, body }) => {
  const article = await apiClient.post('/knowledge-base/create', {
    title,
    keywords,
    body,
  });
  message.success('Article created.');
  return article;
};

const read = async (id) => {
  const article = await apiClient.get(`/knowledge-base/${id}`);
  return article;
};

const update = async (id, data) => {
  const article = await apiClient.post(`/knowledge-base/${id}/update`, {
    ...data,
  });
  message.success('Article updated.');
  return article;
};

const remove = async (id) => {
  await apiClient.delete(`/knowledge-base/${id}/delete`);
  message.success('Article deleted.');
};

const like = async (id) => {
  await apiClient.post(`/knowledge-base/${id}/like`, {});
};

const dislike = async (id) => {
  await apiClient.post(`/knowledge-base/${id}/dislike`, {});
};

const ArticleCRUD = { create, read, update, remove, dislike, like };

export default ArticleCRUD;
