import { CloseCircleFilled } from '@ant-design/icons';
import { Select } from 'antd';
import classNames from 'classnames';
import AgoraInputError from '../AgoraInputError';
import './agora-big-selector.styles.scss';
import arrowDown from '@assets/icons/input-arrow-expand.svg';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import InputLabel from '@components/V4/Inputs/InputLabel';

type Props = {
  label?: string;
  onSelect: (value?: string | number) => void;
  onSearch?: (value: string) => void;
  options: any[] | undefined;
  labelKey?: string;
  secondLabelKey?: string;
  valueKey?: string;
  showOptionsBy?: 'value' | 'index' | undefined;
  mode?: 'multiple' | 'tags' | undefined;
  onDeselect?: (value: string | undefined) => void;
  onBlur?: (value: any) => void;
  onClear?: () => void;
  value?: any;
  isLoading?: boolean;
  isRequired?: boolean;
  className?: string;
  selectClassName?: string;
  isValid?: boolean;
  isTouched?: boolean;
  isDisabled?: boolean;
  errorText?: string;
  autoFocus?: boolean;
  allowClear?: boolean;
  filterOption?: () => void;
  maxTagCount?: number | 'responsive';
  variant?: 'primary' | 'secondary';
};

/**
 * @deprecated
 */
const AgoraBigSelector = ({
  label,
  onSelect,
  onSearch,
  options,
  labelKey = '',
  secondLabelKey = '',
  valueKey = '',
  showOptionsBy,
  mode = undefined,
  onDeselect,
  onBlur,
  onClear,
  value,
  className,
  selectClassName,
  isRequired,
  isLoading,
  isValid,
  isTouched,
  errorText,
  autoFocus,
  isDisabled = false,
  allowClear = true,
  maxTagCount = 'responsive',
  filterOption,
  variant = 'primary',
}: Props) => {
  return (
    <div
      className={classNames(
        'agora-big-selector',
        className,
        isValid && isTouched
          ? 'agora-big-selector--valid'
          : !isValid && isTouched
          ? 'agora-big-selector--error'
          : ''
      )}
    >
      {label && (
        <InputLabel label={label} isRequired={isRequired} isTouched={isTouched} isValid={isValid} />
      )}

      <Select
        value={value}
        disabled={isDisabled}
        className={mergeClassNames(
          {
            'custom-multiple-select-big': mode === 'multiple',
            'custom-ant-select': mode !== 'multiple',
          },
          selectClassName
        )}
        maxTagCount={maxTagCount}
        loading={isLoading}
        onSelect={(value) => {
          onSelect(value);
        }}
        onDeselect={(value: string) => {
          if (onDeselect) {
            onDeselect(value);
          }
        }}
        mode={mode ? mode : undefined}
        showArrow={false}
        allowClear={allowClear}
        onClear={onClear ? onClear : () => onSelect(undefined)}
        filterOption={
          filterOption
            ? filterOption
            : (input, option) => (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())
        }
        clearIcon={
          <div className="clear-icon">
            <CloseCircleFilled className="close-icon" />
          </div>
        }
        autoFocus={autoFocus}
        showSearch
        placeholder={
          <div className="placeholder">
            <span>Search to select</span>
            <span>
              <img
                className="placeholder-image"
                src={arrowDown}
                alt="Click to interact with the selector."
              />
            </span>
          </div>
        }
        options={options?.map((option, index) => ({
          value:
            showOptionsBy === 'value'
              ? option
              : showOptionsBy === 'index'
              ? index.toString()
              : option?.[valueKey],
          label:
            showOptionsBy === 'value'
              ? option
              : showOptionsBy === 'index'
              ? option
              : secondLabelKey
              ? option?.[labelKey]?.[secondLabelKey]
              : option?.[labelKey],
        }))}
        onSearch={(value) => {
          if (onSearch) {
            onSearch(value);
          }
        }}
        onBlur={onBlur}
      />

      {!isValid && isTouched && errorText && <AgoraInputError errorText={errorText} />}
    </div>
  );
};

export default AgoraBigSelector;
