import { ReactComponent as BlockIcon } from '@assets/icons/V4/block-icon.svg';
import { ReactComponent as DefaultAvatarIcon } from '@assets/icons/V4/default-profile-pic.svg';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Button from '@components/V4/Button';
import { PaginationProps } from '@components/V4/Pagination';
import Table, { SortOrder } from '@components/V4/Table/Table';
import { EnrollmentWithStudentTypes } from '@shared/common';
import { Popconfirm } from 'antd';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { COLORS } from 'apps/agora/src/v3/global';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getProgressPercentage } from './helpers';
import { ENROLLMENT_STATUSES, enrollmentStatusColors } from './misc';
import Tooltip from '@components/V4/Tooltip';

interface MyStudentsDesktopTableProps {
  enrollments?: EnrollmentWithStudentTypes[];
  pagination: PaginationProps;
  sortBy: string | null;
  isLoading: boolean;
  sortOrder: SortOrder;
  sortHandler: (field: string, order: SortOrder) => void;
  onEndEnrollment: (enrollment: EnrollmentWithStudentTypes) => void;
  onShowModal: (defaultEnrollment: EnrollmentWithStudentTypes) => void;
}

interface MyStudentsTableRow {
  enrollment: EnrollmentWithStudentTypes;
  onEndEnrollment: (enrollment: EnrollmentWithStudentTypes) => void;
  onShowModal: (defaultEnrollment: EnrollmentWithStudentTypes) => void;
}
const MyStudentsTableRow = (props: MyStudentsTableRow) => {
  const { enrollment, onEndEnrollment, onShowModal } = props;

  const [isVisible, setIsVisible] = useState(false);

  return (
    <Table.Row isUserView>
      <Table.Cell className="rounded-l-2xl">
        <Link
          to={`/student-profile/${enrollment?.user?._id}`}
          className={mergeClassNames(
            'flex items-center gap-4 text-white underline hover:underline',
            { 'line-through': !!enrollment.user.isDezactivated }
          )}
        >
          {enrollment.user.avatar ? (
            <img
              src={enrollment.user.avatar}
              crossOrigin="anonymous"
              alt=""
              className="w-8 h-8 rounded-full"
            />
          ) : (
            <DefaultAvatarIcon className="w-8 h-8" />
          )}
          {enrollment?.user.fullName}
        </Link>
      </Table.Cell>
      <Table.Cell>
        <div className="flex items-center gap-4">
          <div
            className={mergeClassNames(
              'hidden items-center justify-center w-10 h-10 font-raleway font-bold text-xs text-black rounded-lg laptop:flex',
              {
                'text-xxs':
                  enrollment?.course?.abbreviation?.length &&
                  enrollment?.course?.abbreviation?.length > 3,
              }
            )}
            style={{
              background: enrollment?.course?.color ?? COLORS.PRIMARY,
            }}
          >
            {!!enrollment?.course?.abbreviation &&
            enrollment?.course?.abbreviation !== 'NO_ABBREVIATION'
              ? enrollment?.course?.abbreviation
              : 'n/a'}
          </div>
          {enrollment?.course.title}
        </div>
      </Table.Cell>
      <Table.Cell>
        {!!enrollment?.noOfSessions && (
          <Tooltip
            placement="top"
            text="Completed Sessions / Scheduled or Ongoing Sessions / Total No. of Sessions"
          >
            <div className="flex flex-col gap-1">
              <p>
                {`${enrollment.noOfCompletedSessions || 0} / ${
                  enrollment.noOfScheduledOrInProgressSessions || 0
                } / ${enrollment.noOfSessions}`}
              </p>
              <div className="relative w-full h-2 rounded-full bg-customGrey bg-opacity-50 min-w-24">
                <div
                  style={{
                    width: getProgressPercentage(
                      enrollment?.noOfCompletedSessions,
                      enrollment?.noOfSessions,
                      enrollment?.noOfScheduledOrInProgressSessions
                    ),
                    background: `${enrollment?.course?.color ?? COLORS.PRIMARY}4C`,
                  }}
                  className={mergeClassNames(`rounded-full h-full`)}
                />
                <div
                  style={{
                    width: getProgressPercentage(
                      enrollment?.noOfCompletedSessions,
                      enrollment?.noOfSessions
                    ),
                    background: enrollment?.course?.color ?? COLORS.PRIMARY,
                  }}
                  className={mergeClassNames(`absolute top-0 left-0 rounded-full h-full`)}
                />
              </div>
            </div>
          </Tooltip>
        )}
      </Table.Cell>
      <Table.Cell>{enrollment?.user?.graduationYear}</Table.Cell>
      <Table.Cell>
        <p
          className={mergeClassNames(
            'capitalize',
            enrollment.status ? enrollmentStatusColors[enrollment.status] : ''
          )}
        >
          {enrollment.status ? ENROLLMENT_STATUSES[enrollment.status] : 'n/a'}
        </p>
      </Table.Cell>
      <Table.Cell>
        <p
          className={mergeClassNames({
            'text-customRed': !!enrollment.user.isDezactivated,
            'text-customLime': !enrollment.user.isDezactivated,
          })}
        >
          {enrollment.user.isDezactivated ? 'Inactive' : 'Active'}
        </p>
      </Table.Cell>
      <Table.Cell className="rounded-r-2xl">
        {!enrollment.ended && !!enrollment.noOfSessions && (
          <div className="flex gap-2 ">
            {enrollment.noOfSessions !== enrollment.noOfCompletedSessions &&
              !enrollment.user.isDezactivated && (
                <Button buttonText="Add Meeting" onClick={() => onShowModal(enrollment)} />
              )}
            <Popconfirm
              title="Are you sure you want to end this enrollment?"
              onConfirm={() => onEndEnrollment(enrollment)}
              okText="Yes"
              visible={isVisible}
              cancelText="No"
              onCancel={() => setIsVisible(false)}
            >
              <Button buttonText="End" onClick={() => setIsVisible(true)} variant="secondary-red" />
            </Popconfirm>
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

const MyStudentsDesktopTable = (props: MyStudentsDesktopTableProps) => {
  const {
    enrollments,
    pagination,
    sortBy,
    sortOrder,
    isLoading,
    sortHandler,
    onEndEnrollment,
    onShowModal,
  } = props;

  return (
    <Table
      isLoading={isLoading}
      isUserView
      className="hidden w-full tablet:table"
      pagination={pagination}
    >
      <Table.Header>
        <Table.Row isHeader>
          <Table.HeaderCell>Student</Table.HeaderCell>
          <Table.HeaderCell
            onSort={(order) => sortHandler('course', order)}
            sortOrder={sortBy === 'course' ? sortOrder : undefined}
          >
            Course
          </Table.HeaderCell>
          <Table.HeaderCell
            onSort={(order) => sortHandler('sessions', order)}
            sortOrder={sortBy === 'sessions' ? sortOrder : undefined}
          >
            Sessions
          </Table.HeaderCell>
          <Table.HeaderCell
            onSort={(order) => sortHandler('gradYear', order)}
            sortOrder={sortBy === 'gradYear' ? sortOrder : undefined}
          >
            Graduation
          </Table.HeaderCell>
          <Table.HeaderCell
            onSort={(order) => sortHandler('enrollmentStatus', order)}
            sortOrder={sortBy === 'enrollmentStatus' ? sortOrder : undefined}
          >
            Enrollment Status
          </Table.HeaderCell>
          <Table.HeaderCell
            onSort={(order) => sortHandler('studentStatus', order)}
            sortOrder={sortBy === 'studentStatus' ? sortOrder : undefined}
          >
            Student Status
          </Table.HeaderCell>
          <Table.HeaderCell> </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {!isLoading && !enrollments?.length && (
          <tr>
            <td colSpan={6} className="h-64">
              <EmptyStateCard
                icon={<BlockIcon className="text-customGrey" />}
                text={'No results.'}
              />
            </td>
          </tr>
        )}

        {enrollments?.map((enrollment) => (
          <MyStudentsTableRow
            key={enrollment._id}
            enrollment={enrollment}
            onEndEnrollment={onEndEnrollment}
            onShowModal={onShowModal}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default MyStudentsDesktopTable;
