import { ReactComponent as FlagIcon } from '@assets/icons/V4/flag-icon.svg';
import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as ResumeIcon } from '@assets/icons/V4/resume-icon.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/V4/settings-icon.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import { Enrollment } from '@shared/common';
import { useEndEnrollment, useResumeEnrollment, useUnenrollUser } from '@actions';
import useClientPagination from 'apps/agora/src/hooks/useClientPagination';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import { useContext, useEffect, useMemo, useState } from 'react';
import SessionModalSection, { ActionButton } from '../../../../DetailsModalSection';
import { MentorDetailsPageContext } from '../../../MentorDetailsPage';
import EnrolledInEnrollmentForm from './EnrolledInEnrollmentForm';

interface EnrolledInTableProps extends BasicProps {
  areEnrollmentsActive?: boolean;
  enrollments: Partial<Enrollment>[];
}

const EnrolledInTable = (props: EnrolledInTableProps) => {
  const { areEnrollmentsActive, enrollments } = props;

  const { courses, onEnrollmentModalOpen } = useContext(MentorDetailsPageContext);

  const [isAddingEnrollment, setIsAddingEnrollment] = useState(false);

  const toast = useToast();

  const filteredEnrollments = useMemo(
    () =>
      enrollments?.filter((enrollment) =>
        areEnrollmentsActive ? !enrollment.ended : enrollment.ended
      ) || [],

    [enrollments, areEnrollmentsActive]
  );

  const [paginatedEnrollments, pagination] = useClientPagination({
    data: filteredEnrollments,
    shouldOnlyShowPages: true,
    initialPageSize: 5,
  });

  const { mutate: endEnrollment, isLoading: isEndingEnrollment } = useEndEnrollment({
    onSuccess: () => {
      toast.success('Enrollment ended successfully.');
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.error?.message);
    },
  });

  const { mutate: unenrollUser, isLoading: isEnrollmentDeleting } = useUnenrollUser({
    onSuccess: () => {
      toast.success('Enrollment deleted successfully.');
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.error?.message);
    },
  });

  const { mutate: resumeEnrollment, isLoading: isResumingEnrollment } = useResumeEnrollment({
    onSuccess: () => {
      toast.success('Enrollment resumed successfully.');
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.error?.message);
    },
  });

  useEffect(() => {
    setIsAddingEnrollment(false);
  }, [areEnrollmentsActive]);

  const getSectionButtons = () => {
    const buttons: ActionButton[] = [];

    if (areEnrollmentsActive) {
      buttons.push({
        buttonText: 'Add Enrollment',
        onClick: () => setIsAddingEnrollment(true),
      });
    }

    return buttons;
  };

  const endClickHandler = (enrollmentId: string) => {
    endEnrollment({ enrollmentId });
  };

  const resumeClickHandler = (enrollmentId: string) => {
    resumeEnrollment({ enrollmentId });
  };

  const deleteClickHandler = (enrollmentId: string) => {
    unenrollUser({ enrollmentId });
  };

  const isLoading = isEndingEnrollment || isResumingEnrollment || isEnrollmentDeleting;

  return isAddingEnrollment ? (
    <EnrolledInEnrollmentForm
      onCloseEditForm={() => setIsAddingEnrollment(false)}
      courses={courses}
    />
  ) : (
    <SessionModalSection
      className="mt-6"
      sectionTitle="Enrolled In"
      buttonsArray={getSectionButtons()}
    >
      {paginatedEnrollments?.length ? (
        <Table size="small" pagination={pagination}>
          <Table.Header>
            <Table.Row isHeader>
              <Table.HeaderCell>Enrollment</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {paginatedEnrollments.map((enrollment) => {
              return (
                <Table.Row key={enrollment._id}>
                  <Table.Cell onClick={() => onEnrollmentModalOpen(enrollment._id ?? '')}>
                    <div className="flex items-center gap-2">
                      <span
                        className="w-[9px] h-[9px] rounded-full"
                        style={{ backgroundColor: enrollment.course?.color }}
                      />
                      {enrollment?.course?.title}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex gap-4">
                      <IconButton
                        isDisabled={isLoading}
                        size="small"
                        variant="ghost"
                        icon={<SettingsIcon />}
                        onClick={() => onEnrollmentModalOpen(enrollment._id ?? '')}
                      />
                      {areEnrollmentsActive ? (
                        <IconButton
                          isDisabled={isLoading}
                          size="small"
                          variant="ghost"
                          color="danger"
                          icon={<FlagIcon />}
                          onClick={() => endClickHandler(enrollment._id ?? '')}
                        />
                      ) : (
                        <IconButton
                          isDisabled={isLoading}
                          size="small"
                          variant="ghost"
                          icon={<ResumeIcon />}
                          onClick={() => resumeClickHandler(enrollment._id ?? '')}
                        />
                      )}
                      <IconButton
                        isDisabled={isLoading}
                        size="small"
                        variant="ghost"
                        color="danger"
                        icon={<TrashIcon />}
                        onClick={() => deleteClickHandler(enrollment._id ?? '')}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <EmptyStateCard text="No Existing Data" icon={<NoDataIcon className="text-customGrey" />} />
      )}
    </SessionModalSection>
  );
};

export default EnrolledInTable;
