import { useState, useEffect, createContext, useContext, ReactNode } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

const useIsOnMobile = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const [isOnMobile, setIsOnMobile] = useState(false);

  useEffect(() => {
    let throttled = false;
    let timeoutId: ReturnType<typeof setTimeout>;
    function handleResize() {
      if (!throttled) {
        setWindowDimensions(getWindowDimensions());
        throttled = true;
        timeoutId = setTimeout(() => {
          throttled = false;
        }, 300);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    window.matchMedia('(min-width: 800px)').matches ? setIsOnMobile(false) : setIsOnMobile(true);
  }, [windowDimensions.width]);

  return isOnMobile;
};

const MobileContext = createContext<boolean | undefined>(undefined);

type Props = {
  children: ReactNode;
};

const MobileProvider = ({ children }: Props) => {
  const isMobile = useIsOnMobile();
  return <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>;
};

const useMobileState = () => {
  const context = useContext(MobileContext);

  return context;
};

export { MobileProvider, useMobileState };
