import {  useGetMentorTeachingAndEnrolledInCourses } from '@actions';
import CoursesSection from './CoursesSection';
import CoursesSectionWrapper from './CoursesSectionWrapper';
import { useAuthState } from '@contexts/AuthContext';

const MentorCourses = () => {
  const { userId } = useAuthState();

  const { data: courses, isLoading } = useGetMentorTeachingAndEnrolledInCourses(userId);
  return (
    <section className="flex flex-col items-center gap-8 py-8 tablet:py-12">
      <CoursesSectionWrapper title="Teaching" showButton>
        <CoursesSection courses={courses?.teachingCourses} isLoading={isLoading} />
      </CoursesSectionWrapper>
      <CoursesSectionWrapper title="Enrolled in">
        <CoursesSection courses={courses?.enrolledInCourses} isLoading={isLoading} />
      </CoursesSectionWrapper>
    </section>
  );
};

export default MentorCourses;
