import { useGetNoOfFlagsByFlagTypes } from '@actions';
import FlagsDistributionOverTime from './FlagDistributionOverTime';
import FlagsPieChart from './FlagsPieChart';
import TotalFlagsBanner from './TotalFlagsBanner';

type Props = {
  search: any;
  isActive: any;
  flagType: any;
  startRange: any;
  endRange: any;
  studentId: any;
  mentorId: any;
  courseId: any;
};

const FlagsOverview = ({
  search,
  isActive,
  flagType,
  startRange,
  endRange,
  studentId,
  mentorId,
  courseId,
}: Props) => {
  const { data: noOfFlagTypes } = useGetNoOfFlagsByFlagTypes({
    search: search,
    isActive: isActive,
    flagType: flagType,
    startRange: startRange,
    endRange: endRange,
    courseId: courseId,
    studentId: studentId,
    mentorId: mentorId,
  });

  return (
    <div>
      <h3 className="flags-overview-title text-white font-bold mb-6">Total Flags</h3>
      <div className="mb-14">
        <TotalFlagsBanner noOfFlagTypes={noOfFlagTypes} />
      </div>
      <h3 className="flags-overview-title text-white font-bold mb-6">
        Flags Distribution Over Time
      </h3>
      <div className="mb-14">
        <FlagsDistributionOverTime
          search={search}
          isActive={isActive}
          flagType={flagType}
          startRange={startRange}
          endRange={endRange}
          studentId={studentId}
          mentorId={mentorId}
          courseId={courseId}
        />
      </div>
      <h3 className="flags-overview-title text-white font-bold mb-6">
        Flags procentual distribution
      </h3>
      <div className="mb-14">
        <FlagsPieChart noOfFlagTypes={noOfFlagTypes} />
      </div>
    </div>
  );
};

export default FlagsOverview;
