import { useState } from 'react';

const useServerErrors = () => {
  const [serverErrors, setServerErrors] = useState<Record<string, string[]>>();

  const clearServerErrors = () => {
    setServerErrors(undefined);
  };

  const updateServerErrors = (error: unknown) => {
    if ((error as any).response?.data?.error?.invalidFields) {
      setServerErrors((error as any).response.data.error.invalidFields);
    }
  };

  const isFieldValid = (field: string) => !serverErrors?.[field]?.length;
  const getServerErrorText = (field: string) => serverErrors?.[field]?.[0];

  return { clearServerErrors, updateServerErrors, isFieldValid, getServerErrorText };
};

export default useServerErrors;
