import { MEETINGS_PAGE_LIMIT } from '@shared/constants';
import style from '@styles/views/home.module.scss';
import SectionTitle from '../Utils/SectionTitle';
import MeetingNeedingFeedbackMentor from './MeetingNeedingFeedbackMentor';
import { useState } from 'react';
import DotPagination from '@components/V4/DotPagination';
import SkeletonBox from '@components/V4/SkeletonBox';
import { useFeedbackMeetings } from '@actions';

type Props = {
  isMentor?: boolean;
};

const MeetingsNeedingFeedback = ({ isMentor }: Props) => {
  const [page, setPage] = useState(1);

  const { data, isLoading, refetch } = useFeedbackMeetings(page);

  const { data: meetings, total } = data || {};

  if (!total && !isLoading) {
    return null;
  }

  return (
    <>
      <div className="mb-8 mt-14">
        <SectionTitle title="Meetings Needing Feedback" />
      </div>
      {isLoading ? (
        <div className="flex flex-col gap-5 mb-6">
          {[...Array(MEETINGS_PAGE_LIMIT).keys()].map((_, index) => (
            <SkeletonBox className="h-18 w-full" key={index} />
          ))}
        </div>
      ) : (
        <>
          <div className={style.meetings}>
            {meetings?.map((meeting: any) => (
              <MeetingNeedingFeedbackMentor
                key={meeting._id}
                meeting={meeting}
                refetch={refetch}
                isMentor={isMentor}
              />
            ))}
          </div>
        </>
      )}
      <DotPagination
        isVisible={isLoading || (!!total && total > MEETINGS_PAGE_LIMIT)}
        itemCount={meetings?.length}
        isDisabled={isLoading}
        page={page}
        noOfPages={Math.ceil((total || 0) / MEETINGS_PAGE_LIMIT)}
        onSetPage={setPage}
      />
    </>
  );
};

export default MeetingsNeedingFeedback;
