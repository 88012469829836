import { mentorApis, usersApis } from '@shared/apis';
import {
  genericMutation,
  genericMutationWithId,
  genericMutationWithParams,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useUpdateMentorCapacity = genericMutationWithId<mentorApis.UpdateMentorCapacity>(
  mentorApis.updateMentorCapacity,
  usersApis.getUserProfile.endpoint
);

export const useGetMentorCapacity = genericQueryWithId<mentorApis.GetMentorCapacity>(
  mentorApis.getMentorCapacity
);

export const useGetMentorWidgetStatistics = genericQuery<mentorApis.GetMentorWidgetStatistics>(
  mentorApis.getMentorWidgetStatistics
);

export const useSearchMentors = genericQueryWithParams<mentorApis.SearchMentors>(
  mentorApis.searchMentors
);

export const useGetAllCoursesCanTeach = genericQueryWithId<mentorApis.GetAllCoursesCanTeach>(
  mentorApis.getAllCoursesCanTeach
);

export const useReorderCoursesCanTeach =
  genericMutationWithParams<mentorApis.ReorderCoursesCanTeach>(
    mentorApis.reorderCoursesCanTeach,
    mentorApis.getAllCoursesCanTeach.endpoint
  );
