import { MeetingTranscript } from '@shared/types/DTOs/EnrollmentsDTO';
import classNames, { ArgumentArray } from 'classnames';
import moment, { Moment } from 'moment';
import { CSSProperties } from 'react';
import { extendTailwindMerge } from 'tailwind-merge';
import { PUBLIC_PATHS } from './constants';
import { AttendanceStatus, GroupedTranscripts } from './types';

const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [{ text: ['xxs', 'xsm', 'sbase'] }],
    },
  },
});

export const mergeClassNames = (...classes: ArgumentArray) => customTwMerge(classNames(...classes));

export const getValidMoment = (date: string | null): Moment | null => {
  const parsedDate = moment(date);
  return parsedDate.isValid() ? parsedDate : null;
};

export const getStartDateValue = (value?: Moment) =>
  value?.startOf('day') || moment().subtract(1, 'month');

export const getEndDateValue = (value?: Moment) => value?.endOf('day') || moment().add(1, 'month');

export const formatEnumValue = (value?: string) => value?.split('_').join(' ');

export const getHasValue = (value: string | number) => {
  return value !== undefined && value !== null && value !== '';
};

export const isValidWiseField = (value: string) => /^[A-Za-z0-9-./?:(),+ ]+$/.test(value);

export const getNumericEnumValues = <T extends object>(enumObj: T): number[] =>
  Object.values(enumObj).filter((value) => typeof value === 'number') as number[];

export const getTimestampFromMeetingTime = (timestamp: string, meetingStartDate: string) => {
  const date = moment(meetingStartDate);
  const [hours, minutes, secondsWithMs] = timestamp.split(':');
  const [seconds, milliseconds] = secondsWithMs.split('.');

  // Add each part to the date
  return date
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .add(seconds, 'seconds')
    .add(milliseconds, 'milliseconds')
    .format('HH:mm');
};

export const groupTranscriptsByAuthor = (transcript: MeetingTranscript[]) => {
  const groupedMessages: GroupedTranscripts[] = [];

  let currentGroup: GroupedTranscripts | null = null;

  transcript.forEach((message) => {
    if (!currentGroup || currentGroup.user !== message.user) {
      // Start a new group if the user changes
      if (currentGroup) {
        groupedMessages.push(currentGroup);
      }
      currentGroup = { user: message.user, messages: [message] };
    } else {
      // Add to the current group if the user is the same
      currentGroup.messages.push(message);
    }
  });

  // Push the last group to the result
  if (currentGroup) {
    groupedMessages.push(currentGroup);
  }

  return groupedMessages;
};

export function convertHexToRGBA(hex: CSSProperties['color'], opacity: number) {
  if (!hex) return;

  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
}
export const isObjectEmpty = <T extends Record<string, unknown>>(obj?: T): boolean => {
  return !obj || Object.keys(obj).length === 0;
};

export const getIsPublicPath = () => {
  let isPublic = false;

  PUBLIC_PATHS.forEach((path) => {
    if (window.location.pathname.includes(path)) {
      isPublic = true;
      return;
    }
  });

  return isPublic;
};

export const convertSecondsToTime = (seconds?: number) => {
  if (!seconds) return 'n/a';

  const duration = moment.duration(seconds, 'seconds');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  if (hours === 0) return `${minutes}m`;
  return `${hours}h, ${minutes}m`;
};

export const normalizeFeedback = (feedbackStr?: string) => {
  if (!feedbackStr) return '';

  // Trim leading and trailing whitespace
  let trimmedStr = feedbackStr.trim();

  // Remove trailing commas and whitespace
  trimmedStr = trimmedStr.replace(/,\s*$/, '');

  // Early exit if the string doesn't start with '[' or '{'
  if (!trimmedStr.startsWith('[') && !trimmedStr.startsWith('{')) {
    return feedbackStr;
  }

  try {
    const parsed = JSON.parse(trimmedStr);
    if (Array.isArray(parsed)) {
      return parsed.join(', ');
    } else if (typeof parsed === 'object' && parsed !== null) {
      // If it's an object, you can handle it accordingly
      return JSON.stringify(parsed);
    }
  } catch (e) {
    // If parsing fails, return the original string
  }
  return feedbackStr;
};

export const getSymbolFromCurrency = (billingCurrency: string) => {
  let currencySymbol = '';
  switch (billingCurrency) {
    case 'RON':
      currencySymbol = 'RON';
      break;

    case 'USD':
      currencySymbol = '$';
      break;

    case 'EUR':
      currencySymbol = '€';
      break;

    case 'GBP':
      currencySymbol = '£';
      break;

    default:
      currencySymbol = '$';
      break;
  }

  return currencySymbol;
};

export const formatAttendance = (attendance: AttendanceStatus) => {
  return attendance.joinTime
    ? `${moment(attendance.joinTime).format('DD MMM, HH:mm')} - ${moment(
        attendance.leaveTime
      ).format('HH:mm')}`
    : 'n/a';
};

export const getAttendanceTimes = (
  attendanceStatuses: AttendanceStatus[] | undefined,
  isWaitingRoom: boolean
) => {
  if (!attendanceStatuses?.length) return 'n/a';
  return attendanceStatuses
    .filter((status) => (isWaitingRoom ? status.isWaitingRoom : !status.isWaitingRoom))
    .map(formatAttendance)
    .join('; ');
};

export const getValueOrNA = (value: string | number | undefined) => {
  return value !== undefined && value !== null && value !== '' ? value : 'n/a';
};

export const checkNaN = (value: number) => (isNaN(value) ? 0 : value);