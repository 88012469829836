import { FLAG_TYPES } from '@shared/constants';
import { Empty } from 'antd';
import { COLORS } from 'apps/agora/src/v3/global';
import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

interface CustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: CustomizedLabelProps) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

type Props = {
  noOfFlagTypes: any;
};

const FlagsPieChart = ({ noOfFlagTypes }: Props) => {
  const totalCount = noOfFlagTypes?.reduce(
    (accumulator: number, currentValue: any) => accumulator + currentValue.count,
    0
  );

  const processedData = noOfFlagTypes
    ?.map((flagType: any) => {
      const percentage = (flagType.count / totalCount) * 100;
      return {
        name: FLAG_TYPES[flagType.value],
        value: percentage >= 2 ? flagType.count : 0,
        count: flagType.count,
      };
    })
    .filter((item: any) => item.value !== 0);

  const totalProcessedCount = processedData?.reduce(
    (accumulator: number, currentValue: any) => accumulator + currentValue.value,
    0
  );

  if (totalCount !== totalProcessedCount) {
    processedData.push({
      name: 'Other',
      value: totalCount - totalProcessedCount,
      count: totalCount - totalProcessedCount,
    });
  }

  return (
    <div className="w-full h-[400px] flex justify-center items-center">
      {processedData?.length === 0 ? (
        <Empty />
      ) : (
        <ResponsiveContainer width="80%" height="80%">
          <PieChart>
            <Pie
              data={processedData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
            >
              {processedData?.map((entry: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS.FLAG_COLORS[index % COLORS.FLAG_COLORS.length]}
                  stroke={COLORS.DARK_GREY4}
                  strokeWidth={1}
                />
              ))}
            </Pie>
            <Legend
              align="left"
              verticalAlign="middle"
              layout="vertical"
              iconSize={12}
              formatter={(value, entry) => {
                const dataItem = processedData.find((item: any) => item.name === value);
                return (
                  <span style={{ color: 'white' }}>
                    {value} ({dataItem ? dataItem.count : 'N/A'})
                  </span>
                );
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default FlagsPieChart;
