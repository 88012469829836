import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import SessionModalSection, {
  ActionButton,
} from '@modules/Admin/components/DetailsModal/DetailsModalSection';
import { useUpdateMyMentorData } from '@actions';
import useClientPagination from 'apps/agora/src/hooks/useClientPagination';
import useToast from 'apps/agora/src/hooks/useToast';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { MentorDetailsPageContext } from '../../../MentorDetailsPage';
import SubjectsForm from './SubjectsForm';

const SubjectsTable = () => {
  const { mentor } = useContext(MentorDetailsPageContext);

  const [isAddingMajor, setIsAddingMajor] = useState(false);

  const toast = useToast();
  const query = useQueryClient();

  const { mutate: updateMyMentorData, isLoading } = useUpdateMyMentorData({
    onSuccess: async () => {
      toast.success('Successfully updated your profile.');
      await query.invalidateQueries('/users/:id/profile');
      setIsAddingMajor(false);
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const mentorId = mentor?._id ?? '';
  const majors = mentor?.mentorData?.majors;

  const deleteEntryHandler = (entry: string) => {
    if (majors && !isLoading) {
      const newArr = majors.filter((major) => major !== entry);
      updateMyMentorData({
        majors: newArr,
        userId: mentorId,
      });
    }
  };

  const [paginatedMajors, pagination] = useClientPagination({
    data: majors || [],
    shouldOnlyShowPages: true,
  });

  const buttons: ActionButton[] = [{ buttonText: 'Add', onClick: () => setIsAddingMajor(true) }];

  return isAddingMajor ? (
    <SubjectsForm
      className="mt-6"
      isLoading={isLoading}
      updateMyMentorData={updateMyMentorData}
      onCloseEditForm={() => setIsAddingMajor(false)}
    />
  ) : (
    <SessionModalSection className="mt-6" sectionTitle="Subjects Can Teach" buttonsArray={buttons}>
      {majors?.length ? (
        <Table size="small" pagination={pagination}>
          <Table.Header>
            <Table.Row isHeader>
              <Table.HeaderCell>Subject</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {paginatedMajors.map((item: any) => (
              <Table.Row key={item}>
                <>
                  <Table.Cell>{item}</Table.Cell>
                  <Table.Cell>
                    <IconButton
                      isDisabled={isLoading}
                      size="small"
                      variant="ghost"
                      color="danger"
                      icon={<TrashIcon />}
                      onClick={() => deleteEntryHandler(item)}
                    />
                  </Table.Cell>
                </>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <EmptyStateCard text="No Existing Data" icon={<NoDataIcon className="text-customGrey" />} />
      )}
    </SessionModalSection>
  );
};

export default SubjectsTable;
