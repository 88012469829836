import { ReactComponent as CompletedIcon } from '@assets/icons/V4/empty-states/completed-meetings-icon.svg';
import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/empty-states/sad-calendar.svg';
import { Enrollment, MEETINGS_PAGE_LIMIT } from '@shared/common';
import { useStudentEnrollments, useUpcomingMeetings } from '@actions';
import style from '@styles/views/home.module.scss';
import React, { useState } from 'react';
import EmptyStateCard from '../../../components/EmptyStateCard';
import NextMeetingStudent from './NextMeetingStudent';
import SectionTitle from '@components/V3/Utils/SectionTitle';
import SkeletonBox from '@components/V4/SkeletonBox';
import DotPagination from '@components/V4/DotPagination';

const NextMeetingsStudent: React.FC = () => {
  const [page, setPage] = useState(1);

  const { data, isLoading } = useUpcomingMeetings(page);

  const { data: meetings, total } = data || {};

  const { data: enrollments } = useStudentEnrollments();

  if (
    !!enrollments?.length &&
    enrollments?.every((enrollment: Enrollment) => enrollment?.ended === true)
  ) {
    return (
      <EmptyStateCard
        icon={<CompletedIcon />}
        text={`You've completed all your courses and have no more meetings scheduled. 
Congrats on your achievement!`}
      />
    );
  }

  return (
    <>
      <div className="mb-8 mt-14">
        <SectionTitle title="Scheduled Meetings" />
      </div>
      <div className={style.meetings}>
        {!isLoading && !total && (
          <EmptyStateCard
            icon={<EmptyStateIcon />}
            text={`No scheduled meetings. 
Talk to your mentor to schedule a meeting & stay on track.`}
          />
        )}

        {isLoading ? (
          <div className="flex flex-col gap-5 mb-14">
            {[...Array(MEETINGS_PAGE_LIMIT).keys()].map((_, index) => (
              <SkeletonBox className="h-18 w-full" key={index} />
            ))}
          </div>
        ) : (
          <>
            {meetings?.map((meeting) => (
              <NextMeetingStudent key={meeting._id} meeting={meeting} />
            ))}
          </>
        )}
        <DotPagination
          isVisible={isLoading || (!!total && total > MEETINGS_PAGE_LIMIT)}
          itemCount={meetings?.length}
          isDisabled={isLoading}
          page={page}
          noOfPages={Math.ceil((total || 0) / MEETINGS_PAGE_LIMIT)}
          onSetPage={setPage}
        />
      </div>
    </>
  );
};

export default NextMeetingsStudent;
