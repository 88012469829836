import NewStudentsAssignedIcon from '@assets/icons/new-students-assigned.svg';
import UploadInvoiceIcon from '@assets/icons/upload-invoice-icon.svg';
import FeedbackImage from '@assets/images/feedback-banner-image2.png';
import { COLORS } from '../../../v3/global';

export const banners = [
  {
    name: 'invoice-banner',
    backgroundColor: '',
    image: UploadInvoiceIcon,
    title: '',
    description: '',
    descriptionTwo: '',
    primaryButtonText: '',
    primaryButtonIcon: '',
    secondaryButtonText: '',
    linkButton: '',
  },
  {
    name: 'feedback-banner',
    backgroundColor: COLORS.PURPLE,
    image: FeedbackImage,
    title: 'Your Feedback Matters!',
    description: 'Please take a moment to share your feedback with us.',
    descriptionTwo: '',
    primaryButtonText: '',
    primaryButtonIcon: '',
    secondaryButtonText: 'Submit feedback',
    linkButton: '',
  },
  {
    name: 'new-students-assigned-banner',
    backgroundColor: COLORS.GOLD,
    image: NewStudentsAssignedIcon,
    title: 'New Students Assigned!',
    description:
      'You have new students assigned. Check the ”My Students” page and schedule a meeting.',
    descriptionTwo: '',
    primaryButtonText: '',
    primaryButtonIcon: '',
    secondaryButtonText: 'View Upcoming Enrollments',
    linkButton: '',
  },
];
