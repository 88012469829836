import Input from '@components/V4/Inputs/Input';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateMentorCapacity } from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import SessionModalFormWrapper from '../../../DetailsModalFormWrapper';

interface CapacityFormProps extends BasicProps {
  userId: string;
  maxEnrollments?: number;
  currentNoEnrollments?: number;
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  maxEnrollments: z.number().min(0, { message: 'Negative numbers are not allowed.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const CapacityForm = (props: CapacityFormProps) => {
  const { userId, maxEnrollments, currentNoEnrollments, onCloseEditForm } = props;

  const toast = useToast();
  const query = useQueryClient();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    reset,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      maxEnrollments: maxEnrollments,
    },
  });

  const editButtonHandler = () => {
    onCloseEditForm();
    reset();
  };

  const { mutate: updateMentorCapacity, isLoading } = useUpdateMentorCapacity(userId || '', {
    onSuccess: async () => {
      toast.success("Successfully updated mentor's profile.");
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateMentorCapacity({ capacity: data.maxEnrollments });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (maxEnrollments) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <SessionModalFormWrapper
      title="Edit Maximum Number Of Enrolments"
      onCancel={onCloseEditForm}
      isLoading={isLoading}
      submitHandler={submitHandler}
    >
      <Input
        type="number"
        id={'maxEnrollments'}
        placeholder={''}
        label={'Maximum Enrollments'}
        isRequired
        isTouched={touchedFields.maxEnrollments}
        isValid={!errors.maxEnrollments}
        errorText={errors.maxEnrollments?.message}
        {...register('maxEnrollments', { required: true, valueAsNumber: true })}
      />
      <p className="text-xs text-customGrey mt-4">
        Current number of Enrollments: {currentNoEnrollments}
      </p>
    </SessionModalFormWrapper>
  );
};

export default CapacityForm;
