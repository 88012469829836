import { ReactComponent as NotesIcon } from '@assets/icons/V4/session-details/notes-icon.svg';
import Button from '@components/V4/Button';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { useContext, useState } from 'react';
import SessionDetailsLayoutCard from '../components/SessionDetailsLayoutCard';
import SessionDetailsNotesForm from './SessionDetailsNotesForm';
import { SessionDetailsContext } from 'apps/agora/src/views/UserView/SessionDetails/SessionDetails';

interface SessionDetailsNotesProps {
  notes?: string;
  meetingId?: string;
}

const SessionDetailsNotes = (props: SessionDetailsNotesProps) => {
  const { notes, meetingId } = props;

  const { trackEvent } = useAnalytics();
  const { isLoading } = useContext(SessionDetailsContext);

  const [isEditMode, setIsEditMode] = useState(false);

  const cancelFormSubmissionHandler = () => {
    setIsEditMode(false);
  };

  const addNotesClickHandler = () => {
    if (!meetingId) return;

    setIsEditMode((prev) => {
      if (!prev) {
        trackEvent(ANALYTICS_EVENT_NAMES.SESSION_ADD_NOTES_BUTTON_CLICK);
      }

      return !prev;
    });
  };
  return (
    <SessionDetailsLayoutCard
      isLoading={isLoading}
      title="Notes"
      icon={<NotesIcon className="text-white" />}
      onClick={meetingId ? addNotesClickHandler : undefined}
      className="gap-8"
    >
      <>
        {isEditMode && !!meetingId && (
          <SessionDetailsNotesForm
            setIsEditMode={setIsEditMode}
            onCancel={cancelFormSubmissionHandler}
            defaultData={{
              id: meetingId,
              notes: notes ?? undefined,
            }}
          />
        )}

        {!isEditMode &&
          (notes ? (
            <p className="text-sm text-white leading-5">{notes}</p>
          ) : (
            <div className="flex flex-col gap-6 items-center justify-center w-full ">
              <NotesIcon className="text-customGrey" />

              <p className="text-sm text-customGrey text-center  leading-5 whitespace-pre-wrap">
                There are no available notes.
              </p>

              {!!meetingId && (
                <Button variant="secondary" buttonText="Add Notes" onClick={addNotesClickHandler} />
              )}
            </div>
          ))}
      </>
    </SessionDetailsLayoutCard>
  );
};

export default SessionDetailsNotes;
