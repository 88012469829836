import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/no-data.svg';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';

interface DetailsModalEmptyStateProps {
  className?: string;
}

const DetailsModalEmptyState = (props: DetailsModalEmptyStateProps) => {
  const { className } = props;
  return (
    <EmptyStateCard
      className={className}
      icon={<EmptyStateIcon className="text-customGrey" />}
      text="No Existing Data"
    />
  );
};

export default DetailsModalEmptyState;
