import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/empty-states/happy-calendar.svg';
import SectionTitle from '@components/V3/Utils/SectionTitle';
import { useFeedbackMeetings } from '@actions';
import style from '@styles/views/home.module.scss';
import React, { useState } from 'react';
import EmptyStateCard from '../../../components/EmptyStateCard';
import MeetingNeedingFeedbackStudent from './MeetingNeedingFeedbackStudent';
import { MEETINGS_PAGE_LIMIT } from '@shared/constants';
import SkeletonBox from '@components/V4/SkeletonBox';
import DotPagination from '@components/V4/DotPagination';

const MeetingsNeedingFeedback: React.FC = () => {
  const [page, setPage] = useState(1);

  const { data, isLoading } = useFeedbackMeetings(page);

  const { data: meetings, total } = data || {};

  if (!total && !isLoading) {
    return null;
  }

  return (
    <>
      <div className="mb-8 mt-14">
        <SectionTitle title="Meetings Needing Feedback" />
      </div>
      <div className={style.meetings}>
        {isLoading ? (
          <div className="flex flex-col gap-5 mb-14">
            {[...Array(MEETINGS_PAGE_LIMIT).keys()].map((_, index) => (
              <SkeletonBox className="h-18 w-full" key={index} />
            ))}
          </div>
        ) : (
          <>
            {!meetings?.length && (
              <EmptyStateCard
                icon={<EmptyStateIcon />}
                text={`You have no meetings needing feedback.
Thank you for giving us feedback about our services!`}
              />
            )}

            {meetings?.map((meeting: any) => (
              <MeetingNeedingFeedbackStudent key={meeting._id} meeting={meeting} />
            ))}
          </>
        )}
        <DotPagination
          isVisible={isLoading || (!!total && total > MEETINGS_PAGE_LIMIT)}
          itemCount={meetings?.length}
          isDisabled={isLoading}
          page={page}
          noOfPages={Math.ceil((total || 0) / MEETINGS_PAGE_LIMIT)}
          onSetPage={setPage}
        />
      </div>
    </>
  );
};

export default MeetingsNeedingFeedback;
