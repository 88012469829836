import { ReactComponent as EditIcon } from '@assets/icons/V4/edit-icon.svg';
import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import { useSaveUserProfile } from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import moment from 'moment';
import { useContext, useState } from 'react';
import SessionModalSection, { ActionButton } from '../../../../DetailsModalSection';
import { StudentDetailsPageContext } from '../../../StudentDetailsPage';
import PastExamsSectionForm from './PastExamsSectionForm';

interface PastExamsSectionProps extends BasicProps {
  exams?: any;
}

const PastExamsSection = (props: PastExamsSectionProps) => {
  const { exams } = props;

  const { student } = useContext(StudentDetailsPageContext);

  const studentId = student?._id ?? '';

  const [isAddingPastExam, setIsAddingPastExam] = useState(false);
  const [pastExamToEdit, setPastExamToEdit] = useState<any>();

  const toast = useToast();

  const { mutate: updateUserProfile } = useSaveUserProfile(studentId, {
    onSuccess: () => {
      toast.success('Past exam deleted successfully.');
    },
    onError: () => {
      toast.error('Past exam could not be deleted.');
    },
  });

  const positionButtons: ActionButton[] = [
    { buttonText: 'Add', onClick: () => setIsAddingPastExam(true) },
  ];

  const deletPastExamClickHandler = (examId: string) => {
    updateUserProfile({
      languageExams: [
        {
          _id: examId,
          delete: true,
        },
      ],
    });
  };

  return isAddingPastExam ? (
    <PastExamsSectionForm
      isModeAddNew
      className="mt-6"
      onCloseEditForm={() => setIsAddingPastExam(false)}
    />
  ) : (
    <SessionModalSection className="mt-6" sectionTitle="Past Exams" buttonsArray={positionButtons}>
      {exams?.length ? (
        <Table size="small">
          <Table.Header>
            <Table.Row isHeader>
              <Table.HeaderCell className="w-1/4">Exam</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Date</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Score</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {exams.map((item: any) => (
              <Table.Row key={item._id}>
                {pastExamToEdit?._id === item._id ? (
                  <Table.Cell className="!p-0" colSpan={4}>
                    <PastExamsSectionForm
                      className="rounded-none"
                      pastExam={item}
                      onCloseEditForm={() => setPastExamToEdit(undefined)}
                    />
                  </Table.Cell>
                ) : (
                  <>
                    <Table.Cell>{item.exam}</Table.Cell>
                    <Table.Cell>{moment(item.date).format('DD.MM.YYYY')}</Table.Cell>
                    <Table.Cell>{item.prevGrade}</Table.Cell>
                    <Table.Cell>
                      <div className="flex gap-4">
                        <IconButton
                          size="small"
                          variant="ghost"
                          icon={<EditIcon />}
                          onClick={() => setPastExamToEdit(item)}
                        />

                        <IconButton
                          size="small"
                          variant="ghost"
                          color="danger"
                          icon={<TrashIcon />}
                          onClick={() => deletPastExamClickHandler(item._id)}
                        />
                      </div>
                    </Table.Cell>
                  </>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <EmptyStateCard text="No Existing Data" icon={<NoDataIcon className="text-customGrey" />} />
      )}
    </SessionModalSection>
  );
};

export default PastExamsSection;
