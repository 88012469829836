import { ReactComponent as PdfIcon } from '@assets/icons/pdf-icon.svg';
import { ReactComponent as RemoveIcon } from '@assets/icons/remove-icon.svg';
import { ReactComponent as UploadFileIcon } from '@assets/icons/upload-file-icon.svg';
import PrimaryButtonV3 from '@components/V3/PrimaryButtonV3';
import SecondaryButtonV3 from '@components/V3/SecondaryButtonV3';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import AgoraInputLabel from '@components/V3/Utils/InputsV3/AgoraInputLabel';
import AgoraInput from '@components/V3/Utils/InputsV3/AgoraNewInput';
import Modal from '@components/V4/Modal/Modal';
import { reportApis } from '@shared/apis';
import { CloseModalProps, Enrollment, Session } from '@shared/common';
import { Upload } from 'antd';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { COLORS } from '../../v3/global';
import './upload-invoice-modal.style.scss';
import { isValidWiseField } from '../../utils/helpers';
import apiClient from '@contexts/AuthContext/apiClient';
import { useAuthState } from '@contexts/AuthContext';
import useToast from '../../hooks/useToast';

type UserArg = { _id: string; fullName: string };

const { Dragger } = Upload;

type Props = CloseModalProps & {
  name?: string;
  users?: UserArg[];
  enrollmentId?: string;
  defaultEnrollment?: Enrollment;
  defaultStudent?: {
    fullName?: string;
    id: string;
  };
  defaultSession?: Session;
  postSuccess?: () => void;
  reportId?: string;
  setInvoiceUploaded?: (newValue: boolean) => void;
  handleRowTouched?: () => void;
};

const UploadInvoiceModal = (props: Props) => {
  const { visible, reportId, hide, setInvoiceUploaded, handleRowTouched } = props;
  const { userId } = useAuthState();

  const [isCreating, setIsCreating] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [formValidity, setFormValidity] = useState({
    invoiceFile: false,
    invoiceNo: false,
  });
  const [touched, setTouched] = useState(false);
  const [invalidFields, setInvalidFields] = useState<{ invoiceNo?: string[] }>({});

  const queryClient = useQueryClient();

  const isValidLength = (field: string) => (field && field.length > 1) as boolean;

  const runFormValidation = () => {
    setTouched(true);

    const newFormValidity = {
      invoiceFile: !!selectedFile,
      invoiceNo: isValidLength(invoiceNo) && isValidWiseField(invoiceNo),
    };

    setFormValidity(newFormValidity);

    return Object.values(newFormValidity).every(Boolean);
  };

  const closeHandler = () => {
    setSelectedFile(null);
    setInvoiceNo('');
    hide();
  };

  const handleUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('invoice', file);
    formData.append('invoiceNo', invoiceNo);
    const toast = useToast();

    setIsCreating(true);

    if (reportId) {
      try {
        await apiClient.post(`/report/${reportId}/upload-invoice-operations`, formData);
        toast.success('E-Invoice uploaded successfully.');
        setInvoiceUploaded && setInvoiceUploaded(true);
        handleRowTouched && handleRowTouched();
        closeHandler();
      } catch (error: any) {
        if (error.response?.data?.error?.invalidFields) {
          setInvalidFields(error.response.data.error.invalidFields);
        }
      } finally {
        setIsCreating(false);
      }
    } else {
      try {
        await apiClient.post(`/report/invoice/${userId}/upload-invoice`, formData);
        toast.success('E-Invoice uploaded successfully.');
        queryClient.invalidateQueries(reportApis.getLatestMentorReportStatus.endpoint);
        closeHandler();
      } catch (error: any) {
        if (error.response?.data?.error?.invalidFields) {
          setInvalidFields(error.response.data.error.invalidFields);
        }
      } finally {
        setIsCreating(false);
      }
    }
  };

  const submit = () => {
    setInvalidFields({});

    const isFormValid = runFormValidation();

    if (isFormValid) handleUpload(selectedFile);
  };

  const handleFileChange = (info: any) => {
    const { originFileObj } = info.file;
    setSelectedFile(originFileObj);

    info.file.status = 'done';
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value } = event.target;

    let isValid = true;

    if (!isValidLength(value) || !isValidWiseField(value)) {
      isValid = false;
    }

    setFormValidity({
      ...formValidity,
      invoiceNo: isValid,
    });

    setTouched(true);
  };

  return (
    <Modal size="small" isOpen={visible} onClose={closeHandler}>
      <Modal.Header title="Upload your invoice" />
      <Modal.Body>
        <Dragger
          name="invoice"
          customRequest={() => {}}
          accept=".pdf"
          onChange={handleFileChange}
          disabled={!!selectedFile}
          maxCount={1}
          fileList={selectedFile ? [selectedFile] : []}
          itemRender={(originNode, file) => {
            return (
              <div className="">
                <AgoraInputLabel label="Uploaded File:" />
                <div className="flex items-center justify-between py-2.5 px-4 min-h-14 bg-customGrey-700 rounded-lg">
                  <div className="flex items-center gap-4">
                    <div className="">
                      <PdfIcon />
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="text-xs break-words">{file.name}</p>
                      {!!file.size && (
                        <p className="text-xxs font-light">{(file.size / 1024).toFixed(2)} KB</p>
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => setSelectedFile(null)}
                    className="rounded-full fill-customRed cursor-pointer hover:bg-customRed hover:fill-black"
                  >
                    <RemoveIcon className="fill-inherit" />
                  </div>
                </div>
              </div>
            );
          }}
        >
          <div className="flex flex-col items-center gap-6 py-6 px-12 border-2 border-dashed border-white rounded-lg">
            <UploadFileIcon />

            <div className="flex flex-col gap-2 text-customGrey">
              <p className="font-bold text-xsm font-raleway text-inherit">
                Choose a file or drag and drop it here
              </p>
              <p className="text-xs text-inherit">
                Must be in PDF format. Single upload supported.
              </p>
            </div>

            <SecondaryButtonV3 disabled={!!selectedFile} buttonText="Browse File" />
          </div>
        </Dragger>

        {/* Invoice No. input */}
        {!!selectedFile && (
          <AgoraInput
            htmlFor="invoiceNo"
            name="invoiceNo"
            id="invoiceNo"
            type="text"
            placeholder="Type the invoice number for the uploaded invoice"
            label="Invoice Number"
            value={invoiceNo}
            isTouched={touched}
            isValid={formValidity.invoiceNo && !invalidFields.invoiceNo?.length}
            onBlur={(e) => handleBlur(e)}
            onChange={(e) => setInvoiceNo(e.target.value)}
            errorText={
              invalidFields.invoiceNo
                ? invalidFields.invoiceNo[0]
                : 'Invoice number is required and must follow the format: A-Z, a-z, 0-9, and -./?:(),+'
            }
            isRequired
          />
        )}
      </Modal.Body>
      <Modal.Footer className="mt-8">
        <PrimaryButtonV3
          onClick={() => {
            if (!isCreating) {
              submit();
            }
          }}
          disabled={isCreating || !selectedFile}
          buttonText={
            <div className="flex">
              {isCreating && (
                <AgoraSpinner fontSize={14} color={COLORS.TEXT_PRIMARY} margin="0 8px 0 0" />
              )}
              Upload Invoice
            </div>
          }
          className="w-full"
          style={{ width: '100%', height: 40 }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default UploadInvoiceModal;
