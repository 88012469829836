import { StarIcon } from '@heroicons/react/solid';
import React from 'react';

type Props = {
  rating: number;
};

/**
 * @deprecated
 */
const RatingStars = ({ rating }: Props) => {
  const maxStars = 5; // Total number of stars

  return (
    <div className="flex">
      {[...Array(maxStars)].map((_, index) => (
        <StarIcon
          key={index}
          className={`h-5 w-5 flex-shrink-0`}
          style={{ color: index < rating ? '#36B3A8' : '#D8D8D8' }}
          aria-hidden="true"
        />
      ))}
    </div>
  );
};

export default RatingStars;
