import BannerStack from '@components/V3/BannerStack';
import { banners } from '@components/V3/BannerStack/banners';
import HarvesterBanner from '@components/V3/HarvesterBanner';
import MentorCapacityCard from '@components/V3/MentorCapacityCard';
import UserHomeCard from '@components/V3/UserHomeCard';
import PageTitle from '@components/V3/Utils/PageTitle';
import SectionTitle from '@components/V3/Utils/SectionTitle';
import MonthlyNPSModal from '@modules/Base/PendingNPS/MonthlyNPSModal';
import { MENTOR_ROLE } from '@shared/constants';
import {
  useCheckPendingNPS,
  useGetLatestMentorReport,
  useGetMentorCapacity,
  useHasMentorUpcomingStudents,
} from '@actions';
import { detectDeviceType } from '@shared/utils/string';
import { showMentorUploadInvoiceBanner } from 'apps/agora/src/utils/uploadInvoiceBannerHelper';
import style from '@styles/components/reusables/page.module.scss';
import React, { useEffect, useState } from 'react';
import MeetingsNeedingFeedbackMentor from '../../../../components/V3/MeetingsNeedingFeedbackMentor';
import NextMeetingsMentor from '../../../../components/V3/NextMeetingsMentor';
import { COLORS } from '../../../../v3/global';
import OngoingMeetingsSection from '../Common/OngoingMeetingsSection';
import MentorCalendar from './Calendar/Calendar';
import { useAuthState } from '@contexts/AuthContext';

const MentorHome: React.FC = () => {
  const { data, refetch } = useCheckPendingNPS();
  const firstUpgradeNPS = Math.random() < 0.5;

  const { data: lastReportStatus, isLoading: isLoadingLastReportStatus } =
    useGetLatestMentorReport();
  const { data: hasUpcomingStudents, isLoading: isLoadingHasUpcomingStudents } =
    useHasMentorUpcomingStudents();

  const [showNpsModal, setShowNpsModal] = useState(false);

  const { userId, fullName, roles, avatar, isLoading } = useAuthState();

  const isMentor = roles.includes(MENTOR_ROLE);

  const showUploadInvoiceBanner: boolean = showMentorUploadInvoiceBanner(
    isMentor,
    lastReportStatus?.status
  );

  const { data: mentorCapacity, isLoading: isLoadingCapacity } = useGetMentorCapacity(userId);

  const [modalActive, setModalActive] = useState(false);

  const [currentModal, setCurrentModal] = useState('nps-modal');

  const handleCurrentModal = (modalName: string) => {
    setCurrentModal(modalName);
  };

  const handleSubmit = () => {
    setShowNpsModal(true);
  };

  const hasData = data && data?.type === 'monthly';
  const bannersToShow = [];

  if (showUploadInvoiceBanner) {
    bannersToShow.push(banners[0]);
  }

  if (hasData) {
    bannersToShow.push(banners[1]);
  }

  if (hasUpcomingStudents) {
    bannersToShow.push(banners[2]);
  }

  useEffect(() => {
    const isDateLongerThan30Days = () => {
      if (!mentorCapacity || Object.keys(mentorCapacity).length === 0) {
        setModalActive(true);
      } else {
        const updatedAtString = mentorCapacity?.updatedAt;

        if (!updatedAtString) {
          setModalActive(true);
        } else {
          const updatedAt = new Date(updatedAtString);
          const currentDate = new Date();

          const timeDifference = currentDate.getTime() - updatedAt.getTime();

          const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

          if (daysDifference > 30) {
            setModalActive(true);
          }
        }
      }
    };

    if (!isLoadingCapacity) {
      isDateLongerThan30Days();
    }
  }, [isLoadingCapacity, mentorCapacity]);

  return (
    <div className={style.mentorDashboardContainer}>
      <div
        style={{
          backgroundColor: COLORS.SECTION_BACKGROUND,
          minHeight: '100vh',
        }}
        className={`${style.page} ${style.mentorDashboardLeftContainer}`}
      >
        <div className={`${style.mentorDashboardLeftContainerContent}`}>
          {currentModal === 'nps-modal' && data && data?.type === 'monthly' ? (
            <MonthlyNPSModal
              visible={true}
              firstUpgradeNPS={firstUpgradeNPS}
              handleCurrentModal={handleCurrentModal}
              onClose={() => {
                setShowNpsModal(false);
                refetch();
              }}
            />
          ) : (
            <MentorCapacityCard showButton={false} isModalActive={modalActive} />
          )}

          {showNpsModal && (
            <MonthlyNPSModal
              visible={showNpsModal}
              firstUpgradeNPS={firstUpgradeNPS}
              onClose={() => {
                setShowNpsModal(false);
                refetch();
              }}
            />
          )}

          <div
            style={{ marginBottom: '32px' }}
            className={`${style.mentorDashboardSectionMargin} ${style.mentorWelcomeMessage}`}
          >
            <PageTitle
              title={`Welcome back, ${fullName}`}
              description="Let's learn something new today!"
            />
          </div>

          {detectDeviceType() === 'Web' && (
            <div className={style.uploadBannerDesktopContainer} style={{ minWidth: '100%' }}>
              <BannerStack
                showSkeleton={
                  isLoadingCapacity || isLoadingLastReportStatus || isLoadingHasUpcomingStudents
                }
                handleSubmitFeedback={() => {
                  handleSubmit();
                }}
                banners={bannersToShow}
              />
            </div>
          )}

          <div className={style.calendarTitleContainer}>
            <SectionTitle title="Calendar" />
          </div>

          <div className={style.mentorDashboardCalendarMargin}>
            <MentorCalendar />
          </div>

          <div className={style.mentorDashboardSectionMargin}>
            <HarvesterBanner />
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: COLORS.SECTION_BACKGROUND,
        }}
        className={`${style.page} ${style.mentorDashboardRightContainer}`}
      >
        {/*{detectDeviceType() === 'Mobile' && (*/}
        <div
          className={style.uploadBannerMobileContainer}
          style={{ marginBottom: '40px', marginTop: '16px' }}
        >
          <BannerStack
            showSkeleton={
              isLoadingCapacity || isLoadingLastReportStatus || isLoadingHasUpcomingStudents
            }
            handleSubmitFeedback={() => {
              handleSubmit();
            }}
            banners={bannersToShow}
          />
        </div>

        <div
          className={`${style.mentorDashboardSectionMargin} ${style.mentorDashboardBalanceUserContainer}`}
        >
          <div className="ml-auto">
            <UserHomeCard
              isLoading={isLoading}
              name={fullName}
              description={isMentor ? 'Mentor' : 'Student'}
              avatar={avatar}
            />
          </div>
        </div>

        <OngoingMeetingsSection isMentor />

        <MeetingsNeedingFeedbackMentor isMentor={true} />
        <div className="mb-8 mt-14">
          <div className={style.mentorDashboardMeetingSectionContainer}>
            <SectionTitle title="Scheduled Meetings" />
          </div>
          <NextMeetingsMentor isMentor={true} />
        </div>
      </div>
    </div>
  );
};

export default MentorHome;
