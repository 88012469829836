import { useSaveUserProfile } from '@actions';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { ProfileContext } from '../AccountProfile';

interface useDeleteProfileEntryProps {
  userId: string;
  profileProperty: string;
}

export const useDeleteProfileEntry = (props: useDeleteProfileEntryProps) => {
  const { userId, profileProperty } = props;

  const { isExternalViewer } = useContext(ProfileContext);

  const toast = useToast();
  const query = useQueryClient();
  const { trackEvent } = useAnalytics();

  const { mutate: updateUserProfile } = useSaveUserProfile(userId, {
    onSuccess: () => {
      trackEvent(
        isExternalViewer
          ? ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR
          : ANALYTICS_EVENT_NAMES.PROFILE_CHANGE
      );
      query.invalidateQueries('/users/:id/profile');
    },
    onError: () => {
      toast.error('Work experience could not be deleted.');
    },
  });

  const deleteEntryHandler = (id?: string) => {
    if (!id) return;

    updateUserProfile({
      [profileProperty]: [
        {
          _id: id,
          delete: true,
        },
      ],
    });
  };
  return [deleteEntryHandler];
};
