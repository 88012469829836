import SubmitIcon from '@assets/icons/parent-banner-submit-icon.png';
import BannerImage from '@assets/images/feedback-banner-image.png';
import XButton from '@assets/images/x-button.png';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import PrimaryButton from '@components/V3/Utils/PrimaryButton';
import './need-help-parent-banner.scss';
import { useReportParentIssue } from '@actions';
import { Checkbox } from 'antd';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { COLORS } from '../../../v3/global';
import './need-help-parent-banner.scss';
import useToast from 'apps/agora/src/hooks/useToast';

type Props = {
  studentId: string;
  handleShowBanner: (showBanner: boolean) => void;
};

const NeedHelpParentBanner = ({ studentId, handleShowBanner }: Props) => {
  const [bannerState, setBannerState] = useState('default');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedIssues, setSelectedIssues] = useState<string[]>([]);
  const [otherConcerns, setOtherConcerns] = useState('');

  const toast = useToast();

  const handleAddIssue = (flag: string) => {
    setSelectedIssues((prevSelectedIssues) => [...prevSelectedIssues, flag]);
  };

  const handleRemoveIssue = (flag: string) => {
    setSelectedIssues((prevSelectedIssues) => prevSelectedIssues.filter((item) => item !== flag));
  };

  const handleCheckboxChange = (flag: string, checked: boolean) => {
    if (checked) {
      handleAddIssue(flag);
    } else {
      handleRemoveIssue(flag);
    }
  };

  const handleOtherConcerns: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setOtherConcerns(event.target.value);
  };

  const { mutate: registerIssue, isLoading: isLoadingRegisterUser } = useReportParentIssue({
    onSuccess: () => {
      toast.success('Thank you! We will reach back to you soon.');

      setBannerState('success');
    },
  });

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return (
    <div style={{ width: '100%' }}>
      {bannerState === 'success' ? (
        <div className="banner-container" style={{ backgroundColor: COLORS.PRIMARY }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className="banner-image-text-container"
          >
            {/*<div>*/}
            <img
              className="banner-image"
              src={SubmitIcon}
              style={{
                minWidth: '112px',
                maxWidth: '112px',
                height: 'auto',
                marginBottom: '40px',
              }}
              alt="banner-image"
            />
            {/*</div>*/}
            <div className="banner-text-container">
              <div>
                <p
                  style={{
                    color: COLORS.BLACK,
                    marginBottom: '8px',
                    textAlign: 'center',
                  }}
                  className="banner-title"
                >
                  Request submitted. Someone will get back to you shortly!
                </p>
              </div>
            </div>
          </div>
          <div onClick={() => handleShowBanner(false)} className="close-button-container">
            <img
              style={{
                minWidth: '12px',
                maxWidth: '12px',
                minHeight: '12px',
                maxHeight: '12px',
              }}
              src={XButton}
              alt="close"
            />
          </div>
        </div>
      ) : bannerState === 'chosing' ? (
        // CHOSING STATE
        <div className="banner-container" style={{ backgroundColor: COLORS.PRIMARY }}>
          <div className="banner-image-text-container">
            <div style={{ marginRight: '32px' }} className="banner-image-container">
              <img className="banner-image" src={BannerImage} alt="banner-image" />
            </div>
            <div style={{ width: '100%' }} className="banner-text-container">
              <div style={{ marginBottom: '24px' }}>
                <p style={{ color: COLORS.BLACK }} className="banner-title">
                  What are your main areas of concern?
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                }}
              >
                {/*LEFT ITEMS*/}
                <div>
                  <div style={{ display: 'flex', marginBottom: '12px' }}>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) =>
                        handleCheckboxChange('Educational Resources Quality', e.target.checked)
                      }
                    >
                      <span
                        style={{
                          color: '#000000',
                          fontSize: '15px',
                          lineHeight: '16px',
                          fontWeight: 'bold',
                        }}
                        className="banner-description"
                      >
                        Educational Resources Quality
                      </span>
                    </Checkbox>
                  </div>

                  <div style={{ display: 'flex', marginBottom: '12px' }}>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) =>
                        handleCheckboxChange('Mentor Compatibility', e.target.checked)
                      }
                    >
                      <span
                        style={{
                          color: '#000000',
                          fontSize: '15px',
                          lineHeight: '16px',
                          fontWeight: 'bold',
                        }}
                        className="banner-description"
                      >
                        Mentor Compatibility{' '}
                      </span>
                    </Checkbox>
                  </div>
                  <div style={{ display: 'flex', marginBottom: '12px' }}>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) => handleCheckboxChange('Admission Results', e.target.checked)}
                    >
                      <span
                        style={{
                          color: '#000000',
                          fontSize: '15px',
                          lineHeight: '16px',
                          fontWeight: 'bold',
                        }}
                        className="banner-description"
                      >
                        Admission Results{' '}
                      </span>
                    </Checkbox>
                  </div>

                  <div style={{ display: 'flex', marginBottom: '12px' }}>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) => handleCheckboxChange('Customer Support', e.target.checked)}
                    >
                      <span
                        style={{
                          color: '#000000',
                          fontSize: '15px',
                          lineHeight: '16px',
                          fontWeight: 'bold',
                        }}
                        className="banner-description"
                      >
                        Customer Support
                      </span>
                    </Checkbox>
                  </div>
                  <div style={{ display: 'flex', marginBottom: '12px' }}>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) => handleCheckboxChange('Agora Usability', e.target.checked)}
                    >
                      <span
                        style={{
                          color: '#000000',
                          fontSize: '15px',
                          lineHeight: '16px',
                          fontWeight: 'bold',
                        }}
                        className="banner-description"
                      >
                        Agora Usability
                      </span>
                    </Checkbox>
                  </div>
                </div>
                <div style={{ width: windowWidth < 1124 ? '0px' : '140px' }}></div>
                {/*RIGHT ITEMS*/}
                <div>
                  <div style={{ display: 'flex', marginBottom: '12px' }}>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) =>
                        handleCheckboxChange('Learning Progress Overview', e.target.checked)
                      }
                    >
                      <span
                        style={{
                          color: '#000000',
                          fontSize: '15px',
                          lineHeight: '16px',
                          fontWeight: 'bold',
                        }}
                        className="banner-description"
                      >
                        Learning Progress Overview
                      </span>
                    </Checkbox>
                  </div>

                  <div style={{ display: 'flex', marginBottom: '12px' }}>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) => handleCheckboxChange('Billing', e.target.checked)}
                    >
                      <span
                        style={{
                          color: '#000000',
                          fontSize: '15px',
                          lineHeight: '16px',
                          fontWeight: 'bold',
                        }}
                        className="banner-description"
                      >
                        Billing
                      </span>
                    </Checkbox>
                  </div>
                  <div style={{ display: 'flex', marginBottom: '12px' }}>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) => handleCheckboxChange('Feedback Quality', e.target.checked)}
                    >
                      <span
                        style={{
                          color: '#000000',
                          fontSize: '15px',
                          lineHeight: '16px',
                          fontWeight: 'bold',
                        }}
                        className="banner-description"
                      >
                        Feedback Quality
                      </span>
                    </Checkbox>
                  </div>
                  <div style={{ display: 'flex', marginBottom: '12px' }}>
                    <Checkbox
                      className="custom-checkbox"
                      onChange={(e) => handleCheckboxChange('Course Structure', e.target.checked)}
                    >
                      <span
                        style={{
                          color: '#000000',
                          fontSize: '15px',
                          lineHeight: '16px',
                          fontWeight: 'bold',
                        }}
                        className="banner-description"
                      >
                        Course Structure
                      </span>
                    </Checkbox>
                  </div>
                </div>
              </div>
              <span
                style={{
                  color: '#000000',
                  fontSize: '15px',
                  lineHeight: '16px',
                  fontWeight: 'bold',
                }}
                className="banner-description"
              >
                Other:
              </span>
              <textarea
                value={otherConcerns}
                onChange={handleOtherConcerns}
                style={{
                  backgroundColor: 'transparent',
                  borderRadius: '6px',
                  color: '#000000',
                  marginTop: '12px',
                  borderWidth: '2px',
                }}
                className="border border-black placeholder-black custom-textarea"
                placeholder="Please let us know about any other areas of concern"
              />
              {selectedIssues.length > 0 || otherConcerns !== '' ? (
                <div className="get-in-touch-button-container">
                  <PrimaryButton
                    onClick={() => {
                      if (!isLoadingRegisterUser) {
                        registerIssue({
                          studentId: studentId,
                          issues: selectedIssues,
                          otherConcerns: otherConcerns,
                        });
                      }
                    }}
                    margin="0px"
                    width={windowWidth <= 999 ? '100%' : 'auto'}
                  >
                    {!isLoadingRegisterUser ? (
                      <span>Submit request</span>
                    ) : (
                      <span style={{ display: 'flex' }}>
                        <AgoraSpinner color={COLORS.PRIMARY} fontSize={16} />
                        <span style={{ marginLeft: '8px' }}>Submitting...</span>
                      </span>
                    )}
                  </PrimaryButton>
                </div>
              ) : (
                <div className="get-in-touch-button-container">
                  <PrimaryButton
                    disabled={true}
                    margin="0px"
                    width={windowWidth <= 999 ? '100%' : 'auto'}
                  >
                    Please select at least one
                  </PrimaryButton>
                </div>
              )}
            </div>
          </div>
          <div onClick={() => handleShowBanner(false)} className="close-button-container">
            <img
              style={{
                minWidth: '12px',
                maxWidth: '12px',
                minHeight: '12px',
                maxHeight: '12px',
              }}
              src={XButton}
              alt="close"
            />
          </div>
        </div>
      ) : (
        // DEFAULT STATE
        <div className="banner-container" style={{ backgroundColor: COLORS.PRIMARY }}>
          <div className="banner-image-text-container">
            <div className="banner-image-container">
              <img className="banner-image" src={BannerImage} alt="banner-image" />
            </div>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              className="banner-text-container"
            >
              <div style={{ marginBottom: '32px' }}>
                <p style={{ color: COLORS.BLACK, marginBottom: '8px' }} className="banner-title">
                  Got a question or slight concern? Don't hesitate to reach out.
                </p>
                <p style={{ color: COLORS.BLACK }} className="banner-description">
                  We’re committed to ensuring your experience with us is nothing short of excellent.
                  Let us help with even the smallest things.
                </p>
              </div>
              <div className="get-in-touch-button-container">
                <PrimaryButton
                  // onClick={() => registerIssue({})}
                  onClick={() => setBannerState('chosing')}
                  margin="0px"
                  width={windowWidth <= 999 ? '100%' : 'auto'}
                >
                  Get in touch
                </PrimaryButton>
              </div>
            </div>
          </div>
          <div onClick={() => handleShowBanner(false)} className="close-button-container">
            <img
              style={{
                minWidth: '12px',
                maxWidth: '12px',
                minHeight: '12px',
                maxHeight: '12px',
              }}
              src={XButton}
              alt="close"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NeedHelpParentBanner;
