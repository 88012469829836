import { RefObject, useEffect, useRef, useState } from 'react';

const useShowCanvasButtons = (containerRef: RefObject<HTMLDivElement>) => {
  const [showButtons, setShowButtons] = useState(false);

  const hideTimerRef = useRef<NodeJS.Timeout | null>(null);

  const clearHideTimer = () => {
    if (hideTimerRef.current) {
      clearTimeout(hideTimerRef.current);
      hideTimerRef.current = null;
    }
  };

  const startHideTimer = () => {
    clearHideTimer();
    hideTimerRef.current = setTimeout(() => {
      setShowButtons(false);
    }, 3000);
  };

  const handleMouseMove = () => {
    setShowButtons(true);
    startHideTimer();
  };

  const handleMouseLeave = () => {
    clearHideTimer();
    setShowButtons(false);
  };

  useEffect(() => {
    const containerEl = containerRef.current;

    if (!containerEl) return;

    containerEl.addEventListener('mousemove', handleMouseMove);
    containerEl.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      clearHideTimer();
      containerEl.removeEventListener('mousemove', handleMouseMove);
      containerEl.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return showButtons;
};

export default useShowCanvasButtons;
