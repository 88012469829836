import Ellipse1 from '@assets/images/ellipse-1.svg';
import Ellipse2 from '@assets/images/ellipse-2.svg';
import Ellipse3 from '@assets/images/ellipse-3.svg';
import Ellipse4 from '@assets/images/ellipse-4.svg';
import UpgradeLogoLong from '@assets/upgrade-long-logo.png';
import LanguageSwitcher from '@components/V3/LanguageSwitcher';
import { useCheckIfUserExists, useHarvestReferral } from '@actions';
import { Referral } from '@shared/types';
import style from '@styles/pages/referral.module.scss';
import { Checkbox, Input, Space, Radio } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from '../../../TranslationContext';
import { COLORS } from '../../../v3/global';
import './referral.css';
import Button from '@components/V4/Button';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import useToast from 'apps/agora/src/hooks/useToast';

const ReferralView = () => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { t } = useTranslation();

  enum EmailErrorTypes {
    'NONE' = 'none',
    'NOT_FILLED' = 'not_filled_in',
    'INVALID_EMAIL' = 'invalid_email',
  }

  const [email, setEmail] = useState('');
  const [referralFirstName, setReferralFirstName] = useState('');
  const [referralLastName, setReferralLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [tellUsMore, setTellUsMore] = useState('');
  const [yourName, setYourName] = useState('');
  const [yourEmail, setYourEmail] = useState('');
  const [contactType, setContactType] = useState(null);
  const [prizeValue, setPrizeValue] = useState(-1);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const [userCheckData, setUserCheckData] = useState({
    isEmailInputTouched: false,
    oldEmailValue: '',
  });

  const toast = useToast();

  const [emailError, setEmailError] = useState<EmailErrorTypes>(EmailErrorTypes.NONE);
  const [referralFirstNameError, setReferralFirstNameError] = useState(false);
  const [referralLastNameError, setReferralLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [yourNameError, setYourNameError] = useState(false);
  const [yourEmailError, setYourEmailError] = useState<EmailErrorTypes>(EmailErrorTypes.NONE);
  const [contactTypeError, setContactTypeError] = useState(false);
  const [prizeError, setPrizeError] = useState(false);

  const [agreeTermsError, setAgreeTermsError] = useState(false);

  // TODO Remove if no longer needed
  // const formData: HubSpotFormFields[] = [
  //   { name: 'persoana_care_a_facut_recomandarea', value: yourName },
  //   { name: 'firstname', value: referralFirstName },
  //   { name: 'lastname', value: referralLastName },
  //   { name: 'contact_type', value: contactType === 1 ? 'Parent' : 'Student' },
  //   { name: 'email', value: email },
  //   { name: 'phone', value: phone },
  //   {
  //     name: 'referral_reward',
  //     value:
  //       prizeValue === 1
  //         ? '50% discount on the future purchase of any course offered by the provider'
  //         : prizeValue === 2
  //         ? '5 additional sessions within an already purchased program'
  //         : '€200 eMAG voucher',
  //   },
  //   { name: 'tell_us_more', value: tellUsMore },
  // ];

  const formData: Referral = {
    persoana_care_a_facut_recomandarea: yourName,
    email_persoana_care_a_facut_recomandarea: yourEmail,
    firstname: referralFirstName,
    lastname: referralLastName,
    email: email,
    contact_type: contactType === 1 ? 'Parent' : 'Student',
    phone: phone,
    referral_reward: prizeValue,
    tell_us_more: tellUsMore,
  };

  const { mutate: harvestReferral, isLoading: isLoadingHarvestReferral } = useHarvestReferral({
    onSuccess: () => {
      toast.success('Thank you for your referral!');

      history.push('/thank-you');
    },
    onError: () => {
      toast.error('An error occurred. Please try again later.');
    },
  });

  const { data: doesUserExist, refetch: fetchUserExists } = useCheckIfUserExists(
    { email: yourEmail },
    {
      enabled: false,
      keepPreviousData: true,
      cacheTime: 0,
      onSuccess: (doesUserExist: boolean) => {
        if (!doesUserExist) {
          setPrizeValue(-1);
        }
      },
    }
  );

  const validateForm = () => {
    let hasErrors = false;

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$/;

    if (email === '') {
      setEmailError(EmailErrorTypes.NOT_FILLED);
      hasErrors = true;
    } else if (!email.match(emailRegex)) {
      setEmailError(EmailErrorTypes.INVALID_EMAIL);
    } else {
      setEmailError(EmailErrorTypes.NONE);
    }
    if (referralFirstName === '') {
      setReferralFirstNameError(true);
      hasErrors = true;
    } else {
      setReferralFirstNameError(false);
    }
    if (referralLastName === '') {
      setReferralLastNameError(true);
      hasErrors = true;
    } else {
      setReferralLastNameError(false);
    }
    if (phone === '') {
      setPhoneError(true);
      hasErrors = true;
    } else {
      setPhoneError(false);
    }
    if (yourName === '') {
      setYourNameError(true);
      hasErrors = true;
    } else {
      setYourNameError(false);
    }
    if (yourEmail === '') {
      setYourEmailError(EmailErrorTypes.NOT_FILLED);
      hasErrors = true;
    } else if (!yourEmail.match(emailRegex)) {
      setYourEmailError(EmailErrorTypes.INVALID_EMAIL);
    } else {
      setYourEmailError(EmailErrorTypes.NONE);
    }
    if (contactType === null) {
      setContactTypeError(true);
      hasErrors = true;
    } else {
      setContactTypeError(false);
    }
    if (prizeValue === -1) {
      setPrizeError(true);
      hasErrors = true;
    } else {
      setPrizeError(false);
    }
    if (!agreeTerms) {
      setAgreeTermsError(true);
      hasErrors = true;
    } else {
      setAgreeTermsError(false);
    }

    return hasErrors;
  };

  const submitHubSpotForm = async (formData: Referral) => {
    const hasErrors = validateForm();

    if (hasErrors) {
      return;
    } else {
      harvestReferral({
        harversterToken: id,
        fields: formData,
      });
    }
  };

  const emailBlurHandler = () => {
    if (yourEmail && yourEmail !== userCheckData.oldEmailValue) {
      fetchUserExists();
      setUserCheckData({ isEmailInputTouched: true, oldEmailValue: yourEmail });
    }
  };

  const areFirstTwoRewardsDisabled = userCheckData.isEmailInputTouched && doesUserExist === false;

  return (
    <div style={{ minHeight: '100vh' }} className={style.pageWithBackground}>
      <img
        src={Ellipse1}
        style={{
          width: '200px',
          height: '200px',
          position: 'absolute',
          top: '-50px',
          left: '-50px',
        }}
      />

      <img
        src={Ellipse2}
        style={{
          width: '200px',
          height: '200px',
          position: 'absolute',
          top: '-50px',
          right: '-50px',
        }}
      />

      <div style={{ justifyContent: 'space-around' }} className={style.centeredContent}>
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className={style.formContainer}
        >
          <img
            style={{ zIndex: '1', marginTop: '48px', width: 'fit-content' }}
            className="upgrade-logo"
            src={UpgradeLogoLong}
            alt="upgrade-logo"
          />

          <div className={style.languageSwitcherContainer}>
            <LanguageSwitcher />
          </div>

          <h1 style={{ zIndex: '1', color: COLORS.TEXT_PRIMARY }} className={style.title}>
            {t('earn_part1')}
            <span style={{ color: COLORS.GOLD }}>{t('monetary_sum')}</span> {t('earn_part2')}
          </h1>

          <p
            style={{ zIndex: '1', color: COLORS.TEXT_PRIMARY }}
            className={`${style.description} ${style.stepTwoDetailsContainer}`}
          >
            <span>{t('earn_part3')}</span>
          </p>
        </div>

        <div className={style.formContainer}>
          <div className={style.namePhoneContainer}>
            <div style={{ zIndex: '1' }} className={`${style.stepTwoLabel} ${style.nameContainer}`}>
              <span style={{ color: COLORS.TEXT_SECONDARY }}>
                {t('referral_first_name')} <span style={{ color: COLORS.ALERT_RED }}>*</span>
              </span>
              <br />
              {referralFirstNameError && (
                <span style={{ color: '#ff4d4f', fontSize: '12px' }}>
                  ({t('referral_first_name_required')})
                </span>
              )}
              <Input
                className={`${style.input} ${style.namePhoneReferralInput} ${
                  referralFirstNameError ? 'custom-ant-input-error' : 'custom-ant-input'
                }`}
                onChange={(e) => setReferralFirstName(e.target.value)}
              />
            </div>
            <div
              style={{ zIndex: '1' }}
              className={`${style.stepTwoLabel} ${style.phoneReferralContainer}`}
            >
              <span style={{ color: COLORS.TEXT_SECONDARY }}>
                {t('referral_last_name')} <span style={{ color: COLORS.ALERT_RED }}>*</span>
              </span>
              <br />
              {referralLastNameError && (
                <span style={{ color: '#ff4d4f', fontSize: '12px' }}>
                  ({t('referral_last_name_required')})
                </span>
              )}
              <Input
                className={`${style.input} ${style.namePhoneReferralInput} ${
                  referralLastNameError ? 'custom-ant-input-error' : 'custom-ant-input'
                }`}
                onChange={(e) => setReferralLastName(e.target.value)}
              />
            </div>
          </div>
          <div className={style.namePhoneContainer}>
            <div style={{ zIndex: '1' }} className={`${style.stepTwoLabel} ${style.nameContainer}`}>
              <span style={{ color: COLORS.TEXT_SECONDARY }}>
                {t('referral_email')} <span style={{ color: COLORS.ALERT_RED }}>*</span>
              </span>
              <br />
              {emailError === EmailErrorTypes.NOT_FILLED && (
                <span style={{ color: '#ff4d4f', fontSize: '12px' }}>
                  ({t('referral_email_required')})
                </span>
              )}
              {emailError === EmailErrorTypes.INVALID_EMAIL && (
                <span style={{ color: '#ff4d4f', fontSize: '12px' }}>
                  ({t('referral_email_invalid')})
                </span>
              )}
              <Input
                className={`${style.input} ${style.namePhoneReferralInput} ${
                  emailError !== EmailErrorTypes.NONE
                    ? 'custom-ant-input-error'
                    : 'custom-ant-input'
                }`}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              style={{ zIndex: '1' }}
              className={`${style.stepTwoLabel} ${style.phoneReferralContainer}`}
            >
              <span style={{ color: COLORS.TEXT_SECONDARY }}>
                {t('referral_phone')} <span style={{ color: COLORS.ALERT_RED }}>*</span>
              </span>
              <br />
              {phoneError && (
                <span style={{ color: '#ff4d4f', fontSize: '12px' }}>
                  ({t('referral_phone_required')})
                </span>
              )}
              <Input
                className={`${style.input} ${style.namePhoneReferralInput} ${
                  phoneError ? 'custom-ant-input-error' : 'custom-ant-input'
                }`}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>

          <div className={style.userNameEmailContainer}>
            <div
              style={{ zIndex: '1' }}
              className={`${style.stepTwoLabel} ${style.userNameContainer}`}
            >
              <span style={{ color: COLORS.TEXT_SECONDARY }}>
                {t('referral_your_name')} <span style={{ color: COLORS.ALERT_RED }}>*</span>
              </span>
              <br />
              {yourNameError && (
                <span style={{ color: '#ff4d4f', fontSize: '12px' }}>
                  ({t('referral_your_name_required')})
                </span>
              )}
              <Input
                className={`${style.input} ${style.userNameInput} ${style.stepTwoUserNameInput} ${
                  yourNameError ? 'custom-ant-input-error' : 'custom-ant-input'
                }`}
                onChange={(e) => setYourName(e.target.value)}
              />
            </div>
            <div
              style={{ zIndex: '1' }}
              className={`${style.stepTwoLabel} ${style.userEmailContainer}`}
            >
              <span style={{ color: COLORS.TEXT_SECONDARY }}>
                {t('referral_your_email')} <span style={{ color: COLORS.ALERT_RED }}>*</span>
              </span>
              <br />
              {yourEmailError === EmailErrorTypes.NOT_FILLED && (
                <span style={{ color: '#ff4d4f', fontSize: '12px' }}>
                  ({t('referral_email_required')})
                </span>
              )}
              {yourEmailError === EmailErrorTypes.INVALID_EMAIL && (
                <span style={{ color: '#ff4d4f', fontSize: '12px' }}>
                  ({t('referral_email_invalid')})
                </span>
              )}
              <Input
                className={`${style.input} ${style.namePhoneReferralInput} ${
                  yourEmailError !== EmailErrorTypes.NONE
                    ? 'custom-ant-input-error'
                    : 'custom-ant-input'
                }`}
                onChange={(e) => setYourEmail(e.target.value)}
                onBlur={emailBlurHandler}
              />
            </div>
          </div>
          <div>
            <div style={{ width: '100%' }} className={`${style.stepTwoLabel}`}>
              <span style={{ zIndex: '1', color: COLORS.TEXT_SECONDARY }}>
                {t('referral_tell_us_more')}
              </span>
              <Input
                style={{ zIndex: '1', marginBottom: '0px' }}
                className={`${style.input} ${style.namePhoneReferralInput} custom-ant-input`}
                onChange={(e) => setTellUsMore(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              color: COLORS.TEXT_SECONDARY,
              display: 'flex',
              flexDirection: 'column',
            }}
            className={`${style.stepTwoLabel} ${style.radioContainer}`}
          >
            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span style={{ zIndex: '1' }}>
                {t('referral_contact_type')} <span style={{ color: COLORS.ALERT_RED }}>*</span>
              </span>
              <br />
              {contactTypeError && (
                <span
                  style={{
                    color: '#ff4d4f',
                    fontSize: '12px',
                  }}
                >
                  ({t('referral_contact_type_required')})
                </span>
              )}
            </div>
            <Radio.Group
              style={{ zIndex: '1' }}
              onChange={(e) => {
                setContactType(e.target.value);
              }}
              value={contactType}
              defaultValue={contactType}
            >
              <Space direction="vertical">
                <Radio className="custom-ant-radio" value={1}>
                  <span
                    className={style.description}
                    style={{
                      marginLeft: '8px',
                      color: contactType === 1 ? 'rgb(215, 176, 55)' : COLORS.TEXT_SECONDARY,
                    }}
                  >
                    {t('referral_parent')}
                  </span>
                </Radio>
                <Radio className="custom-ant-radio" value={2}>
                  <span
                    className={style.description}
                    style={{
                      marginLeft: '8px',
                      color: contactType === 2 ? 'rgb(215, 176, 55)' : COLORS.TEXT_SECONDARY,
                    }}
                  >
                    {t('referral_student')}
                  </span>
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            className={`${style.stepTwoLabel} ${style.radioContainer}`}
          >
            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  zIndex: '1',
                }}
              >
                {t('referral_reward')} <span style={{ color: COLORS.ALERT_RED }}>*</span>
              </span>
              <br />
              {prizeError && (
                <span
                  style={{
                    color: '#ff4d4f',
                    fontSize: '12px',
                  }}
                >
                  ({t('referral_reward_required')})
                </span>
              )}
              {areFirstTwoRewardsDisabled && (
                <span className="mt-2 text-xs text-error2">
                  {t('user_does_not_exist_reward_message')}
                </span>
              )}
            </div>
            <Radio.Group
              style={{ zIndex: '1' }}
              onChange={(e) => {
                setPrizeValue(e.target.value);
              }}
              value={prizeValue}
              defaultValue={prizeValue}
            >
              <Space direction="vertical">
                <Radio className="custom-ant-radio" value={0} disabled={areFirstTwoRewardsDisabled}>
                  <span
                    className={mergeClassNames(style.description, 'ml-2', {
                      'text-customGrey': prizeValue !== 0,
                      'text-customGold': prizeValue === 0,
                      'text-white opacity-50': areFirstTwoRewardsDisabled,
                    })}
                  >
                    {t('referral_reward_val1')}
                  </span>
                </Radio>
                <Radio className="custom-ant-radio" value={1} disabled={areFirstTwoRewardsDisabled}>
                  <span
                    className={mergeClassNames(style.description, 'ml-2', {
                      'text-customGrey': prizeValue !== 1,
                      'text-customGold': prizeValue === 1,
                      'text-white opacity-50': areFirstTwoRewardsDisabled,
                    })}
                  >
                    {t('referral_reward_val2')}
                  </span>
                </Radio>
                <Radio className="custom-ant-radio" value={2}>
                  <span
                    className={style.description}
                    style={{
                      marginLeft: '8px',
                      color: prizeValue === 2 ? 'rgb(215, 176, 55)' : COLORS.TEXT_SECONDARY,
                    }}
                  >
                    {t('referral_reward_val3')}
                  </span>
                </Radio>
              </Space>
            </Radio.Group>
          </div>
        </div>

        <div
          style={{
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
          className={style.agreementContainer}
        >
          <div
            style={{
              width: '100%',
              maxWidth: '672px',
            }}
          >
            <div
              style={{
                zIndex: '1',
                display: 'flex',
                alignItems: 'center',
              }}
              className={style.agreementCheckboxContainer}
            >
              <Checkbox
                style={{
                  marginTop: '0px',
                  marginRight: '8px',
                }}
                value={agreeTerms}
                checked={agreeTerms}
                onChange={() => setAgreeTerms(!agreeTerms)}
                className="custom-ant-checkbox"
              />{' '}
              &nbsp;{' '}
              <span
                style={{ zIndex: '1', color: COLORS.TEXT_SECONDARY }}
                className={style.termsAndCondition}
              >
                {t('referral_agree_part1')}{' '}
                <Link
                  to={'/terms-and-conditions'}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#D7B037', cursor: 'pointer' }}
                >
                  {t('referral_agree_part2')}
                </Link>
              </span>
            </div>
            {agreeTermsError && (
              <span
                style={{
                  color: '#ff4d4f',
                  fontSize: '12px',
                  width: '100%',
                  zIndex: '1',
                }}
              >
                ({t('referral_agree_required')})
              </span>
            )}
          </div>

          <Button
            style={{
              marginBottom: '64px',
              zIndex: '1',
              borderRadius: '6px',
            }}
            className={style.button}
            onClick={() => {
              if (!isLoadingHarvestReferral) {
                submitHubSpotForm(formData);
              }
            }}
            buttonText={t('referral_refer_a_friend')}
            isLoading={isLoadingHarvestReferral}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '-200px',
        }}
      >
        <img
          src={Ellipse3}
          style={{
            width: '200px',
            height: '200px',
            marginLeft: '-50px',
          }}
        />
        <img
          src={Ellipse4}
          style={{
            width: '200px',
            height: '200px',
            marginRight: '-50px',
          }}
        />
      </div>
    </div>
  );
};

export default ReferralView;
