import Toast, { ToastProps } from '@components/V4/Toast';
import { render } from 'react-dom';

type ToastDuration = number | 'infinite';

const useToast = () => {
  const hide = (container: HTMLDivElement) => {
    render(<></>, container);
  };

  const show: (props: ToastProps, duration?: ToastDuration) => void = (props, duration = 5000) => {
    const individualToastContainer = document.createElement('div');
    let timeout: NodeJS.Timeout;

    const closeHandler = () => {
      hide(individualToastContainer);
      props.onClose?.();

      if (timeout) {
        clearTimeout(timeout);
      }
    };

    if (duration !== 'infinite') {
      timeout = setTimeout(() => {
        closeHandler();
      }, duration);
    }

    render(<Toast {...props} onClose={closeHandler} />, individualToastContainer);
  };

  const info = (message: string, title?: 'Info', duration?: ToastDuration) => {
    show({ variant: 'info', messageTitle: title, messageBody: message }, duration);
  };

  const success = (message: string, title?: 'Success', duration?: ToastDuration) => {
    show({ variant: 'success', messageTitle: title, messageBody: message }, duration);
  };

  const warning = (message: string, title?: 'Warning', duration?: ToastDuration) => {
    show({ variant: 'warning', messageTitle: title, messageBody: message }, duration ?? 'infinite');
  };

  const error = (message: string, title?: 'Error', duration?: ToastDuration) => {
    show({ variant: 'error', messageTitle: title, messageBody: message }, duration ?? 'infinite');
  };

  return { info, success, warning, error };
};

export default useToast;
