import { FLAG_TYPES } from '@shared/constants';
import { useSearchFlagsForLinearChart } from 'apps/agora/src/actions';
import { COLORS } from 'apps/agora/src/v3/global';
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

type Props = {
  search: any;
  isActive: any;
  flagType: any;
  startRange: any;
  endRange: any;
  studentId: any;
  mentorId: any;
  courseId: any;
};

const FlagsDistributionOverTime = ({
  search,
  isActive,
  flagType,
  startRange,
  endRange,
  studentId,
  mentorId,
  courseId,
}: Props) => {
  const { data: linearChartFlags, refetch: refetchLinearChartFlags } = useSearchFlagsForLinearChart(
    {
      search: search,
      isActive: isActive,
      flagType: flagType,
      startRange: startRange,
      endRange: endRange,
      courseId: courseId,
      studentId: studentId,
      mentorId: mentorId,
    }
  );

  const flagTypes =
    linearChartFlags && linearChartFlags[0]
      ? Object.keys(linearChartFlags[0]).filter((key) => key !== 'month' && key !== 'year')
      : [];

  const lines = flagTypes.map((key, index) => (
    <Line
      key={key}
      type="monotone"
      dataKey={key}
      stroke={COLORS.FLAG_COLORS[index % COLORS.FLAG_COLORS.length]}
      activeDot={{ r: 8 }}
      dot={{ r: 8 }}
    />
  ));

  interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string | number | Date;
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-customGrey w-[300px] py-4 px-2 border border-solid border-[#707070]">
          <p className="text-black mb-2 underline">{label}:</p>
          <div>
            {payload?.map((data) => (
              <div key={data.dataKey} className="flex">
                <div
                  style={{ backgroundColor: COLORS.FLAG_COLORS[data?.dataKey] }}
                  className="min-w-3 max-w-3 w-3 mr-2"
                />
                <p className="black mb-2">
                  {FLAG_TYPES[data?.dataKey]}: {data?.value ?? 'N/A'}
                </p>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <div className="w-full h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={500} height={300} data={linearChartFlags}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip content={CustomTooltip} />
            {lines}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default FlagsDistributionOverTime;
