import { ApiDescription, ApiData, RequestsMethod, Homework } from '../common';

export const createHomework: ApiDescription = {
  endpoint: '/homeworks',
  method: RequestsMethod.Post,
};
export type CreateHomework = ApiData<
  {
    title: string;
    description: string;
    student: string;
    meeting: string;
    enrollment: string;
  },
  Homework
>;
