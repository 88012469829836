import MultiPageModal from '@components/V4/Modal/MultiPageModal/MultiPageModal';
import { enrollmentsApis, meetingsApis } from '@shared/apis';
import { useGetAIGeneratedFeedback, useRegisterMentorFeedback } from '@actions';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { isObjectEmpty } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import EngagementPage from './EngagementPage/EngagementPage';
import FinalReviewPage, { type Feedback } from './FinalReviewPage/FinalReviewPage';
import HomeworkPage from './HomeworkPage/HomeworkPage';
import ParentHelpPage from './ParentHelpPage/ParentHelpPage';
import ProgressPage from './ProgressPage/ProgressPage';
import SessionSummaryPage from './SessionSummaryPage/SessionSummaryPage';
import useFeedbackModalForm from './useFeedbackModalForm';

export interface FeedbackModalProps extends BasicProps {
  title: string;
  feedback: Feedback;
  meetingId: string;
  setFeedback: Dispatch<SetStateAction<Feedback>>;
  onClose: () => void;
}

const FeedbackModal = (props: FeedbackModalProps) => {
  const { title, feedback, meetingId, setFeedback, onClose } = props;

  const toast = useToast();
  const reactQuery = useQueryClient();

  const { trackEvent } = useAnalytics();

  const { mutate: registerFeedback, isLoading: isRegisteringFeedback } = useRegisterMentorFeedback(
    meetingId,
    {
      onSuccess: () => {
        trackEvent(ANALYTICS_EVENT_NAMES.MEETING_GIVE_FEEDBACK);
        setFeedback({});
        reactQuery.invalidateQueries(meetingsApis.getMentorStudentMeetings.endpoint);
        reactQuery.invalidateQueries(enrollmentsApis.getSessionDetails.endpoint);
        reactQuery.invalidateQueries(enrollmentsApis.searchSessionHistory.endpoint);
        onClose();
      },
      onError: () => {
        toast.error("There was an error and your feedback couldn't be submitted");
      },
    }
  );

  const { data: aiGeneratedFeedback } = useGetAIGeneratedFeedback(meetingId);

  const [isFeedbackAIGenerated, setIsFeedbackAIGenerated] = useState({
    sessionSummary: true,
    homework: true,
    engagement: true,
    progress: true,
    parentHelp: true,
  });

  const {
    sessionSummaryFormData,
    homeworkFormData,
    engagementFormData,
    progressFormData,
    parentHelpFormData,
  } = useFeedbackModalForm({
    feedback,
  });

  useEffect(() => {
    if (aiGeneratedFeedback?.sessionSummary) {
      sessionSummaryFormData.setValue('aboutSession', aiGeneratedFeedback?.sessionSummary || '', {
        shouldValidate: true,
      });
    }

    if (aiGeneratedFeedback?.homework) {
      homeworkFormData.setValue('feedback', aiGeneratedFeedback?.homework.explanation || '', {
        shouldValidate: true,
      });
      homeworkFormData.setValue('rating', aiGeneratedFeedback?.homework.rate || 0, {
        shouldValidate: true,
      });
    }
    if (aiGeneratedFeedback?.engagement) {
      engagementFormData.setValue('feedback', aiGeneratedFeedback?.engagement.explanation || '', {
        shouldValidate: true,
      });
      engagementFormData.setValue('rating', aiGeneratedFeedback?.engagement.rate || 0, {
        shouldValidate: true,
      });
    }
    if (aiGeneratedFeedback?.progress) {
      progressFormData.setValue('feedback', aiGeneratedFeedback?.progress.explanation || '', {
        shouldValidate: true,
      });
      progressFormData.setValue('rating', aiGeneratedFeedback?.progress.rate || 0, {
        shouldValidate: true,
      });
    }
    if (aiGeneratedFeedback?.parentHelp) {
      parentHelpFormData.setValue('feedback', aiGeneratedFeedback?.parentHelp || '', {
        shouldValidate: true,
      });
    }
  }, [aiGeneratedFeedback]);

  const finalReviewFeedback = {
    sessionSummary: sessionSummaryFormData.getValues(),
    homework: homeworkFormData.getValues(),
    engagement: engagementFormData.getValues(),
    progress: progressFormData.getValues(),
    parentHelp: parentHelpFormData.getValues(),
  };

  const submitHandler = () => {
    registerFeedback({
      sessionSummary: finalReviewFeedback.sessionSummary.aboutSession,
      nextTopics: finalReviewFeedback.sessionSummary.nextTopics,
      engagement: {
        rate: finalReviewFeedback.engagement.rating,
        explanation: finalReviewFeedback.engagement.feedback,
      },
      progress: {
        rate: finalReviewFeedback.progress.rating,
        explanation: finalReviewFeedback.progress.feedback,
      },
      homework: {
        rate: finalReviewFeedback.homework.rating,
        explanation: finalReviewFeedback.homework.feedback,
      },
      parentHelp: finalReviewFeedback.parentHelp.feedback,
    });
  };

  const isSessionSummaryAIGeneratedToggleHandler = (isAIGenerated: boolean) => {
    setIsFeedbackAIGenerated((prevState) => ({
      ...prevState,
      sessionSummary: isAIGenerated,
    }));

    if (isAIGenerated) {
      sessionSummaryFormData.setValue('aboutSession', aiGeneratedFeedback?.sessionSummary || '', {
        shouldValidate: true,
      });
      return;
    }

    sessionSummaryFormData.setValue('aboutSession', feedback.sessionSummary?.aboutSession || '', {
      shouldValidate: true,
    });
    return;
  };

  const isHomeworkAIGeneratedToggleHandler = (isAIGenerated: boolean) => {
    setIsFeedbackAIGenerated((prevState) => ({
      ...prevState,
      homework: isAIGenerated,
    }));

    if (isAIGenerated) {
      homeworkFormData.setValue('feedback', aiGeneratedFeedback?.homework?.explanation || '', {
        shouldValidate: true,
      });
      homeworkFormData.setValue('rating', aiGeneratedFeedback?.homework?.rate || 0, {
        shouldValidate: true,
      });
      return;
    }

    homeworkFormData.setValue('feedback', feedback?.homework?.feedback || '', {
      shouldValidate: true,
    });
    homeworkFormData.setValue('rating', feedback?.homework?.rating || 0, {
      shouldValidate: true,
    });
    return;
  };

  const isEngagementAIGeneratedToggleHandler = (isAIGenerated: boolean) => {
    setIsFeedbackAIGenerated((prevState) => ({
      ...prevState,
      engagement: isAIGenerated,
    }));

    if (isAIGenerated) {
      engagementFormData.setValue('feedback', aiGeneratedFeedback?.engagement?.explanation || '', {
        shouldValidate: true,
      });
      engagementFormData.setValue('rating', aiGeneratedFeedback?.engagement?.rate || 0, {
        shouldValidate: true,
      });
      return;
    }

    engagementFormData.setValue('feedback', feedback?.engagement?.feedback || '', {
      shouldValidate: true,
    });
    engagementFormData.setValue('rating', feedback?.engagement?.rating || 0, {
      shouldValidate: true,
    });
    return;
  };

  const isProgressAIGeneratedToggleHandler = (isAIGenerated: boolean) => {
    setIsFeedbackAIGenerated((prevState) => ({
      ...prevState,
      progress: isAIGenerated,
    }));

    if (isAIGenerated) {
      progressFormData.setValue('feedback', aiGeneratedFeedback?.progress?.explanation || '', {
        shouldValidate: true,
      });
      progressFormData.setValue('rating', aiGeneratedFeedback?.progress?.rate || 0, {
        shouldValidate: true,
      });
      return;
    }

    progressFormData.setValue('feedback', feedback?.progress?.feedback || '', {
      shouldValidate: true,
    });
    progressFormData.setValue('rating', feedback?.progress?.rating || 0, {
      shouldValidate: true,
    });
    return;
  };

  const isParentHelpAIGeneratedToggleHandler = (isAIGenerated: boolean) => {
    setIsFeedbackAIGenerated((prevState) => ({
      ...prevState,
      parentHelp: isAIGenerated,
    }));

    if (isAIGenerated) {
      parentHelpFormData.setValue('feedback', aiGeneratedFeedback?.parentHelp || '', {
        shouldValidate: true,
      });

      return;
    }

    parentHelpFormData.setValue('feedback', feedback?.parentHelp?.feedback || '', {
      shouldValidate: true,
    });
    return;
  };

  const isAIFeedbackReady = useMemo(
    () => !isObjectEmpty(aiGeneratedFeedback),
    [aiGeneratedFeedback]
  );

  const pages = {
    sessionSummary: () => (
      <SessionSummaryPage
        didAIFeedbackGenerationFail={aiGeneratedFeedback?.generationFailed}
        isAIFeedbackReady={isAIFeedbackReady}
        isFeedbackAIGenerationEnabled={isFeedbackAIGenerated.sessionSummary}
        sessionSummaryFormData={sessionSummaryFormData}
        onNextClick={(sessionSummary) =>
          setFeedback((prev) => ({ ...prev, sessionSummary: sessionSummary }))
        }
        onIsFeedbackAIGenerationEnabledToggle={(isAIGenerated) =>
          isSessionSummaryAIGeneratedToggleHandler(isAIGenerated)
        }
      />
    ),
    homework: () => (
      <HomeworkPage
        didAIFeedbackGenerationFail={aiGeneratedFeedback?.generationFailed}
        isAIFeedbackReady={isAIFeedbackReady}
        isFeedbackAIGenerationEnabled={isFeedbackAIGenerated.homework}
        homeworkFormData={homeworkFormData}
        onNextClick={(homework) => setFeedback((prev) => ({ ...prev, homework: homework }))}
        onIsFeedbackAIGenerationEnabledToggle={(isAIGenerated) =>
          isHomeworkAIGeneratedToggleHandler(isAIGenerated)
        }
      />
    ),
    engagement: () => (
      <EngagementPage
        didAIFeedbackGenerationFail={aiGeneratedFeedback?.generationFailed}
        isAIFeedbackReady={isAIFeedbackReady}
        isFeedbackAIGenerationEnabled={isFeedbackAIGenerated.engagement}
        engagementFormData={engagementFormData}
        onNextClick={(engagement) => setFeedback((prev) => ({ ...prev, engagement: engagement }))}
        onIsFeedbackAIGenerationEnabledToggle={(isAIGenerated) =>
          isEngagementAIGeneratedToggleHandler(isAIGenerated)
        }
      />
    ),
    progress: () => (
      <ProgressPage
        didAIFeedbackGenerationFail={aiGeneratedFeedback?.generationFailed}
        isAIFeedbackReady={isAIFeedbackReady}
        isFeedbackAIGenerationEnabled={isFeedbackAIGenerated.progress}
        progressFormData={progressFormData}
        onNextClick={(progress) => setFeedback((prev) => ({ ...prev, progress: progress }))}
        onIsFeedbackAIGenerationEnabledToggle={(isAIGenerated) =>
          isProgressAIGeneratedToggleHandler(isAIGenerated)
        }
      />
    ),
    parentHelp: () => (
      <ParentHelpPage
        didAIFeedbackGenerationFail={aiGeneratedFeedback?.generationFailed}
        isAIFeedbackReady={isAIFeedbackReady}
        isFeedbackAIGenerationEnabled={isFeedbackAIGenerated.parentHelp}
        parentHelpFormData={parentHelpFormData}
        onNextClick={(parentHelp) => setFeedback((prev) => ({ ...prev, parentHelp: parentHelp }))}
        onIsFeedbackAIGenerationEnabledToggle={(isAIGenerated) =>
          isParentHelpAIGeneratedToggleHandler(isAIGenerated)
        }
      />
    ),
    finalReview: () => (
      <FinalReviewPage
        feedback={finalReviewFeedback}
        isSubmitting={isRegisteringFeedback}
        onSubmit={submitHandler}
      />
    ),
  };

  const sessionSummaryErrors = sessionSummaryFormData.formState.errors;
  const homeworkErrors = homeworkFormData.formState.errors;
  const engagementErrors = engagementFormData.formState.errors;
  const progressErrors = progressFormData.formState.errors;
  const parentHelpErrors = parentHelpFormData.formState.errors;

  const navigation = {
    sessionSummary: {
      label: 'Session Summary',
      isCompleted: !sessionSummaryErrors.nextTopics && !sessionSummaryErrors.aboutSession,
    },
    homework: {
      label: 'Homework',
      isCompleted: !homeworkErrors.rating && !homeworkErrors.feedback,
    },
    engagement: {
      label: 'Engagement',
      isCompleted: !engagementErrors.rating && !engagementErrors.feedback,
    },
    progress: {
      label: 'Progress',
      isCompleted: !progressErrors.rating && !progressErrors.feedback,
    },
    parentHelp: {
      label: 'Parent Help',
      isCompleted: !parentHelpErrors.feedback,
    },
    finalReview: { label: 'Final Review', isCompleted: false },
  };

  const closeClickHandler = () => {
    onClose();
    trackEvent(ANALYTICS_EVENT_NAMES.CLOSE_FEEDBACK_MODAL);
  };

  return (
    <MultiPageModal
      shouldCloseOnBackdropClick={false}
      size="large"
      className="h-[640px]"
      bodyWrapperClassName="mt-8 tablet:px-6"
      isOpen={true}
      title={title}
      initialPage="sessionSummary"
      navigation={navigation}
      pages={pages}
      onClose={closeClickHandler}
    />
  );
};

export { Feedback };
export default FeedbackModal;
