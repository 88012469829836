import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import BaseProvider from './BaseProvider';
import {
  setGetAccessTokenSilently,
  setLogout,
} from '@contexts/AuthContext/auth';
import { Environment } from '@shared/common';
import { EnvironmentProvider } from '../contexts/EnvironmentContext';
import ReactQueryProvider from '../contexts/ReactQueryProvider';

type Props = {
  environment: Environment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

const WebProvider = ({ children, environment }: Props) => {
  const { isLoading: isLoadingAuth, getAccessTokenSilently, logout } = useAuth0();

  const isDevelopment = environment === 'development';

  useEffect(() => {
    setGetAccessTokenSilently(getAccessTokenSilently);
    setLogout(logout);
  }, [getAccessTokenSilently, logout]);

  return (
    <EnvironmentProvider environment={environment} device="web">
      <ReactQueryProvider showWebDevTools={isDevelopment}>
        {!isLoadingAuth && <BaseProvider>{children}</BaseProvider>}
      </ReactQueryProvider>
    </EnvironmentProvider>
  );
};

export default WebProvider;
