import Button from '@components/V4/Button';
import TextArea from '@components/V4/Inputs/Textarea';
import { useGetMeetingNotes, useUpdateMeetingNotes } from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

interface NotesPageProps {
  notes?: string;
}

const NotesPage = (props: NotesPageProps) => {
  const { meetingId } = useParams<{ meetingId: string }>();
  const [localNotes, setLocalNotes] = useState('');

  const localNotesRef = useRef('');

  const toast = useToast();

  const { data: notes, isLoading: isFetchNotesLoading } = useGetMeetingNotes(meetingId, {
    onError: (error: any) => {
      toast.error(error.response.data.error.message);
    },
  });
  const { mutate: updateMeetingNotes, isLoading: isUpdateNotesLoading } = useUpdateMeetingNotes(
    meetingId,
    {
      onError: (error: any) => {
        toast.error(error.response.data.error.message);
      },
    }
  );

  useEffect(() => {
    if (notes) {
      setLocalNotes(notes);
      localNotesRef.current = notes;
    }
  }, [notes]);

  useEffect(() => {
    return () => updateMeetingNotes({ notes: localNotesRef.current });
  }, []);

  const notesChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setLocalNotes(e.target.value);
    localNotesRef.current = e.target.value;
  };

  return (
    <div className="relative flex flex-auto flex-col justify-between gap-2 text-white">
      <div className="p-2 bg-surfaceHover rounded-xl">
        <p className="text-xxs font-semibold">
          Notes taken during the meeting will stay available for participants to view after the
          meeting ends.
        </p>
      </div>
      <TextArea
        value={localNotes}
        onChange={notesChangeHandler}
        className="bg-surfaceBackground h-full max-h-full resize-none border-customGrey"
        wrapperClassName="flex flex-col h-full"
        name="notes"
        id="notes"
        label="Notes"
      />
      <Button
        size="large"
        disabled={isFetchNotesLoading || isUpdateNotesLoading || localNotes === notes}
        isLoading={isUpdateNotesLoading}
        buttonText="Update Notes"
        onClick={() => {
          updateMeetingNotes({ notes: localNotes });
        }}
      />
    </div>
  );
};

export default NotesPage;
