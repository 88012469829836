import { useContext, useEffect, useState } from 'react';
import { ZoomContext } from '../contexts/ZoomContext';

const useCanvasAspectRatio = () => {
  const { zoomClient } = useContext(ZoomContext);

  const [ratio, setRatio] = useState('16 / 9');

  useEffect(() => {
    const shareContentDimensionChangeHandler = (payload: {
      type: 'sended' | 'received';
      width: number;
      height: number;
    }) => {
      const ratio = payload.width / payload.height;

      setRatio(ratio.toFixed(4));
    };
    zoomClient?.on('share-content-dimension-change', shareContentDimensionChangeHandler);

    return () =>
      zoomClient?.off('share-content-dimension-change', shareContentDimensionChangeHandler);
  }, []);

  return ratio;
};

export default useCanvasAspectRatio;
