import { npsApis, parentApis } from '@shared/apis';
import { genericMutation, genericQueryWithId, genericQueryWithParams } from './generics';

export const useStudentData = genericQueryWithParams<parentApis.GetStudentData>(
  parentApis.getStudentData
);

export const useCheckParentPendingNPS = genericQueryWithId<npsApis.CheckParentPendingNPS>(
  npsApis.checkParentPendingNPS
);

export const useSubmitParentNPS = genericMutation<npsApis.SubmitParentNPS>(npsApis.submitParentNPS);

export const useReportParentIssue = genericMutation<parentApis.ReportParentIssue>(
  parentApis.reportParentIssue
);
