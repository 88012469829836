import * as ws from 'ws';
import {
  ActivityType,
  EdjData,
  EmploymentType,
  ExperienceType,
  MeetingStatus,
  MentoringStyleType,
  RoleType,
  ServiceType,
  SessionStatus,
} from '../common';

export type CompletedLesson = {
  _id: string;
  course: string;
  user: string;
  lesson: string;
  createdAt: string;
  updatedAt: string;
};

export type MentorCapacity = {
  updatedAt: string;
  capacity: number;
};

export type MentorData = {
  status: string;
  lastName: string;
  firstName: string;
  description: string;
  image: string;
  welcomeMessage: string;
  universities: string[];
  maximumActiveEnrollments: number;
  available: boolean;
  averageFeedback: number;
  averageFeedbackHistory: number[];
  majors: string[];
  weeklyHours: number;
  biography: string;
  shortBiography: string;
  coursesCanTeach: {
    _id: string;
    order?: number;
  }[];
  recruitedFrom: string;
  reliability: number;
  availability: {
    isAvailable: boolean;
    intervals: number[][];
    _id: string;
  }[];
  mentorCapacity?: MentorCapacity;
  academicExperiences?: {
    label: string;
    title: string;
    fieldOfStudy: string;
  }[];
  mostRelevantJob?: string;
  rate?: Rate;

  company?: {
    model: string;
    name: string;
    address: string;
    CUI: string;
    IBAN: string;
    country: string;
  };
};

export type UseMentorDataType = {
  fullName: string;
  pronouns: string;
  avatar: string;
} & MentorData;

export type ParentData = {
  fullName: string;
  phoneNumber: string;
  email: string;
};

export type DriveStructure = {
  student?: {
    id: string;
    parentId: string;
    absolutePath: {
      id: string;
      name: string;
    };
  };
  mentor?: {
    id: string;
    parentId: string;
    absolutePath: {
      id: string;
      name: string;
    };
  };
};

export type LanguageExam = {
  _id: string;
  date: string;
  exam: string;
  targetGrade: string;
  prevGrade: string;
  areasOfImprovement: string;
};

export type EducationalBackground = {
  _id?: string;
  educationalInstitution: EducationalInstitution;
  major?: Major;
  graduationYear?: number;
  gpa?: number;
  degreeType?: string;
  startDate?: string;
  endDate?: string;
};

export type AppliedUniversity = {
  _id?: string;
  university: EducationalInstitution;
  major: Major;
  degree: string;
  pros: string;
  cons: string;
  status: string;
  applicationDeadline: string;
  academicRequirements: string;
  order?: number;
};

export type User = {
  _id: string;
  username: string;
  password: string;
  email: string;
  roles: RoleType[];
  countries: string[];
  interests: string[];
  mentoringStyle: MentoringStyleType;
  isOnboarded: boolean;
  isMarkedInactive?: boolean;
  isDezactivated?: boolean;
  deactivationReason?: string;
  isTestUser?: boolean;

  authId?: string;
  lmsId?: string;
  skipLmsIntegration?: boolean;
  courseCreatorCourses: string[];

  harvesterToken?: string;

  contactOwner: string;
  ecl: string;
  gpa: number;
  standardisedTests: string[];
  admissionExamPrep: string[];
  subjectsStudentLikes: string[];
  subjectsStudentDoesntLike: string[];
  learningStyle: string[];

  registrationToken: string;
  parentToken?: string;

  // optional
  country: string;
  city: string;
  graduationYear: number;
  studentType?: string;
  gender: string;
  pronouns: string;
  highSchool: string;
  highSchoolExamn?: {
    title: string;
    grade: number;
  };
  grades?: {
    label: string;
    title: string;
    grade: number;
  }[];
  educationalBackground?: EducationalBackground[];
  experiences?: Experience[];
  appliedUniversities?: AppliedUniversity[];
  languageExams?: LanguageExam[];

  activities?: string[];

  // for 1:1 mentoring
  fullName: string;
  phoneNumber: string;
  parentData: ParentData;

  avatar: string;
  studySubjects: string[];

  // for mentors
  mentorData: MentorData;

  notes: string;
  specificNotes: string[];

  folderId?: string;
  googleDrive: GoogleDrive;

  createdAt: string;
  updatedAt: string;

  isAdmin?: () => boolean;
  isMentor?: () => boolean;

  //settings
  virtualBackgrounds?: string[];
  preferredLanguage: string;
  timezone: string;
};

export type GoogleDrive = {
  CI?: DriveStructure;
  staging?: DriveStructure;
  production?: DriveStructure;
  development?: DriveStructure;
};

export type MyAccountInfo = {
  _id?: string;
  username: string;
  pronouns: string;
  isDezactivated?: boolean;
  isOnboarded: boolean;
  isTestUser?: boolean;
  harvesterToken?: string;
  email: string;
  fullName: string;
  avatar: string;
  roles: RoleType[];
  countries: string[];
  interests: string[];
  studySubjects: string[];
  country: string;
  city: string;
  highSchool: string;
  graduationYear: number;
  gender: string;
  phoneNumber?: string;
  parentData?: { email: string; fullName: string; phoneNumber: string };
  folderId?: string;
  googleDrive?: any;
  rate?: {
    amount?: number;
    currency?: string;
    vatPayer?: boolean;
  };
};

export type Resource = {
  text: string;
  url: string;
  description: string;
};

export type CourseModule = {
  _id: string;
  title: string;
  subtitle: string;
  isSkippable: boolean;
  resources: Resource[];
  lessons: {
    refType: 'Lesson' | 'Quiz';
    _id: string;
    title: string;
  }[];
};

export type Course = {
  _id: string;
  title: string;
  subtitle: string;
  abbreviation?: string;
  lmsCourseId?: string;
  lmsId?: string;
  lmsCoursePrice?: number;
  numberOfLessons: number;
  modules: CourseModule[];
  hideNonModuleLessons: boolean;
  isFree: boolean;
  isMentorless: boolean;
  isPublic: boolean;
  price: number;
  details: string;
  country: string;
  headerImage: {
    original: string;
    '160x160': string;
    '470x320': string;
  };
  youtubeId: string;
  color: string;
  mentors: string[];
  isDeleted?: boolean;
  deletedInfo?: {
    by: string;
    at: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type Session = {
  _id?: string;
  statuses: {
    value: SessionStatus;
    updatedOn: string;
  }[];
  name: string;
  meetings: string[];
  homework?: {
    _id: string;
    fileId: string;
  };
};

export type Enrollment = {
  _id: string;
  course: {
    _id: string;
    title: string;
    abbreviation?: string;
    color: string;
    headerImage?: any;
    lmsCourseId?: string;
    isMentorless?: boolean;
  };
  user: Student;
  mentor: {
    _id: string;
    fullName: string;
    addedOn?: string;
  };

  startDate: string;
  estimatedEndDate: string;

  status?: string;

  sessions: Session[];

  subject?: string;
  findBy?: string;

  ended?: boolean;

  createdAt: string;
  updatedAt: string;

  hasAtLeastOneMeeting: () => boolean;
  markSessionAsCompleted: (sessionIndex: number) => void;
  noOfSessions: number;
  noOfCompletedSessions: number;
  noOfScheduledOrInProgressSessions: number;
};

export type MentorWithRate = {
  mentor: Omit<User, 'educationalBackground'> & {
    educationalBackground: {
      educationalInstitution: string;
      major: string;
    }[];
  };
  currentCapacity: number;
  rate?: Rate;
};

export type EnrollmentDetail = {
  enrollment: EnrollmentCourse;
  noOfSessions: number;
  noOfCompletesSessions: number;
  lastSession: { updatedOn: string; id: string };
};

export type EnrollmentCourse = {
  _id: string;
  course: Partial<Course>;
  user: Partial<Student>;
  mentor: {
    _id: string;
    fullName: string;
    addedOn?: string;
  };

  startDate: string;
  estimatedEndDate: string;

  sessions: Session[];

  subject?: string;
  findBy?: string;

  ended?: boolean;

  createdAt: string;
  updatedAt: string;
};

export type ModuleStatus = {
  title: string;
  allLessons: Lesson[];
  finishedLessons: Lesson[];
  resources: Resource[];
};

export type PaymentItem = {
  quantity: number;
  description: string;
  price: number;
  taxRate: number;
};

export type Payment = {
  _id: string;
  title: string;
  user: string;
  status: string;
  total: number;
  invoiceId: string;
  paymentLink: string;
  mollieId: string;
  createdAt: string;
  updatedAt: string;
  items: PaymentItem[];
};

export type MentorSelectionCriteria = {
  interests?: string[];
  mentoringStyle?: MentoringStyleType;
  coursesCanTeach?: string;
  countries?: string[];
};

export type ErrorLog = {
  _id: string;
  error: string;
  userAgent: string;
  location: string;
  user: string;
};

export type Feedback = {
  _id: string;
  from: string;
  to: string;
  context: {
    course: string;
    meeting: string;
  };
  message: string;
  rating: number;
  createdAt: string;
  updatedAt: string;
};

export type ModuleFeedback = {
  _id: string;
  from: string;
  context: {
    course: string;
    moduleIndex: number;
  };
  rating: number;
  support?: number;
  createdAt: string;
  updatedAt: string;
};

export type NPSObject = {
  _id: string;
  from: any;
  userRole: string;
  rating: number;
  platformRating?: number;
  reasons?: NPSReasons;
  createdAt: string;
  updatedAt: string;
};

export type NpsStatsType = {
  userRates?: {
    detractorsRates: {
      userRateCurrentMonth: UserPopulatedNPS;
      userRateLastMonth: UserPopulatedNPS;
    }[];
    passivesRates: {
      userRateCurrentMonth: UserPopulatedNPS;
      userRateLastMonth: UserPopulatedNPS;
    }[];
    promotersRates: {
      userRateCurrentMonth: UserPopulatedNPS;
      userRateLastMonth: UserPopulatedNPS;
    }[];
  };
  totalNps: number;
  responsesNumber: number;
  detractors: {
    percentage: number;
    users: Partial<User>[];
  };
  passives: {
    percentage: number;
    users: Partial<User>[];
  };
  promoters: {
    percentage: number;
    users: Partial<User>[];
  };
  problems: NPSProblems;
};

export type NpsTrendPoint = {
  name: string;
  y: number;
};

export type GetNpsDiagramDataReturnType = {
  trendPoints: NpsTrendPoint[];
};

export type UserPopulatedNPS = {
  _id: string;
  from: any;
  userRole: string;
  rating: number;
  platformRating?: number | undefined;
  reasons?: NPSReasons;
  createdAt: string;
  updatedAt: string;
};

export type ComputedNPS = {
  _id: string;
  userRole: string;
  ratingField: string;
  month: number;
  year: number;
  totalNps: number;
  responsesNumber: number;
  detractors: {
    percentage: number;
    users: Partial<User>[];
  };
  passives: {
    percentage: number;
    users: Partial<User>[];
  };
  promoters: {
    percentage: number;
    users: Partial<User>[];
  };
  userRates: {
    detractorsRates: {
      userRateCurrentMonth: UserPopulatedNPS;
      userRateLastMonth: UserPopulatedNPS;
    }[];
    passivesRates: {
      userRateCurrentMonth: UserPopulatedNPS;
      userRateLastMonth: UserPopulatedNPS;
    }[];
    promotersRates: {
      userRateCurrentMonth: UserPopulatedNPS;
      userRateLastMonth: UserPopulatedNPS;
    }[];
  };
  problems: NPSProblems;
};

export type NPSProblems = {
  education: {
    mentorManagementSupport: number;
    educationalResourcesQuality: number;
    mentorCompatibility: number;
    admissionResults: number;
    customerSupport: number;
    learningProgressOverview: number;
    feedbackQuality: number;
    courseStructure: number;
    billing: number;
    qualityOfCourseResources: number;
    usabilityOfCourseResources: number;
    compensationBenefits: number;
    trainingOnboarding: number;
    qualityOfStudents: number;
    schedulingFlexibility: number;
    numberOfStudentAllocations: number;
    other: number;
  };
  platform: {
    userInterfaceExperience: number;
    performance: number;
    functionality: number;
    compatibilityAccessibility: number;
    documentationSupport: number;
    securityPrivacy: number;
    trainingOnboarding: number;
    customizationPersonalization: number;
    other: number;
  };
};

// education reasons

export enum MentorManagementSupport {
  en = 'Mentor Management Support',
}

export enum EducationalResourcesQuality {
  en = 'Educational Resources Quality',
}

export enum MentorCompatibility {
  en = 'Mentor Compatibility',
}

export enum AdmissionResults {
  en = 'Admission Results',
}

export enum CustomerSupport {
  en = 'Customer Support',
}

export enum LearningProgressOverview {
  en = 'Learning Progress Overview',
}

export enum FeedbackQuality {
  en = 'Feedback Quality',
}

export enum CourseStructure {
  en = 'Course Structure',
}

export enum Billing {
  en = 'Billing',
}

export enum QualityOfCourseResources {
  en = 'Quality of Course Resources',
}

export enum UsabilityOfCourseResources {
  en = 'Usability of Course Resources',
}

export enum CompensationBenefits {
  en = 'Compensation Benefits',
}

export enum TrainingOnboarding {
  en = 'Training Onboarding',
}

export enum QualityOfStudents {
  en = 'Quality of Students',
}

export enum SchedulingFlexibility {
  en = 'Scheduling Flexibility',
}

export enum NumberOfStudentAllocations {
  en = 'Number of Student Allocations',
}

export enum OtherEducation {
  en = 'Other',
}

// platform

export enum UserInterfaceExperience {
  en = 'User Interface Experience',
}

export enum Performance {
  en = 'Performance',
}

export enum Functionality {
  en = 'Functionality',
}

export enum CompatibilityAccessibility {
  en = 'Compatibility Accessibility',
}

export enum DocumentationSupport {
  en = 'Documentation Support',
}

export enum SecurityPrivacy {
  en = 'Security Privacy',
}

export enum TrainingOnboardingPlatform {
  en = 'Training Onboarding',
}

export enum CustomizationPersonalization {
  en = 'Customization or Personalization',
}

export enum OtherPlatform {
  en = 'Other',
}

export enum enumEducationObject {
  MentorManagementSupport,
  EducationalResourcesQuality,
  MentorCompatibility,
  AdmissionResults,
  CustomerSupport,
  LearningProgressOverview,
  FeedbackQuality,
  CourseStructure,
  Billing,
  QualityOfCourseResources,
  UsabilityOfCourseResources,
  CompensationBenefits,
  TrainingOnboarding,
  QualityOfStudents,
  SchedulingFlexibility,
  NumberOfStudentAllocations,
  OtherEducation,
}

export enum enumPlatformObject {
  UserInterfaceExperience,
  Performance,
  Functionality,
  CompatibilityAccessibility,
  DocumentationSupport,
  SecurityPrivacy,
  TrainingOnboardingPlatform,
  CustomizationPersonalization,
  OtherPlatform,
}

export const NpsStringKeys = {
  en: {
    MentorManagementSupport: 'Mentor Management Support',
    EducationalResourcesQuality: 'Educational Resources Quality',
    MentorCompatibility: 'Mentor Compatibility',
    AdmissionResults: 'Admission Results',
    CustomerSupport: 'Customer Support',
    LearningProgressOverview: 'Learning Progress Overview',
    FeedbackQuality: 'Feedback Quality',
    CourseStructure: 'Course Structure',
    Billing: 'Billing',
    QualityOfCourseResources: 'Quality of Course Resources',
    UsabilityOfCourseResources: 'Usability of Course Resources',
    CompensationBenefits: 'Compensation Benefits',
    TrainingOnboarding: 'Training Onboarding',
    QualityOfStudents: 'Quality of Students',
    SchedulingFlexibility: 'Scheduling Flexibility',
    NumberOfStudentAllocations: 'Number of Student Allocations',
    OtherEducation: 'Other',

    UserInterfaceExperience: 'User Interface Experience',
    Performance: 'Performance',
    Functionality: 'Functionality',
    CompatibilityAccessibility: 'Compatibility Accessibility',
    DocumentationSupport: 'Documentation Support',
    SecurityPrivacy: 'Security Privacy',
    CustomizationPersonalization: 'Customization or Personalization',
    OtherPlatform: 'Other',
  },
};

export type NPSReasons = {
  education: string[];
  platform: string[];
  otherEducation: string;
  otherPlatform: string;
};

export type Lesson = {
  _id: string;
  title: string;
  parentCourse: string;
  content: EdjData;
  contentType: string;
  isFree: boolean;
  createdAt: string;
  updatedAt: string;
};

export type Student = {
  _id: string;
  fullName: string;
  phoneNumber?: string;
  graduationYear?: number;
  pronouns?: string;
  studySubjects?: string[];
  countries?: string[];
  isDezactivated?: boolean;
  attendance?: Attendance;
  notes?: string;
  avatar?: string;
};

export type MentorFeedback = {
  sessionSummary: string;
  homework: {
    explanation: string;
    rate: number;
  };
  engagement: {
    explanation: string;
    rate: number;
  };
  progress: {
    explanation: string;
    rate: number;
  };
  nextTopics: string;
  parentHelp: string;
  createdAt?: string;
};

export type Attendance = {
  responseStatus: number;
  status?: {
    isWaitingRoom: boolean;
    joinTime: string;
    leaveTime: string;
    duration: number;
  }[];
};

export type StudentFeedback = {
  message: string;
  rating: number;
};

export type Meeting = {
  _id: string;
  name: string;
  currentSession?: number;
  totalSessions?: number;
  enrollment: {
    _id: string;
    color: string;
    title: string;
    abbreviation?: string;
    courseId: string;
    sessions?: any[];
    course?: any;
    user?: any;
  };
  mentor: {
    _id: string;
    fullName: string;
    attendance?: Attendance;
    notes?: string;
  };
  students: Student[];

  status: MeetingStatus;

  startDate: string;
  endTime?: string;
  startTime?: string;
  duration?: number;
  billableTime?: number;

  ended: boolean;

  attended: {
    mentor: boolean;
    student: boolean;
  };

  calendarEventId?: string;

  transcript?: {
    text: string;
    user: string;
    startTime: string;
    endTime: string;
  }[];

  chat?: {
    message: {
      text: string;
      at: string;
    };
    user: string;
  }[];

  feedback: StudentFeedback[];
  endMeetingReason?: string;
  mentorFeedback: MentorFeedback;

  sessionStatus?: number;

  agenda?: string;
  sessionKey?: string;

  createdAt: string;
  updatedAt: string;
};

export type Page = {
  _id: string;
  title: string;
  subtitle: string;
  body: string;
  isFree: boolean;
  country: string;
  topic: string;
  createdAt: string;
  updatedAt: string;
};

export type KnowledgeBaseArticle = {
  _id?: string;
  title: string;
  keywords: Array<string>;
  body: EdjData;
  createdAt?: string;
  updatedAt?: string;
  likes?: number;
  dislikes?: number;
  lastUpdate?: string;
};

export type Question = {
  _id: string;
  text: string;
  imageUrl?: string;
  answers: {
    _id: string;
    text: string;
  }[];
  explanation?: string;
};

export type Quiz = {
  _id: string;
  title: string;
  isGraded: boolean;
  parentCourse: string;
  questions: Question[];
  createdAt: string;
  updatedAt: string;
};

export type QuizAttempt = {
  _id: string;
  quiz: string;
  user: string;
  results: {
    answer: string;
    question: string;
    correct: boolean;
  }[];
  passed: boolean;
  rank: number;
  createdAt: string;
  updatedAt: string;
};

export type Takeaway = {
  _id: string;
  question: string;
  answer: string;
  lesson: string;
  course: string;
};

export type PopulatedTakeaway = Takeaway & {
  course: { _id: string; title: string; color: string };
  lesson: { _id: string; title: string };
};

export type Homework = {
  _id: string;
  title: string;
  description?: string;
  student: string;
  mentor: string;
  enrollment: {
    _id: string;
    courseId: string;
    sessionIndex: number;
  };
  fileId: string;
  completed: boolean;
  createdAt: string;
  updatedAt: string;
};

export type MeetingWithHomework = Meeting & {
  homework: Homework;
  sessionId: string;
};

export type VerificationToken = {
  _id: string;
  type: string;
  email: string;
  token: string;
  user: string;
};

export enum RequestsMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Patch = 'patch',
  Delete = 'delete',
  Ws = 'ws',
}

export type ApiDescription = {
  endpoint: string;
  method: RequestsMethod;
};
export type ApiData<Payload, Response> = {
  payload: Payload;
  response: Response;
};

export type Environment = 'development' | 'CI' | 'staging' | 'production';
export type Device = 'web' | 'mobile';

export type RawObject<T> = Omit<T, '_id' | 'createdAt' | 'updatedAt'>;
export type Modify<T, R> = Omit<T, keyof R> & R;

export type CloseModalProps = {
  visible: boolean;
  hide: () => void;
};

export type ToggleModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export type ExternalUserData = {
  fullName: string;
  city: string;
  graduationYear: number;
  highSchool: string;
  parentEmail: string;
  parentFullName: string;
  parentPhoneNumber: string;
  phoneNumber: string;
  country: string;
  ecl: string;
  contactOwner: string;
  gpa: number;
  standardisedTests: string[];
  universities: string[];
  admissionExamPrep: string[];
  studySubjects: string[];
  subjectsStudentLikes: string[];
  subjectsStudentDoesntLike: string[];
  learningStyle: string[];
  countries: string[];
  interests: string[];
};

export type NewUserType = {
  email: string;
  countries: string[];
  interests: string[];
  studySubjects: string[];
  country: string;
  fullName: string;
  city: string;
  graduationYear: number | undefined;
  highSchool: string;
  parentEmail: string;
  parentFullName: string;
  parentPhoneNumber: string;
  phoneNumber: string;
  isInfluencer: boolean;

  contactOwner: string;
  ecl: string;
  gpa: number | undefined;
  standardisedTests: string[];
  universities: string[];
  admissionExamPrep: string[];
  subjectsStudentLikes: string[];
  subjectsStudentDoesntLike: string[];
  learningStyle: string[];

  isMentor: boolean;
  mentorData?: MentorData;
};

export type CreateNewUserResponse = {
  message: string;
  id: string;
  registrationToken: string;
};

export type FileType = {
  path: string;
  filename: string;
  mimetype: string;
  content?: any;
  originalname?: string;
};

export type JitsiApi = {
  getNumberOfParticipants: () => number;
  addEventListener: (event: string, listener: (arg1: any) => void) => void;
  removeEventListener: (event: string) => void;
  isVideoMuted: () => Promise<boolean>;
  executeCommand: (arg1: string) => void;
};

export type MailProps = {
  to: string | string[];
  cc?: string | string[];
  from: string;
  subject: string;
  text?: string;
  html?: string;
  replyTo?: string;
  attachments?: any[];
};

export type Email = MailProps & {
  successful: boolean;
};

export type MentorFeedbackItem = {
  _id: string;
  course: string;
  startDate: string;
  mentor: string;
  feedback: MentorFeedback;
};

export type SelectOption = {
  label: string;
  value: string;
};

export type DurationInput = [number, moment.DurationInputArg2];

export type MentorRelabilityScore = {
  _id: string;
  newScore: number;
  oldScore: number;
  change: number;
  description: string;
  user: string;
  isDefault: boolean;
  changeAdminData?: {
    reason: string;
    date: string;
    adminId: string;
  };
};

export type Report = {
  _id: string;
  title: string;
  status: number;
  mentor: {
    _id: string;
    fullName: string;
    email: string;
    isTestUser?: boolean;
  };
  paymentAmountInHC: number;
  paymentAmountInExtraService: number;
  paymentAmountInIC: number;
  vatAmount?: number;
  totalAmountWithoutVat?: number;
  hubspotCurrency: string;
  invoiceCurrency: string;
  exchangeRate: {
    from: {
      currency: string;
      value: number;
      date: string;
    };
    to: {
      currency: string;
      value: number;
      date: string;
    };
  };

  rate: Rate;

  invoiceId: any;
  activityReportId: any;

  invoiceNo?: string;

  noOfCompletedSessions: number;
  noOfActiveStudents: number;

  sessions: Session[];

  extraServices: ExtraService[];

  year: string;
  month: string;

  rejectionReason?: string;
  eInvoiceRejectionReason?: string;

  createdBy: string;
  updatedBy: string;

  createdAt?: string;
  updatedAt?: string;
};

export type Invoice = {
  _id: string;
  file: FileType;
  userId: string;
  reportId: string;
  exchangeRate: {
    from: {
      currency: string;
      value: number;
      date: string;
    };
    to: {
      currency: string;
      value: number;
      date: string;
    };
  };

  createdBy: string;
  updatedBy: string;

  createdAt?: string;
  updatedAt?: string;
};

export type Attachment = {
  name: string;
  content: Buffer;
};

export type ExchangeRate = {
  _id?: string;
  from: string;
  to: {
    currency: string;
    value: number;
  };
  date: string;
  day: number;
  month: number;
  year: number;
  createdAt?: string;
  updatedAt?: string;
};

export type ExtraService = {
  _id: string;
  serviceType: ServiceType;
  description?: string;
  amount: number;
  currency: string;
  mentor: {
    _id: string;
    fullName: string;
  };
  status: number;

  month: number;
  year: number;

  createdAt?: string;
  updatedAt?: string;
};

export type SaveMentorProfileData = {
  email?: string;
  fullName?: string;
  phoneNumber?: string;
  country?: string;
  city?: string;
  educationalBackground?: {
    educationalInstitution: string;
    major: string;
    graduationYear: number;
    gpa?: number;
    degreeType?: string;
    startDate?: string;
    endDate?: string;
  }[];
  experiences?: Experience[];
  academicExperiences?: {
    label: string;
    title: string;
    fieldOfStudy: string;
  }[];
  mostRelevantJob?: string;
  biography?: string;
  majors?: string[];
  coursesCanTeach?: {
    _id: string;
    order: number;
  }[];
  maximumActiveEnrollments?: number;
  rate?: Rate;
  company?: {
    model?: string;
    name: string;
    address: string;
    CUI: string;
    IBAN: string;
    country?: string;
  };
};

export type SaveStudentProfileData = {
  email?: string;
  fullName?: string;
  phoneNumber?: string;
  city?: string;
  country?: string;
  highSchool?: string;
  graduationYear?: number;
  highSchoolExamn?: {
    title: string;
    grade: number;
  };
  grades?: {
    label: string;
    title: string;
    grade: number;
  }[];
  activities?: string[];
  interests?: string[];
  studySubjects?: string[];
  ecl?: string;
  educationalBackground?: {
    educationalInstitution: string;
    major: string;
    graduationYear: number;
    gpa?: number;
    degreeType?: string;
    startDate?: string;
    endDate?: string;
  }[];
  appliedUniversities?: {
    university: string;
    major: string;
    degree: string;
    pros: string[];
    cons: string[];
    status: string;
  }[];
  languageExams?: Partial<LanguageExam>[];
  parentData?: {
    fullName: string;
    phoneNumber: string;
    email: string;
  };

  preferredLanguage?: string;
  timezone?: string;
};

// LMS
export type LMSCourse = {
  id: string;
  title: string;
  price: number;
};

export type LMSFields = {
  email?: string;
  username?: string;
  is_admin?: boolean;
};

export type ApiError = {
  code: string;
  message: string;
  internalError: string;
  invalidFields?: Record<string, string | string[]>;
};

export type ApiErrorOptions = {
  code: string;
  message: string;
  method: string;
  endpoint: string;
  error: string;
  invalidFields?: Record<string, string | string[]>;
  originalUrl?: string;
  params?: any;
  query?: any;
  body?: any;
  stack?: string;
};

export type CreateNewFlag = {
  modelType: number;
  refId: string;
  ref?: Enrollment | Meeting;
  flagType: number;
  description: string;
};

export type DismissFlag = {
  modelType: number;
  flagType: number;
  refId: string;
};

export interface MeetingWithSessionId extends Meeting {
  sessionId: string;
}

export type Flag = {
  _id: string;
  modelType: number;
  flagType: number;
  ref: Enrollment | MeetingWithSessionId;
  description: string;
  status: number;
  closedAt?: string;
  closedBy?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type MentorWidgetStatistics = {
  activeMentors: {
    count: number;
    mentors: Partial<User>[];
  };
  maxCapacity: {
    count: number;
    mentors: User[];
  };
  inTraining: {
    count: number;
    mentors: User[];
  };
  noSessionsHeld: {
    count: number;
    mentors: User[];
  };
  noRecentSessions: {
    count: number;
    mentors: User[];
  };
  missingFeedback: {
    count: number;
    mentors: User[];
  };
  nextSessionMissing: {
    count: number;
    mentors: User[];
  };
};

export type PermissionsType = {
  student?: boolean;
  mentor?: boolean;
  course_creator?: {
    isCourseCreator?: boolean;
    courses?: string[];
  };
  admin?: boolean;
  admin_views?: {
    payments?: boolean;
    customer_success?: boolean;
    students?: boolean;
    mentors?: boolean;
    users?: boolean;
  };
};

export type createBaseFields = {
  fullName: string;
  phoneNumber: string;
};

export type createStudentFields = createBaseFields & {
  parentEmail: string;
  parentFullName: string;
  parentPhoneNumber: string;

  city: string;
  country: string;
  highSchool: string;
  graduationYear: number;
  gpa: number;
  ecl: string;
  standardisedTests: string[];
  universities: string[];
  admissionExamPrep: string[];
  studySubjects: string[];
  subjectsStudentLikes: string[];
  subjectsStudentDoesntLike: string[];
  learningStyle: string[];
  countries: string[];
  interests: string[];
  timezone: string;
  preferredLanguage: string;
};

export type createMentorFields = createBaseFields & {
  city: string;
  country: string;
  universities: string[];
  majors: string[];
  countries: string[];
  status: string;
  capacity: number;
  rate: Rate;
  coursesCanTeach: string[];
  companyModel: string;
  companyName: string;
  companyAddress: string;
  companyCUI: string;
  companyIBAN: string;
  companyCountry: string;
};

export type createAdminFields = createBaseFields;

export type SearchMentorEnrollmentsParams = {
  studentSearch?: string;
  course?: string;
  enrollmentStatus?: 'all' | 'upcoming' | 'active' | 'ended';
  studentStatus?: 'all' | 'active' | 'inactive';
  gradYear?: number;
  periodStart?: string;
  periodEnd?: string;
  sorters?: { field: string; direction: number }[];
  sortOrder?: 'asc' | 'desc' | null;
  sortBy?: string;
  page?: number;
  limit?: number;
};

export interface EnrollmentWithStudentTypes extends Omit<Enrollment, 'status'> {
  status?: number;
}

export type ActiveStudents = {
  _id: string;
  fullName: string;
  enrollment: {
    _id: string;
    course: Partial<Course>;
  };
};

export type EducationalInstitution = {
  _id?: string;
  name: string;
  type: string; // highschool / univ etc.
  createdAt?: string;
  updatedAt?: string;
};

export type Experience = {
  _id?: string;
  experienceType: ExperienceType;
  positionTitle: string;
  employer?: string;
  employmentType?: EmploymentType;
  activityType?: ActivityType;
  institution?: string;
  startDate: string;
  endDate: string;
  description: string;
};

export type Position = {
  _id?: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type Major = {
  _id?: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type Message = {
  content: string;
  at: string;
  file?: string;
  error?: boolean;
};

export type KnowledgeBaseChat = {
  _id: string;
  title: string;
  user: any;
  threadId: string;
  messages: [Message, Message][];
  createdAt: string;
  updatedAt: string;
};

export type Referral = {
  persoana_care_a_facut_recomandarea: string;
  email_persoana_care_a_facut_recomandarea: string;
  firstname: string;
  lastname: string;
  email: string;
  contact_type: string;
  phone: string;
  referral_reward: number;
  tell_us_more: string;
};

export type NPSCategoriesType = 'detractors' | 'passives' | 'promoters';
export type WebSocketSession = {
  ws: ws;
  openedBy: string;
  isConnectionOpen: boolean;
  authToken?: string;
  threadId?: string;
  runId?: string;
};

export type StudentDetails = {
  fullName: string;
  phoneNumber: string;
  parentName: string;
  parentEmail: string;
  parentPhoneNumber: string;
  city: string;
  country: string;
  highschool: string;
  graduationYear: string;
  gpa: string;
  universities: string[];
  countriesToStudyIn: string[];
  major: string;
  preferredLanguage?: string;
  timezone?: string;
};

export type AdminDetails = {
  fullName: string;
  phoneNumber: string;
};

export type MentorDetails = {
  fullName: string;
  phoneNumber: string;
  coursesCanTeach?: {
    _id: string;
    order: number;
  }[];
  mentorStatus: string;
  payRate: string | undefined;
  currency: string;
  billingCurrency: string;
  vatPayer: boolean;
  company: string;
  companyCountry: string;
  companyAddress: string;
  fiscalRegistrationNumber: string;
  companyIBAN: string;
};

export type SignVideoAccessToken = {
  type: number;
  session_name: string;
  name: string;
  session_key: string;
  user_id: string;
};

export type CreateHomeworkParams = {
  title: string;
  description: string;
  student: string;
  mentor: string;
  meeting: string;
  enrollment: string;
};

export type MentorHistory = {
  fullName: string;
  addedOn: string;
  status?: string;
};

export type Rate = {
  amount: number;
  currency: string;
  billingCurrency: string;
  vatPayer: boolean;
  amountInRon?: number;
};

export type MeetingUser = {
  _id: string;
  avatar: string;
  fullName: string;
};

export type MeetingDetails = {
  accessToken: string;
  accessTokenData: {
    name: string;
    session_key: string;
    session_name: string;
    type: number;
  };
  startDate: Date;
  agenda: string;
  mentor: MeetingUser;
  students: MeetingUser[];
  status: number;
  name: string;
  homeworkFileId?: string;
  googleDrive?: string;
};

export type CoursesCanTeach = {
  _id: string;
  title?: string;
  order?: number;
};
