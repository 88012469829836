import { ReactComponent as ActiveFilterIcon } from '@assets/icons/V4/active-filter-icon.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/V4/close-icon.svg';
import { ReactComponent as InactiveFilterIcon } from '@assets/icons/V4/inactive-filter-icon.svg';
import IconButton from '@components/V4/IconButton';
import { SortOrder } from '@components/V4/Table/Table';
import { useQueryParamsState } from '@hooks';
import {
  Feedback,
  default as MentorFeedbackModal,
} from '@modules/Mentor/components/FeedbackModal/FeedbackModal';
import SessionHistoryFilters from '@modules/SessionHistory/SessionHistoryFIlters/SessionHistoryFilters';
import SessionHistoryListDesktop from '@modules/SessionHistory/SessionHistoryList/SessionHistoryListDesktop';
import SessionHistoryListMobile from '@modules/SessionHistory/SessionHistoryList/SessionHistoryListMobile';
import { default as StudentFeedbackModal } from '@modules/Student/components/FeedbackModal/FeedbackModal';
import { useSearchSessionHistory } from 'apps/agora/src/actions/enrollments.actions';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => new URLSearchParams(useLocation().search);

const SessionHistory = () => {
  const [isMentorFeedbackModalOpen, setIsMentorFeedbackModalOpen] = useState(false);
  const [isStudentFeedbackModalOpen, setIsStudentFeedbackModalOpen] = useState(false);
  const [feedback, setFeedback] = useState<Feedback>({});
  const [isFilterDropdownActive, setIsFilterDropdownActive] = useState(false);
  const [feedbackModalData, setFeedbackModalData] = useState<{
    title?: string;
    meetingId: string;
  }>();

  const query = useQuery();
  const status = query.get('status') || null;
  const course = query.get('course') || null;
  const student = query.get('student') || null;
  const limit = query.get('limit') || '10';
  const page = query.get('page') || '1';
  const sortBy = query.get('sortBy') || null;
  const sortOrder = (query.get('sortOrder') || null) as SortOrder;

  const [searchParams, setSearchParams] = useQueryParamsState<any>({
    page: page,
    limit: limit,
    status: status,
    student: student,
    course: course,
    sortBy: sortBy,
    sortOrder: sortOrder,
  });

  const { data: allSessions, isLoading } = useSearchSessionHistory({
    limit: searchParams.limit,
    page: searchParams.page,
    status: searchParams.status,
    student: searchParams.student,
    course: searchParams.course,
    sortBy: searchParams.sortBy,
    sortOrder: searchParams.sortOrder || 'asc',
  });

  const openMentorFeedbackModalHandler = (title: string, meetingId: string) => {
    setIsMentorFeedbackModalOpen(true);
    setFeedbackModalData({ title, meetingId });
  };

  const openStudentFeedbackModalHandler = (meetingId: string) => {
    setIsStudentFeedbackModalOpen(true);
    setFeedbackModalData({ meetingId });
  };

  const currentPageChangeHandler = (page: number) => {
    setSearchParams({
      ...searchParams,
      page,
    });
  };

  const pageSizeChangeHandler = (size: number) => {
    setSearchParams({
      ...searchParams,
      limit: size,
      page: 1,
    });
  };

  const sortHandler = (field: string, order: SortOrder) => {
    setSearchParams({
      ...searchParams,
      sortBy: order ? field : null,
      sortOrder: order || null,
      page: 1,
    });
  };

  const courseHandler = (course: string) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      course: course,
    });
  };

  const statusHandler = (status: number | null) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      status: status ?? null,
    });
  };

  const studentHandler = (student: string) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      student: student,
    });
  };

  const pagination = {
    pageSize: limit !== null ? parseInt(limit) : 10,
    total: allSessions?.total,
    currentPage: page !== null ? parseInt(page) : 1,
    onCurrentPageChange: currentPageChangeHandler,
    onPageSizeChange: pageSizeChangeHandler,
  };

  return (
    <section className="flex flex-col gap-6 p-4 min-h-full tablet:p-6 tablet:gap-12 laptop:ml-1 laptop:gap-6 laptop:pt-12 bg-surfaceBackground">
      <div className="mx-auto w-full flex flex-col gap-6 max-w-[1564px] laptop:px-24">
        <h1 className="flex justify-between font-raleway font-semibold text-2xl">
          Sessions
          <div className="flex items-center gap-3 tablet:hidden">
            {(!!searchParams.status || !!searchParams.sort || !!searchParams.course) && (
              <IconButton
                onClick={() =>
                  setSearchParams({
                    page: 1,
                    limit: 10,
                    status: null,
                    course: null,
                    sortBy: null,
                    sortOrder: null,
                  })
                }
                icon={<CloseIcon />}
              />
            )}
            <IconButton
              size="large"
              onClick={() => setIsFilterDropdownActive((prev) => !prev)}
              icon={
                isFilterDropdownActive ? (
                  <ActiveFilterIcon className="w-9 h-9" />
                ) : (
                  <InactiveFilterIcon className="w-9 h-9" />
                )
              }
            />
          </div>
        </h1>

        <SessionHistoryFilters
          isLoading={isLoading}
          sortBy={sortBy}
          sortOrder={sortOrder}
          sortHandler={sortHandler}
          courseHandler={courseHandler}
          statusHandler={statusHandler}
          studentHandler={studentHandler}
          searchParams={searchParams}
          isFilterDropdownActive={isFilterDropdownActive}
          courseFilters={allSessions?.courseFilters}
          studentsFilter={allSessions?.studentsFilter}
        />

        <SessionHistoryListMobile
          allSessions={allSessions}
          pagination={pagination}
          sortHandler={sortHandler}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onMentorFeedbackOpen={openMentorFeedbackModalHandler}
          onStudentFeedbackOpen={openStudentFeedbackModalHandler}
          isLoading={isLoading}
        />
        <SessionHistoryListDesktop
          allSessions={allSessions}
          pagination={pagination}
          sortHandler={sortHandler}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onMentorFeedbackOpen={openMentorFeedbackModalHandler}
          onStudentFeedbackOpen={openStudentFeedbackModalHandler}
          isLoading={isLoading}
        />

        {isMentorFeedbackModalOpen && (
          <MentorFeedbackModal
            title={feedbackModalData?.title ?? ''}
            feedback={feedback}
            meetingId={feedbackModalData?.meetingId ?? ''}
            setFeedback={setFeedback}
            onClose={() => setIsMentorFeedbackModalOpen(false)}
          />
        )}

        {isStudentFeedbackModalOpen && (
          <StudentFeedbackModal
            meetingId={feedbackModalData?.meetingId ?? ''}
            isOpen={isStudentFeedbackModalOpen}
            onClose={() => setIsStudentFeedbackModalOpen(false)}
          />
        )}
      </div>
    </section>
  );
};

export default SessionHistory;
