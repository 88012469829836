import React, { useState } from 'react';
import { COLORS } from '../../../../v3/global';
import './agora-input.styles.scss';

type Props = {
  htmlFor: string;
  type: string;
  name: string;
  id: string;
  placeholder: string;
  label: string;
  labelMargin?: string;
  labelColor?: string;
  margin?: string;
  width?: string;
  value?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  rows?: number;
  color?: string;
  borderColor?: string;
  isRequired?: boolean;
};

/**
 * @deprecated
 */
const AgoraInput = ({
  htmlFor,
  type,
  name,
  id,
  placeholder,
  label,
  labelMargin,
  labelColor,
  margin,
  width,
  value,
  onChange,
  onBlur,
  rows,
  color,
  borderColor,
  isRequired,
}: Props) => {
  return (
    <div style={{ margin: margin ?? '0px', width: width ?? '100%' }}>
      <label
        style={{ margin: labelMargin ?? '', color: labelColor ?? '' }}
        htmlFor={htmlFor}
        className="block text-sm font-medium leading-6 text-customPrimary"
      >
        {label} {isRequired && <span className="text-red-600">*</span>}
      </label>
      <div className="agora-input mt-2">
        {type === 'textarea' ? (
          <textarea
            style={{
              backgroundColor: 'transparent',
              border: `1px solid ${borderColor ?? COLORS.PRIMARY}`,
              color: color ?? '',
            }}
            rows={rows ?? 4}
            name={name}
            id={id}
            className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-0 ring-inset ring-customPrimary placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-customPrimary sm:text-sm sm:leading-6"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
          />
        ) : (
          <input
            style={{
              backgroundColor: 'transparent',
              border: `1.6px solid ${borderColor ?? COLORS.PRIMARY}`,
              color: color ?? '',
            }}
            value={value}
            onChange={onChange}
            type={type}
            name={name}
            id={id}
            className="block w-full rounded-md border-0 py-1.5 text-white-900 shadow-sm ring-0 ring-inset ring-transparent placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-customPrimary sm:text-sm sm:leading-6"
            placeholder={placeholder}
            onBlur={onBlur}
          />
        )}
      </div>
    </div>
  );
};

export default AgoraInput;
