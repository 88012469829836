import { ReactComponent as ClearIcon } from '@assets/icons/new-close-icon.svg';
import { PlusOutlined } from '@ant-design/icons';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useModal } from '@hooks';
import ExtraServicesModal from '@modules/Admin/ExtraServices/ExtraServicesModal/CreateExtraServiceModal';
import ExtraServicesTable from '@modules/Admin/ExtraServices/ExtraServicesTable';
import { useGetAllMentors, useSearchExtraServices } from '@actions';
import styles from '@styles/components/calendar.module.scss';
import reportsStyles from '@styles/pages/admin/activity-report.module.scss';
import { Button, Pagination, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useCalendar from '../../../hooks/useCalendar';

const itemsPerPage = 10;

const ExtraServices = () => {
  const { goForward, goBackward, selected } = useCalendar();

  const [statusFilter, setStatusFilter] = useState('ALL');
  const [mentorFilter, setMentorFilter] = useState('ALL');

  const {
    data: extraServices,
    isLoading: isLoadingExtraServices,
    refetch: refetchExtraServices,
  } = useSearchExtraServices({
    month: Number(selected.format('M')),
    year: Number(selected.format('YYYY')),
    page: 1,
    limit: 100,
    status: statusFilter === 'CREATED' ? 0 : statusFilter === 'PAID' ? 1 : '',
    mentorId: mentorFilter !== 'ALL' ? mentorFilter : '',
  });

  const { data: mentors } = useGetAllMentors();

  const [ServicesModal, showModal] = useModal(ExtraServicesModal);

  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [renderKey, setRenderKey] = useState(0);
  const [rowTouched, setRowTouched] = useState(0);

  const [showAll, setShowAll] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the data array to display only the items for the current page
  const currentItems = extraServices?.slice(startIndex, endIndex);

  const totalPages = Math.ceil(extraServices?.length ?? 0 / itemsPerPage);

  const handleRowTouched = () => {
    setRowTouched((prevRowTouched) => prevRowTouched + 1);
  };

  const handleSelected = (report: any) => {
    setSelectedItems((prevSelectedItems) => {
      const isSelected = prevSelectedItems.includes(report);

      if (isSelected) {
        return prevSelectedItems.filter((selectedItem) => selectedItem !== report);
      } else {
        return [...prevSelectedItems, report];
      }
    });
  };

  const handleSelectAll = (reports: any[]) => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      selectedStatus ? setSelectedItems(reports) : setSelectedItems(reports);
    }

    setSelectAll(!selectAll);
  };

  useEffect(() => {
    refetchExtraServices();
  }, [rowTouched]);

  return (
    <>
      <div
        className={reportsStyles.activityReportContainer}
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <h1 className={reportsStyles.title}>Extra Services</h1>
        <div className={reportsStyles.selectorContainer}>
          <span onClick={() => handleRowTouched()}>
            <ChevronLeftIcon
              className={`${styles.icon} ${reportsStyles.directionIcon}`}
              onClick={goBackward}
            />
          </span>
          <span className={reportsStyles.dateFormat}>{selected.format('MMMM YYYY')}</span>
          <span onClick={() => handleRowTouched()}>
            <ChevronRightIcon
              className={`${styles.icon} ${reportsStyles.directionIcon}`}
              onClick={goForward}
            />
          </span>
        </div>

        <Button
          type="primary"
          style={{
            border: '2px solid #000000',
            margin: '0px 10px',
          }}
          onClick={() => {
            showModal({
              handleRowTouched,
            });
          }}
        >
          <PlusOutlined />
          Add Service
        </Button>
      </div>

      <div className={reportsStyles.reportsContainer}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="text-white">Filter by status:</span>
            <Select
              showSearch
              style={{
                width: '200px',
                marginBottom: '10px',
                marginRight: '16px',
              }}
              onChange={(val) => setStatusFilter(val)}
              value={statusFilter}
              placeholder={'Select Status'}
            >
              <Select.Option value={'ALL'}>ALL</Select.Option>
              <Select.Option value={'CREATED'}>CREATED</Select.Option>
              <Select.Option value={'PAID'}>PAID</Select.Option>
            </Select>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="text-white">Filter by mentor:</span>
            <Select
              style={{ width: '200px', marginBottom: '10px' }}
              allowClear
              clearIcon={<ClearIcon className="text-customGrey w-3 h-3" />}
              showSearch
              placeholder="Select a mentor"
              optionFilterProp="children"
              onChange={(value: string) => {
                setMentorFilter(value);
              }}
            >
              <Select.Option key="select-a-mentor" value="ALL">
                Select a mentor
              </Select.Option>
              <Select.Option key="all" value="ALL">
                ALL
              </Select.Option>
              {mentors &&
                mentors?.map((mentor) => (
                  <Select.Option key={mentor?._id} value={mentor?._id}>
                    {mentor.fullName}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '40px' }}></div>

      <div>
        {extraServices && (
          <ExtraServicesTable
            key={renderKey}
            selectedDate={selected}
            extraServicesRendered={currentItems}
            extraServices={extraServices}
            isLoading={isLoadingExtraServices}
            handleSelected={handleSelected}
            handleSelectAll={handleSelectAll}
            selectAll={selectAll}
            selectedItems={selectedItems}
            handleRowTouched={handleRowTouched}
          />
        )}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
        className={reportsStyles.paginationContainer}
      >
        <span className="text-white">Total: {extraServices?.length} &nbsp;</span>{' '}
        {/*{!showAll && (*/}
        <Pagination
          total={extraServices?.length}
          current={currentPage}
          defaultPageSize={10}
          defaultCurrent={1}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            // setCurrentPageSize(pageSize);
          }}
          // pageSizeOptions={[10, 20, 50, 100, allMonthlyReports?.total]}
        />
      </div>
      {ServicesModal}
    </>
  );
};

export default ExtraServices;
