import { coursesApis } from '@shared/apis';
import { genericMutationWithId, genericQuery, genericQueryWithId } from './generics';

export const useUserCourses = genericQuery<coursesApis.GetUserCourses>(coursesApis.getUserCourses);

export const useCourse = genericQueryWithId<coursesApis.GetCourse>(coursesApis.getCourse);

export const useGetAllCourses = genericQuery<coursesApis.GetAllCourses>(coursesApis.getAllCourses);

export const useGetAllCoursesLite = genericQuery<coursesApis.GetAllCoursesLite>(
  coursesApis.getAllCoursesLite
);

export const useGetAllCoursesByMentor = genericQuery<coursesApis.GetCoursesByMentor>(
  coursesApis.getCoursesByMentor
);

export const useSoftDeleteCourse = genericMutationWithId<coursesApis.SoftDeleteCourse>(
  coursesApis.softDeleteCourse
);

export const useUploadCourseImage = genericMutationWithId<coursesApis.UploadCourseImage>(
  coursesApis.uploadCourseImage
);
