import { useAuth0 } from '@auth0/auth0-react';
import { ENVIRONMENT } from '@shared/frontendEnv';
import { createContext, useState, useContext } from 'react';
import { useQueryClient } from 'react-query';
import {
  CUSTOMER_SUCCESS,
  FINANCIAL_ROLE,
  MENTORS_MANAGER,
  MENTOR_ROLE,
  MyAccountInfo,
  STUDENTS_MANAGER,
  USERS_MANAGER,
} from '../../../../../libs/shared/src/common';
import { useLogin, useMe } from './actions';

export type State = {
  userId: string;
  isLoading: boolean;
  error: unknown;
  isLogoutInProgress: boolean;

  isAdmin: boolean;
  isMentor: boolean;
} & MyAccountInfo;
export const AuthStateContext = createContext<State | undefined>(undefined);

type Actions = {
  login: (username: string, password: string) => void;
  logout: () => void;
};
export const AuthActionsContext = createContext<Actions | undefined>(undefined);

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

const AuthProvider = ({ children }: Props) => {
  const [isLogoutInProgress, setIsLogoutInProgress] = useState<boolean>(false);
  const { logout: auth0Logout } = useAuth0();

  const queryClient = useQueryClient();
  const me = useMe();

  if (me.data?.isDezactivated && !localStorage.getItem('impersonate-user-id')) {
    throw new Error('User is deactivated');
  }

  const login = useLogin();

  const state: State = {
    userId: me.data?._id ?? '',
    username: me.data?.username ?? '',
    fullName: me.data?.fullName ?? '',
    email: me.data?.email ?? '',
    avatar: me.data?.avatar ?? '',
    pronouns: me.data?.pronouns ?? '',
    roles: me.data?.roles || [],
    isOnboarded: !!me.data?.isOnboarded,
    countries: me.data?.countries || [],
    interests: me.data?.interests || [],
    studySubjects: me.data?.studySubjects || [],
    harvesterToken: me.data?.harvesterToken ?? '',
    isLoading: me.isLoading || login.isLoading,
    highSchool: me.data?.highSchool ?? '',
    country: me.data?.country ?? '',
    city: me.data?.city ?? '',
    graduationYear: me.data?.graduationYear ?? 0,
    phoneNumber: me.data?.phoneNumber ?? '',
    gender: me.data?.gender ?? '',
    folderId: me.data?.folderId ?? '',
    googleDrive:
      me.data?.googleDrive?.[`${ENVIRONMENT}`]?.[
        me.data?.roles.includes(MENTOR_ROLE) ? 'mentor' : 'student'
      ]?.id ?? '',
    error: me.error || login.error,

    isAdmin:
      (me.data?.roles.includes(FINANCIAL_ROLE) ||
        me.data?.roles.includes(CUSTOMER_SUCCESS) ||
        me.data?.roles.includes(STUDENTS_MANAGER) ||
        me.data?.roles.includes(MENTORS_MANAGER) ||
        me.data?.roles.includes(USERS_MANAGER)) ??
      false,
    isMentor: !!me.data?.roles.includes(MENTOR_ROLE),
    rate: me.data?.rate,
    isLogoutInProgress: isLogoutInProgress,
  };

  const actions: Actions = {
    login: (username, password) => login.mutate({ username, password }),
    logout: async () => {
      try {
        localStorage.setItem('justLoggedOut', 'true');

        setIsLogoutInProgress(true);

        queryClient.clear();

        await auth0Logout({
          logoutParams: { returnTo: window.location.origin },
        });
      } catch (e) {
        console.log({ errorInLogout: e });
      } finally {
        setIsLogoutInProgress(false);
      }
    },
  };

  return (
    <AuthStateContext.Provider value={state}>
      <AuthActionsContext.Provider value={actions}>{children}</AuthActionsContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuthState = () => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
};

const useAuthActions = () => {
  const context = useContext(AuthActionsContext);
  if (context === undefined) {
    throw new Error('useAuthActions must be used within a AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuthState, useAuthActions };
