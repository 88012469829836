import { useStudentsOverview } from '@actions';
import { ROLES } from '@shared/constants';
import { User } from '@shared/types';
import styles from '@styles/pages/admin/cs-students.module.scss';
import { Button, Table } from 'antd';
import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';

type Props = {
  selectedIds: string[];
  selectedField: string;
};

const CSVTable: React.FC<Props> = ({ selectedIds, selectedField }) => {
  const { data } = useStudentsOverview();

  const columns = useMemo(
    () => [
      { title: 'Full Name', dataIndex: 'fullName' },
      { title: 'Email', dataIndex: 'email' },
      {
        title: 'Roles',
        dataIndex: 'roles',
        render: (value: unknown, record: User) => {
          return record.roles.map((r) => ROLES[r]).join(', ');
        },
      },
    ],
    []
  );

  const indexedUsers = data?.users ?? {};

  const userList = [...new Set(selectedIds)]
    .map((id) => {
      if (!indexedUsers[id]) return undefined;
      return {
        ...indexedUsers[id],
        key: id,
      };
    })
    .filter((u) => u !== undefined) as User[];

  const csvData = useMemo(() => {
    const data = [];

    const headers = columns.map((col) => col.title);
    data.push(headers);

    userList.forEach((user) => {
      const row = columns.map((col) => {
        const dataIndex = col.dataIndex as keyof User;
        const value = user[dataIndex];
        return col.render ? col.render(value, user) : user[dataIndex];
      });

      data.push(row);
    });

    return data;
  }, [userList]);

  return (
    <div>
      <CSVLink
        filename={`cs_glance_${selectedField}`}
        data={csvData}
        className={styles.glanceDownload}
      >
        <Button>Download csv</Button>
      </CSVLink>

      <Table dataSource={userList} columns={columns} pagination={{ pageSize: 10 }} />
    </div>
  );
};

export default CSVTable;
