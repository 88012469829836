import { AudioTransmissionQuality, Participant } from '@modules/zoom/utils/types';
import { BasicProps } from 'apps/agora/src/utils/types';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import InfoItem from './InfoItem';
import ParticipantCard from './ParticipantCard';

interface AudioTabProps extends BasicProps {
  participantsList?: Participant[];
  audioQuality?: Record<number, Record<'sending' | 'receiving', AudioTransmissionQuality>>;
  hasEmptyState?: boolean;
}

const AudioTab = (props: AudioTabProps) => {
  const { participantsList, audioQuality, hasEmptyState } = props;

  if (hasEmptyState) {
    return <EmptyStateCard text="No data available." icon={undefined} />;
  }

  return (
    <div className="flex flex-col gap-4">
      {participantsList?.map((user) => (
        <ParticipantCard key={user._id} name={user.fullName}>
          <InfoItem
            label="Average Loss (Sending)"
            value={audioQuality?.[user.zoomUserId]?.sending?.avgLoss}
          />
          <InfoItem
            label="Average Loss (Receiving)"
            value={audioQuality?.[user.zoomUserId]?.receiving?.avgLoss}
          />
          <InfoItem
            label="Maximum Loss (Sending)"
            value={audioQuality?.[user.zoomUserId]?.sending?.maxLoss}
          />
          <InfoItem
            label="Maximum Loss (Receiving)"
            value={audioQuality?.[user.zoomUserId]?.receiving?.maxLoss}
          />
        </ParticipantCard>
      ))}
    </div>
  );
};

export default AudioTab;
