import { useAuthState } from '@contexts/AuthContext';
import { getTokenSilently } from '@contexts/AuthContext/auth';
import { useEffect, useState, useRef } from 'react';

interface UseWebSocketProps {
  url: string;
  onTitleGenerated?: () => void;
}

const useWebSocket = (props: UseWebSocketProps) => {
  const { url, onTitleGenerated } = props;

  const { userId } = useAuthState();
  const [lastMessage, setLastMessage] = useState<string>('');
  const [newChat, setNewChat] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [isGeneratingText, setIsGeneratingText] = useState(false); // Track message reception

  const continueListeningRef = useRef(true); // Ref to track the latest continueListening value

  const handleSetLoading = (loading: boolean) => {
    setLoading(loading);
  };

  const ws = useRef<WebSocket | null>(null);

  const removeSources = (text: string) => {
    return text?.replace(/【\d+:\d+†source】/g, '');
  };

  const [isOpened, setIsOpened] = useState<boolean>(false);

  useEffect(() => {
    ws.current = new WebSocket(url);

    ws.current.onopen = () => {
      setIsOpened(true);
    };

    ws.current.onmessage = (event: MessageEvent) => {
      try {
        const parsedEvent = JSON.parse(event.data);

        if (parsedEvent.type === 'chat_created') {
          setNewChat(parsedEvent);
        }

        if (parsedEvent.type === 'title') {
          onTitleGenerated?.();
        }

        if (parsedEvent.type === 'text-generation-started') {
          setIsGeneratingText(true);

          return;
        }

        if (parsedEvent.type === 'text-generation-ended') {
          setLoading(false);
          setIsGeneratingText(false);

          return;
        }

        if (!continueListeningRef.current) {
          return;
        }

        const content = parsedEvent.type !== 'title' ? removeSources(parsedEvent.content) : '';

        setLastMessage((prevMessage) => prevMessage + content);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };

    ws.current.onclose = () => {};

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [url, userId]);

  const createNewChat = () => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      const createChat = JSON.stringify({
        type: 'create_chat',
        userId,
      });

      ws.current.send(createChat);
    }
  };

  const sendMessage = async (
    type: string,
    message: string,
    threadId: string,
    chatId: string,
    title?: string
  ) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      if (type === 'chat') {
        const chat = {
          id: chatId,
          message,
          threadId: threadId,
          title: title ?? 'New Chat',
        };

        const chatMessage = JSON.stringify({ type: 'chat', chat, userId });

        ws.current.send(chatMessage);
        setLastMessage('');
        continueListeningRef.current = true;
      } else if (type === 'initial_connection') {
        getTokenSilently()
          .then((token) => {
            const connectionMessage = JSON.stringify({
              type: 'initial_connection',
              userId: userId,
              authToken: `Bearer ${token}`,
            });

            ws.current?.send(connectionMessage);
          })
          .catch((error) => {
            console.error('Failed to establish authenticated WebSocket connection:', error);
          });
      } else if (type === 'stop_generating') {
        continueListeningRef.current = false;

        const cancelMessage = JSON.stringify({
          type: 'stop_generating',
          id: chatId,
          userId,
        });
        setIsGeneratingText(false);

        ws.current.send(cancelMessage);
      }
    }
  };

  return [
    lastMessage,
    sendMessage,
    createNewChat,
    newChat,
    isOpened,
    loading, // Return loading state
    isGeneratingText,
    handleSetLoading,
  ] as [
    string,
    (
      type: string,
      message: string,
      customThreadId?: string,
      chatId?: string,
      title?: string
    ) => Promise<void>,
    () => void,
    any,
    boolean,
    boolean,
    boolean,
    (loading: boolean) => void
  ];
};

export default useWebSocket;
