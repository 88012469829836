import TableHeaderInfoCard from '@components/V3/TableHeaderInfoCard';
import { useGetAllCourses } from '@actions';
import { MentorWidgetStatistics } from '@shared/types';
import AgoraSearchBar from '@utils/AgoraSearchbar';
import { Checkbox } from 'antd';
import { COLORS } from '../../../v3/global';
import './users-table-info.styles.scss';
import Select from '@components/V4/Select/Select';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';

type Props = {
  mentorStatistics?: MentorWidgetStatistics | undefined;
  handleSearch: (searchValue: string) => void;
  search: string;
  handleAddStatus: (statusValue: any) => void;
  handleRemoveStatus: (statusValue: any) => void;
  statuses: string[];
  handleCourse: (course: any) => void;
  handleClearCourse?: () => void;
  course: any;
  handleIsTestUser: (isTestUser: boolean) => void;
  handleIsDeactivated: () => void;
  isDeactivated?: boolean;
  isTestUser: boolean;
  handleExtraFilter: (extraFilter: any) => void;
  extraFilter: any;
  handleClearStatuses?: () => void;
  isLoadingMentorStatistics: boolean;
};

const UsersTableInfo = ({
  mentorStatistics,
  handleSearch,
  search,
  handleAddStatus,
  handleRemoveStatus,
  statuses,
  handleCourse,
  handleClearCourse,
  course,
  handleIsTestUser,
  handleIsDeactivated,
  isDeactivated,
  isTestUser,
  handleExtraFilter,
  extraFilter,
  handleClearStatuses,
  isLoadingMentorStatistics,
}: Props) => {
  const { data: allCourses } = useGetAllCourses();

  const mentorStatuses = [
    { label: 'In Training', value: 'In Training' },
    { label: 'Active', value: 'Active' },
    { label: 'Available', value: 'Available' },
    { label: 'At Capacity', value: 'At Capacity' },
    { label: 'Leaving', value: 'Leaving' },
    { label: 'Inactive', value: 'Inactive' },
  ];

  return (
    <div
      style={{
        backgroundColor: COLORS.DARK_GREY_ALT,
        minHeight: '328px',
        padding: '32px 24px',
      }}
    >
      <div
        style={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/*HEADER TITLE AND BUTTON*/}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <h1 style={{ color: COLORS.TEXT_PRIMARY }} className="header-title">
            Mentors
          </h1>
          {/*TODO Implement this when add mentor modal is ready*/}
          {/*<SecondaryButton*/}
          {/*  backgroundColor={COLORS.WHITE}*/}
          {/*  height="24px"*/}
          {/*  padding="4px 10px 4px 10px"*/}
          {/*  margin="0px"*/}
          {/*>*/}
          {/*  <PlusOutlined style={{ color: COLORS.BLACK, fontSize: '13px' }} />{' '}*/}
          {/*  <span*/}
          {/*    className="button-text"*/}
          {/*    style={{ marginLeft: '8px', color: COLORS.BLACK }}*/}
          {/*  >*/}
          {/*    Add Mentor*/}
          {/*  </span>*/}
          {/*</SecondaryButton>*/}
        </div>
        <div style={{ maxWidth: '1920px' }}>
          {/*TABLE HEADER INFO*/}

          {!isLoadingMentorStatistics ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                marginBottom: '16px',
              }}
            >
              <TableHeaderInfoCard
                title={mentorStatistics?.activeMentors?.count ?? 'N/A'}
                description={'Active'}
                statuses={statuses}
                status={'Active'}
                handleExtraFilter={handleExtraFilter}
                handleAddStatus={handleAddStatus}
                handleRemoveStatus={handleRemoveStatus}
                handleClearStatuses={handleClearStatuses}
              />
              <TableHeaderInfoCard
                title={mentorStatistics?.maxCapacity?.count ?? 'N/A'}
                description={'At max. capacity'}
                statuses={statuses}
                status={'At Capacity'}
                handleExtraFilter={handleExtraFilter}
                handleAddStatus={handleAddStatus}
                handleRemoveStatus={handleRemoveStatus}
                handleClearStatuses={handleClearStatuses}
              />
              <TableHeaderInfoCard
                title={mentorStatistics?.inTraining?.count ?? 'N/A'}
                description={'In training'}
                statuses={statuses}
                status={'In Training'}
                handleExtraFilter={handleExtraFilter}
                handleAddStatus={handleAddStatus}
                handleRemoveStatus={handleRemoveStatus}
                handleClearStatuses={handleClearStatuses}
              />
              <TableHeaderInfoCard
                title={mentorStatistics?.noSessionsHeld?.count ?? 'N/A'}
                description={'Not started'}
                handleExtraFilter={handleExtraFilter}
                extraFilter={extraFilter}
                handleClearStatuses={handleClearStatuses}
              />
              <TableHeaderInfoCard
                title={mentorStatistics?.noRecentSessions?.count ?? 'N/A'}
                description={'No recent session'}
                handleExtraFilter={handleExtraFilter}
                extraFilter={extraFilter}
                handleClearStatuses={handleClearStatuses}
              />
              <TableHeaderInfoCard
                title={mentorStatistics?.missingFeedback?.count ?? 'N/A'}
                description={'Missing feedback'}
                handleExtraFilter={handleExtraFilter}
                extraFilter={extraFilter}
                handleClearStatuses={handleClearStatuses}
              />
              <TableHeaderInfoCard
                title={mentorStatistics?.nextSessionMissing?.count ?? 'N/A'}
                description={'Next session missing'}
                margin="0 16px 8px 0px"
                handleExtraFilter={handleExtraFilter}
                extraFilter={extraFilter}
                handleClearStatuses={handleClearStatuses}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center py-10">
              <LoadingSpinner />
            </div>
          )}
          {/*SEARCHBAR*/}
          <AgoraSearchBar
            search={search}
            handleSearch={handleSearch}
            placeholder={'Search by name, e-mail, phone, universities...'}
          />

          <div className="flex flex-wrap gap-4">
            {/*STATUSES*/}
            <Select
              className="max-w-64"
              variant="secondary"
              label="Statuses"
              onSelect={handleAddStatus}
              onDeselect={handleRemoveStatus}
              onClear={handleClearStatuses}
              options={mentorStatuses}
              mode="multiple"
            />

            {/*COURSE*/}
            <Select
              className="max-w-64"
              variant="secondary"
              label="Course"
              onSelect={handleCourse}
              options={allCourses?.map((course) => ({
                value: course._id,
                label: course.title,
              }))}
              onClear={handleClearCourse}
            />
          </div>

          <div className="flex gap-6">
            <div className="flex items-center gap-2 mt-6">
              <Checkbox onChange={(e) => handleIsTestUser(e.target.checked)} checked={isTestUser} />

              <span className="text-white filter-label">Show Test Users</span>
            </div>
            <div className="flex items-center gap-2 mt-6">
              <Checkbox
                onChange={(e) => handleIsDeactivated()}
                indeterminate={isDeactivated === undefined}
                checked={isDeactivated}
              />

              <span className="text-white filter-label">Show Deactivated Users</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersTableInfo;
