import { useState, useRef, useCallback, CSSProperties } from 'react';

interface UseCanvasZoomPanOptions {
  containerWidth: number;
  containerHeight: number;
  contentWidth: number;
  contentHeight: number;
  minScale?: number;
  maxScale?: number;
  scaleStep?: number;
  doubleClickToReset?: boolean;
}

interface UseCanvasZoomPanReturn {
  transformStyle: CSSProperties;

  pointerDownHandler: (e: React.PointerEvent) => void;
  pointerMoveHandler: (e: React.PointerEvent) => void;
  pointerUpHandler: (e: React.PointerEvent) => void;
  doubleClickHandler: (e: React.MouseEvent) => void;

  zoomIn: (e: React.MouseEvent<HTMLButtonElement>) => void;
  zoomOut: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const clampTranslate = (
  translate: { x: number; y: number },
  scale: number,
  containerWidth: number,
  containerHeight: number,
  contentWidth: number,
  contentHeight: number
) => {
  const scaledWidth = contentWidth * scale;
  const scaledHeight = contentHeight * scale;

  let { x, y } = translate;

  if (scaledWidth <= containerWidth) {
    // center horizontally if smaller
    x = (containerWidth - scaledWidth) / 2;
  } else {
    const maxX = 0;
    const minX = containerWidth - scaledWidth;
    if (x > maxX) x = maxX;
    if (x < minX) x = minX;
  }

  if (scaledHeight <= containerHeight) {
    // center vertically if smaller
    y = (containerHeight - scaledHeight) / 2;
  } else {
    const maxY = 0;
    const minY = containerHeight - scaledHeight;
    if (y > maxY) y = maxY;
    if (y < minY) y = minY;
  }

  return { x, y };
};

const useCanvasZoomPan = (props: UseCanvasZoomPanOptions): UseCanvasZoomPanReturn => {
  const {
    containerWidth,
    containerHeight,
    contentWidth,
    contentHeight,
    minScale = 1,
    maxScale = 2,
    scaleStep = 0.1,
    doubleClickToReset = true,
  } = props;
  const [scale, setScale] = useState(1);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const lastTranslateRef = useRef({ x: 0, y: 0 });
  const startPointerRef = useRef({ x: 0, y: 0 });

  const clampAndSet = useCallback(
    (newTranslate: { x: number; y: number }, newScale: number) => {
      const clamped = clampTranslate(
        newTranslate,
        newScale,
        containerWidth,
        containerHeight,
        contentWidth,
        contentHeight
      );
      setTranslate(clamped);
      lastTranslateRef.current = clamped;
    },
    [containerWidth, containerHeight, contentWidth, contentHeight]
  );

  const zoomIn = useCallback(
    (e) => {
      e.stopPropagation();
      setScale((curr) => {
        let next = curr + scaleStep;
        if (next > maxScale) next = maxScale;
        clampAndSet(translate, next);
        return next;
      });
    },
    [scaleStep, maxScale, translate, clampAndSet]
  );

  const zoomOut = useCallback(
    (e) => {
      e.stopPropagation();

      setScale((curr) => {
        let next = curr - scaleStep;
        if (next < minScale) next = minScale;
        clampAndSet(translate, next);
        return next;
      });
    },
    [scaleStep, minScale, translate, clampAndSet]
  );

  const pointerDownHandler = useCallback((e: React.PointerEvent) => {
    (e.target as HTMLElement).setPointerCapture(e.pointerId);

    setIsDragging(true);

    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();

    // Store the starting mouse position relative to the element’s top-left
    startPointerRef.current = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
  }, []);

  const pointerUpHandler = useCallback(
    (e: React.PointerEvent) => {
      (e.target as HTMLElement).releasePointerCapture(e.pointerId);

      setIsDragging(false);

      lastTranslateRef.current = { ...translate };
    },
    [translate]
  );

  const pointerMoveHandler = useCallback(
    (e: React.PointerEvent) => {
      if (!isDragging) return;

      const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
      const currentX = e.clientX - rect.left;
      const currentY = e.clientY - rect.top;

      const dx = currentX - startPointerRef.current.x;
      const dy = currentY - startPointerRef.current.y;

      const newX = lastTranslateRef.current.x + dx;
      const newY = lastTranslateRef.current.y + dy;
      clampAndSet({ x: newX, y: newY }, scale);
    },
    [scale, isDragging, clampAndSet]
  );

  // Reset zoom
  const doubleClickHandler = useCallback(() => {
    if (!doubleClickToReset) return;
    setScale(1);
    setTranslate({ x: 0, y: 0 });
    lastTranslateRef.current = { x: 0, y: 0 };
  }, [doubleClickToReset]);

  const draggingCursor = isDragging ? 'grabbing' : 'grab';

  const transformStyle: CSSProperties = {
    transform: `translate(${translate.x}px, ${translate.y}px) scale(${scale})`,
    transformOrigin: 'top left',
    touchAction: 'none',
    userSelect: 'none',
    cursor: scale > 1 ? draggingCursor : undefined,
  };

  return {
    transformStyle,
    pointerDownHandler,
    pointerMoveHandler,
    pointerUpHandler,
    doubleClickHandler,
    zoomIn,
    zoomOut,
  };
};

export default useCanvasZoomPan;
