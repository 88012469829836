export enum enrollmentStatuses {
  'Upcoming' = 1,
  'Active' = 2,
  'Ended' = 3,
}

export const ENROLLMENT_STATUSES: Record<number, string> = {
  [enrollmentStatuses.Upcoming]: 'Inactive',
  [enrollmentStatuses.Active]: 'Active',
  [enrollmentStatuses.Ended]: 'Ended',
};

export const enrollmentStatusColors: { [key: number]: string } = {
  [enrollmentStatuses.Upcoming]: 'text-customGold',
  [enrollmentStatuses.Active]: 'text-customLime',
  [enrollmentStatuses.Ended]: 'text-customRed',
};
