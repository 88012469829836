import { ReactComponent as BlockIcon } from '@assets/icons/V4/block-icon.svg';
import { ReactComponent as FeedbackReceivedIcon } from '@assets/icons/V4/session-history/feedback-received-icon.svg';
import Table from '@components/V4/Table/Table';
import { SESSION_STATUSES } from '@shared/constants';
import { useAuthState } from '@contexts/AuthContext';
import { SessionHistorySessionDTO } from '@shared/types/DTOs/EnrollmentsDTO';
import { COLORS } from 'apps/agora/src/v3/global';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import moment from 'moment';
import { Link } from 'react-router-dom';
import RelevantSessionActionButtons from '../components/RelevantSessionActionButtons';
import { SessionHistoryListProps } from '../types';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import Tooltip from '@components/V4/Tooltip';

const SessionHistoryListDesktop = (props: SessionHistoryListProps) => {
  const {
    allSessions,
    pagination,
    sortBy,
    sortHandler,
    sortOrder,
    onStudentFeedbackOpen,
    onMentorFeedbackOpen,
    isLoading,
  } = props;

  const { isMentor } = useAuthState();

  const shouldShowFeedbackIcon = (session: SessionHistorySessionDTO) => {
    return (isMentor && !!session?.studentFeedback?.[0]) || (!isMentor && !!session?.feedbackDate);
  };

  return (
    <Table
      isUserView
      isLoading={isLoading}
      className="hidden w-full tablet:table"
      pagination={pagination}
    >
      <Table.Header>
        <Table.Row isHeader>
          <Table.HeaderCell
            onSort={(order) => sortHandler('name', order)}
            sortOrder={sortBy === 'name' ? sortOrder : undefined}
          >
            Course
          </Table.HeaderCell>
          <Table.HeaderCell
            onSort={(order) => sortHandler('sessionNo', order)}
            sortOrder={sortBy === 'sessionNo' ? sortOrder : undefined}
          >
            Session Number
          </Table.HeaderCell>
          <Table.HeaderCell>{isMentor ? 'Student' : 'Mentor'}</Table.HeaderCell>
          <Table.HeaderCell
            onSort={(order) => sortHandler('startDate', order)}
            sortOrder={sortBy === 'startDate' ? sortOrder : undefined}
          >
            Meeting Date & Time
          </Table.HeaderCell>
          {isMentor && (
            <Table.HeaderCell
              onSort={(order) => sortHandler('status', order)}
              sortOrder={sortBy === 'status' ? sortOrder : undefined}
            >
              Session Status
            </Table.HeaderCell>
          )}
          <Table.HeaderCell>Go To</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {!isLoading && !allSessions?.sessions?.length && (
          <tr>
            <td colSpan={6} className="h-64">
              <EmptyStateCard
                icon={<BlockIcon className="text-customGrey" />}
                text={'No results.'}
              />
            </td>
          </tr>
        )}

        {allSessions?.sessions?.map((session) => (
          <Table.Row key={session._id} isUserView>
            <Table.Cell className="rounded-l-2xl">
              <div className="flex items-center gap-4">
                <div
                  className={mergeClassNames(
                    'flex items-center justify-center w-10 h-10 font-raleway font-bold text-xs text-black rounded-lg',
                    {
                      'text-xxs':
                        session?.course?.abbreviation?.length &&
                        session?.course?.abbreviation?.length > 3,
                    }
                  )}
                  style={{
                    background: session?.course?.color ?? COLORS.PRIMARY,
                  }}
                >
                  {!!session?.course?.abbreviation &&
                  session?.course?.abbreviation !== 'NO_ABBREVIATION'
                    ? session?.course?.abbreviation
                    : 'n/a'}
                </div>
                {session?.course?.title ?? 'n/a'}
              </div>
            </Table.Cell>
            <Table.Cell>
              {session?.sessionNo ?? 'n/a'} of {session?.totalSessionNo ?? 'n/a'}
            </Table.Cell>
            <Table.Cell>
              <div className="flex gap-2">
                {isMentor && !!session?.students?.[0]?.fullName && (
                  <Link
                    className="text-customGrey underline hover:underline hover:text-customPrimary"
                    to={`/student-profile/${session?.students?.[0]?._id}`}
                  >
                    {session?.students?.[0]?.fullName}
                  </Link>
                )}
                {!isMentor && session?.mentor?.fullName}
                {shouldShowFeedbackIcon(session) && (
                  <Tooltip text="Feedback has been provided.">
                    <FeedbackReceivedIcon className="text-customGrey" />
                  </Tooltip>
                )}
              </div>
            </Table.Cell>
            <Table.Cell>
              {session.startDate ? moment(session.startDate).format('DD MMM YYYY, HH:mm') : 'n/a'}
            </Table.Cell>
            {isMentor && <Table.Cell>{SESSION_STATUSES[session.status]}</Table.Cell>}
            <Table.Cell className="rounded-r-2xl">
              <div className="flex gap-2">
                <RelevantSessionActionButtons
                  meeting={session.relevantMeeting}
                  session={session}
                  isMentor={isMentor}
                  onOpenFeedbackModal={
                    isMentor
                      ? () =>
                          onMentorFeedbackOpen(
                            `${session.name} / ${session.students?.[0]?.fullName}`,
                            session?.relevantMeeting?._id ?? ''
                          )
                      : () => onStudentFeedbackOpen(session?.relevantMeeting?._id ?? '')
                  }
                />
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default SessionHistoryListDesktop;
