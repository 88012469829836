import { ChevronDownIcon } from '@heroicons/react/outline';
import { ACCOUNT } from '@routes';
import styles from '@styles/components/header.module.scss';
import { Dropdown, Space } from 'antd';
import { useAuthActions, useAuthState } from '@contexts/AuthContext';
import { Link } from 'react-router-dom';

const AccountDropdown = () => {
  const { logout } = useAuthActions();
  const { fullName, googleDrive } = useAuthState();

  const userMenu = (
    <div className={styles.userMenu}>
      <Link to={ACCOUNT} className={styles.userMenuItem}>
        <span>My account</span>
      </Link>

      <Link
        to={{
          pathname: `https://drive.google.com/drive/folders/${googleDrive}`,
        }}
        target="_blank"
        className={styles.userMenuItem}
      >
        <span>My Files</span>
      </Link>

      <div onClick={logout} className={styles.userMenuItem}>
        <span>Log out</span>
      </div>
    </div>
  );

  return (
    <Dropdown overlay={userMenu} placement="bottomRight" trigger={['click']}>
      <Space style={{ cursor: 'pointer' }}>
        <span className={styles.userMenuName}>{fullName}</span>
        <ChevronDownIcon className={styles.icon} style={{ width: '16px', height: '16px' }} />
      </Space>
    </Dropdown>
  );
};

export default AccountDropdown;
