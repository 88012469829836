import { ReactComponent as PictureInPictureIcon } from '@assets/icons/V4/picture-in-picture-icon.svg';
import { ReactComponent as CameraIconActive } from '@assets/icons/video-icons/camera-icon-active.svg';
import { ReactComponent as CameraIcon } from '@assets/icons/video-icons/camera-icon.svg';
import { ReactComponent as EndCallIcon } from '@assets/icons/video-icons/end-call-icon.svg';
import { ReactComponent as HandIcon } from '@assets/icons/video-icons/hand-icon.svg';
import { ReactComponent as MicrophoneIconActive } from '@assets/icons/video-icons/microphone-icon-active.svg';
import { ReactComponent as MicrophoneIcon } from '@assets/icons/video-icons/microphone-icon.svg';

import { ReactComponent as OptionsIcon } from '@assets/icons/video-icons/options-icon.svg';
import { ReactComponent as ShareScreenIconActive } from '@assets/icons/video-icons/share-screen-icon-active.svg';
import { ReactComponent as ShareScreenIcon } from '@assets/icons/video-icons/share-screen-icon.svg';
import { ReactComponent as ThumbsUpIcon } from '@assets/icons/video-icons/thumbs-up-icon.svg';

import IconButton from '@components/V4/IconButton';
import Tooltip from '@components/V4/Tooltip';
import { ZoomContext } from '@modules/zoom/contexts/ZoomContext';
import { hasScreenShareCapability } from '@modules/zoom/utils/helpers';
import { CommandChannel, CommandChannelMsg } from '@zoom/videosdk';
import useToast from 'apps/agora/src/hooks/useToast';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { VideoMeetingModal } from './VideoMeetingModals/VideoMeetingModals';

interface VideoActionButtonProps {
  isCameraButtonLoading?: boolean;
  isMicrophoneButtonLoading?: boolean;
  isShareScreenDisabled?: boolean;
  isPiPDisabled?: boolean;
  isHandRaised: boolean;
  setActiveModal: React.Dispatch<React.SetStateAction<VideoMeetingModal>>;
  onCameraButtonClick: () => void;
  onMicrophoneButtonClick: () => void;
  onRaiseHandClick: (senderId: number, isRaised: boolean) => void;
  onThumbsUpClick: (senderId: number, senderName: string, timestamp: number) => void;
  onShareScreenClick: () => Promise<void>;
  preparePiP: () => Promise<void>;
  startPiP: () => Promise<void>;
}

type VideoActionButton = {
  icon: ReactNode;
  clickHandler?: () => void;
  mouseDownHandler?: () => void;
  mouseUpHandler?: () => void;
  touchStartHandler?: () => void;
  touchEndHandler?: () => void;
  isHandRaised?: boolean;
  isDanger?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
  className?: string;
  isDisabled?: boolean;
  hasWarning?: boolean;
  tooltipText: {
    active: string;
    inactive: string;
    disabled?: string;
  };
};

const VideoActionButtons = (props: VideoActionButtonProps) => {
  const {
    isCameraButtonLoading,
    isMicrophoneButtonLoading,
    isShareScreenDisabled,
    isPiPDisabled,
    isHandRaised,
    setActiveModal,
    preparePiP,
    startPiP,
    onRaiseHandClick,
    onCameraButtonClick,
    onMicrophoneButtonClick,
    onThumbsUpClick,
    onShareScreenClick,
  } = props;

  const { isMicrophoneActive, zoomClient, stream } = useContext(ZoomContext);

  const [isPiPActive, setIsPiPActive] = useState(false);

  const isPiPPreparedRef = useRef(false);

  const commandChannelRef = useRef<typeof CommandChannel>();

  const toast = useToast();

  useEffect(() => {
    if (!zoomClient) return;

    commandChannelRef.current = zoomClient.getCommandClient();
  }, []);

  const handleShowModal = (modalPage: 'end-meeting' | 'settings' | undefined) => {
    setActiveModal(modalPage);
  };

  const handleShareScreenButtonClick = async () => {
    try {
      await onShareScreenClick();
    } catch (error) {
      console.log(error);
    }
  };

  const handleHandButtonClick = async () => {
    if (!commandChannelRef.current || !zoomClient) return;

    try {
      const raisedHand = {
        type: 'hand-raise',
        playSound: true,
        isRaised: !isHandRaised,
      };

      const stringifiedRaisedHand = JSON.stringify(raisedHand);

      await commandChannelRef.current.send(stringifiedRaisedHand);

      const sessionInfo = zoomClient.getSessionInfo();

      onRaiseHandClick(sessionInfo.userId, !isHandRaised);
    } catch (error: any) {
      toast.error(error.type);
    }
  };

  const handleThumbsUpButtonClick = async () => {
    if (!commandChannelRef.current || !zoomClient) return;

    try {
      const command = (await commandChannelRef.current.send('thumbs-up')) as CommandChannelMsg;

      const sessionInfo = zoomClient.getSessionInfo();

      onThumbsUpClick(sessionInfo.userId, sessionInfo.userName, command.timestamp);
    } catch (error: any) {
      toast.error(error.type);
    }
  };

  useEffect(() => {
    const PiPExitHandler = () => {
      setIsPiPActive(false);
    };

    document.addEventListener('leavepictureinpicture', PiPExitHandler);

    return () => {
      document.removeEventListener('leavepictureinpicture', PiPExitHandler);
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      }
    };
  }, []);

  const PiPMouseDownHandler = async () => {
    try {
      if (document.pictureInPictureElement) {
        await document.exitPictureInPicture();
        isPiPPreparedRef.current = false;
      } else {
        await preparePiP?.();
        isPiPPreparedRef.current = true;
      }
    } catch (error) {
      setIsPiPActive(false);
      toast.error('Error starting Picture-In-Picture');
    }
  };

  const PiPMouseUpHandler = async () => {
    if (!isPiPPreparedRef.current) return;

    try {
      await startPiP();
      setIsPiPActive(true);
    } catch (error) {
      setIsPiPActive(false);
      console.log({ error });
    }
  };

  const handleOptionsButtonClick = () => {
    handleShowModal('settings');
  };

  const handleEndMeetingButton = () => {
    handleShowModal('end-meeting');
  };

  const videoActionButtons: VideoActionButton[] = [
    {
      icon: !stream?.isAudioMuted() ? <MicrophoneIcon /> : <MicrophoneIconActive />,
      isDanger: true,
      isLoading: isMicrophoneButtonLoading,
      clickHandler: onMicrophoneButtonClick,
      isActive: !isMicrophoneActive,
      tooltipText: {
        active: 'Unmute microphone',
        inactive: 'Mute microphone',
      },
    },
    {
      icon: stream?.isCapturingVideo() ? <CameraIcon /> : <CameraIconActive />,
      isLoading: isCameraButtonLoading,
      isDanger: true,
      clickHandler: onCameraButtonClick,
      isActive: !stream?.isCapturingVideo(),
      tooltipText: {
        active: 'Turn on Camera',
        inactive: 'Pause Camera',
      },
    },
    {
      icon:
        stream?.getShareStatus() !== 'sharing' ? <ShareScreenIcon /> : <ShareScreenIconActive />,
      clickHandler: handleShareScreenButtonClick,
      isActive: stream?.getShareStatus() === 'sharing',
      isDisabled: isShareScreenDisabled || !hasScreenShareCapability(),
      hasWarning: isShareScreenDisabled || !hasScreenShareCapability(),
      tooltipText: {
        active: 'Stop Screen Share',
        inactive: 'Screen Share',
      },
    },
    {
      icon: <PictureInPictureIcon />,
      mouseDownHandler: PiPMouseDownHandler,
      mouseUpHandler: PiPMouseUpHandler,
      touchStartHandler: PiPMouseDownHandler,
      touchEndHandler: PiPMouseUpHandler,
      isActive: isPiPActive,
      isDisabled: isPiPDisabled || (isShareScreenDisabled && !hasScreenShareCapability()),
      hasWarning: isPiPDisabled,
      tooltipText: {
        active: 'Stop Picture-In-Picture',
        inactive: 'Start Picture-In-Picture',
        disabled:
          'The other participant needs to be present in the meeting with their camera activated.',
      },
    },
    {
      icon: <HandIcon />,
      clickHandler: handleHandButtonClick,
      isActive: isHandRaised,
      tooltipText: {
        active: 'Lower Hand',
        inactive: 'Raise Hand',
      },
    },
    {
      icon: <ThumbsUpIcon />,
      clickHandler: handleThumbsUpButtonClick,
      tooltipText: {
        active: '',
        inactive: 'Thumbs Up',
      },
    },
    {
      icon: <OptionsIcon />,
      clickHandler: handleOptionsButtonClick,
      tooltipText: {
        active: '',
        inactive: 'More Settings',
      },
    },
    {
      icon: <EndCallIcon />,
      className:
        'w-14 h-8 bg-customRed text-surfaceHover hover:bg-customRedAccent tablet:w-16 tablet:h-10',
      clickHandler: handleEndMeetingButton,
      tooltipText: {
        active: '',
        inactive: 'End Meeting',
      },
    },
  ];

  const getTooltipText = (button: VideoActionButton) => {
    if (button.isDisabled) {
      return button.tooltipText.disabled;
    }

    if (button.isActive) {
      return button.tooltipText.active;
    }

    return button.tooltipText.inactive;
  };

  return (
    <div className="flex gap-2 laptop:gap-6">
      {videoActionButtons.map((button, index) => (
        <Tooltip text={getTooltipText(button)} placement="top" key={index}>
          <div>
            <IconButton
              icon={button.icon}
              variant="secondary"
              className={mergeClassNames('w-8 h-8 tablet:w-10 tablet:h-10', button.className)}
              hasWarning={button.hasWarning}
              isLoading={button.isLoading}
              isDisabled={button.isDisabled}
              onClick={button.clickHandler}
              onMouseDown={button.mouseDownHandler}
              onMouseUp={button.mouseUpHandler}
              onTouchStart={button.touchStartHandler}
              onTouchEnd={button.touchEndHandler}
              size="large"
              color={button.isDanger ? 'danger' : 'default'}
              isActive={button.isActive}
            />
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default VideoActionButtons;
