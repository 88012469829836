import Button from '@components/V4/Button';
import { SESSION_STATUSES, SessionStatus } from '@shared/constants';
import moment from 'moment';
import SessionDetailsLayoutCard from '../components/SessionDetailsLayoutCard';
import CourseAbbreviationCard from './CourseAbbreviationCard';
import DetailsHeaderParticipantsList from './DetailsHeaderParticipantsList';
import HeaderLabelAndValue from './HeaderLabelAndValue';
import { DetailsHeaderProps } from './SessionDetailsHeader';
import SessionDetailsNavigationArrows from './SessionDetailsNavigationArrows';
import SessionSummaryCard from './SessionSummaryCard';
import { convertSecondsToTime } from 'apps/agora/src/utils/helpers';
import { useAuthState } from '@contexts/AuthContext';
import { SessionDetailsContext } from 'apps/agora/src/views/UserView/SessionDetails/SessionDetails';
import { useContext } from 'react';
import SkeletonBox from '@components/V4/SkeletonBox';

const DetailsHeaderMobile = (props: DetailsHeaderProps) => {
  const {
    abbreviation,
    title,
    sessionSummary,
    agenda,
    scheduledDate,
    endDate,
    mentor,
    student,
    nextSession,
    prevSession,
    accentColor,
    meetingDuration,
    sessionStatus,
    setIsMentorFeedbackOpen,
    onMeetingModalOpen,
  } = props;

  const { isMentor } = useAuthState();
  const { isLoading } = useContext(SessionDetailsContext);

  return (
    <SessionDetailsLayoutCard className="gap-8 tablet:hidden">
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <CourseAbbreviationCard
            abbreviation={abbreviation}
            accentColor={accentColor}
            isLoading={isLoading}
          />

          <SessionDetailsNavigationArrows nextSession={nextSession} prevSession={prevSession} />
        </div>

        {isLoading ? (
          <>
            <SkeletonBox className="w-72 my-2 h-8" />
            <SkeletonBox className="w-20 h-8" />
          </>
        ) : (
          <>
            <h1 className="text-3.5xl font-raleway font-semibold" style={{ color: accentColor }}>
              {title}
            </h1>

            <div className="flex gap-2">
              <DetailsHeaderParticipantsList
                mentor={mentor}
                student={student}
                accentColor={accentColor}
              />
            </div>
          </>
        )}

        <div className="flex flex-col gap-3">
          <HeaderLabelAndValue
            isLoading={isLoading}
            label="End Date:"
            value={!!endDate ? moment(endDate).format('DD.MM.YYYY, HH:mm') : undefined}
          />

          {!endDate && !!scheduledDate && (
            <HeaderLabelAndValue
              label="Scheduled Date:"
              value={moment(scheduledDate).format('DD.MM.YYYY, HH:mm')}
            />
          )}

          {!isLoading && !endDate && !scheduledDate && isMentor && (
            <Button buttonText="Schedule Meeting" className="w-36" onClick={onMeetingModalOpen} />
          )}

          {isMentor && (
            <HeaderLabelAndValue
              isLoading={isLoading}
              label="Session Status:"
              value={!!sessionStatus ? SESSION_STATUSES[sessionStatus] : undefined}
            />
          )}

          {!!meetingDuration && (
            <HeaderLabelAndValue
              label="Meeting Duration:"
              value={convertSecondsToTime(meetingDuration ?? 0)}
            />
          )}

          {!!agenda && <HeaderLabelAndValue label="Agenda:" value={agenda} />}
        </div>
      </div>

      {isLoading ? (
        <SkeletonBox className="h-80 w-full rounded-lg" />
      ) : (
        <SessionSummaryCard
          needsFeedback={sessionStatus === SessionStatus.NEEDS_FEEDBACK}
          accentColor={accentColor}
          sessionSummary={sessionSummary}
          setIsMentorFeedbackOpen={setIsMentorFeedbackOpen}
        />
      )}
    </SessionDetailsLayoutCard>
  );
};

export default DetailsHeaderMobile;
