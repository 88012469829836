import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { ZoomContext } from '../contexts/ZoomContext';
import {
  Participant,
  CommandChannelMsg,
  ConnectionChangePayload,
  ConnectionState,
  CommandChannel,
  Stream,
  MediaDevice,
} from '@zoom/videosdk';
import {
  findRemovedDevicesById,
  mountDevices,
  stopTracks,
  subscribeToStatisticData,
  unsubscribeToStatisticData,
} from '../utils/helpers';
import { ChatMessage as ZoomChatMessage } from '@zoom/videosdk/dist/types/chat';
import { ChatMessage } from '../components/VideoMeeting/VideoSidebar/SidebarChatPage';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import useToast from 'apps/agora/src/hooks/useToast';
import { PageNameType } from '../types/PageNameType';
import { useHistory } from 'react-router-dom';

interface UseEventListenersProps {
  isHandRaised?: boolean;
  pageName?: PageNameType;
  micList: MediaDevice[];
  cameraList: MediaDevice[];
  backgroundSuppression: boolean;
  isCameraStateSwitchLoading: boolean;
  commandChannelRef: React.MutableRefObject<typeof CommandChannel | undefined>;
  timeoutRefs: React.MutableRefObject<Record<string, NodeJS.Timeout | undefined>>;
  screenShareViewCanvasContainerRef: React.RefObject<HTMLCanvasElement>;
  shouldMuteShareAudioRef: React.MutableRefObject<boolean>;
  onRaiseHandClick: (senderId: number, isRaised: boolean, playSound?: boolean) => void;
  onThumbsUpClick: (senderId: number, senderName: string, timestamp: number) => void;
  onCloseSidebar: () => void;
  onShowFeedbackModal: () => void;
  onCloseModal: () => void;
  reattachVideos: () => Promise<void>;
  renderShareScreen: (stream: typeof Stream) => Promise<void>;
  setParticipants: Dispatch<SetStateAction<Participant[]>>;
  setCameraList: Dispatch<SetStateAction<MediaDevice[]>>;
  setMicList: Dispatch<SetStateAction<MediaDevice[]>>;
  setNetworkQuality: Dispatch<SetStateAction<Record<number, { uplink: number; downlink: number }>>>;
  setUnreadCount: React.Dispatch<React.SetStateAction<number>>;
  setIsScreenShareLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsViewingScreenShare: React.Dispatch<React.SetStateAction<boolean>>;
}

const useEventListeners = (props: UseEventListenersProps) => {
  const {
    isHandRaised,
    micList,
    cameraList,
    pageName,
    timeoutRefs,
    isCameraStateSwitchLoading,
    backgroundSuppression,
    commandChannelRef,
    screenShareViewCanvasContainerRef,
    shouldMuteShareAudioRef,
    setMicList,
    setCameraList,
    onThumbsUpClick,
    onRaiseHandClick,
    onCloseSidebar,
    onShowFeedbackModal,
    onCloseModal,
    reattachVideos,
    renderShareScreen,
    setUnreadCount,
    setParticipants,
    setNetworkQuality,
    setIsScreenShareLoading,
    setIsViewingScreenShare,
  } = props;

  const {
    zoomClient,
    stream,
    activeMicrophone,
    isMicrophoneActive,
    isCameraActive,
    activeCamera,
    setActiveMicrophone,
    setActiveCamera,
    closeWebsocketConnection,
    setIsCameraActive,
  } = useContext(ZoomContext);

  const [speakingParticipants, setSpeakingParticipants] = useState<Record<number, boolean>>({});
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [connectionState, setConnectionState] = useState<ConnectionState>(
    ConnectionState.Connected
  );

  const shouldRestartCameraRef = useRef(false);
  const streamRef = useRef<MediaStream>();

  const toast = useToast();
  const { trackEvent } = useAnalytics();
  const history = useHistory();

  const params = new URLSearchParams(location.search);

  const reinitializeStatisticsListeners = async () => {
    if (!stream) return;

    await unsubscribeToStatisticData(stream);
    await subscribeToStatisticData(stream);
  };

  const removeDuplicateMe = (participants: Participant[]) => {
    const myUserInfo = zoomClient?.getCurrentUserInfo();

    return participants.filter(
      (participant) =>
        (participant.userGuid === myUserInfo?.userGuid &&
          participant.userId === myUserInfo?.userId) ||
        participant.userGuid !== myUserInfo?.userGuid
    );
  };

  const cleanupNetworkQuality = () => {
    if (!zoomClient) {
      return;
    }

    const participantIds = removeDuplicateMe(zoomClient.getAllUser()).map((user) => user.userId);

    setNetworkQuality((networkQuality) => {
      const newNetworkQuality = { ...networkQuality };

      Object.keys(networkQuality).forEach((userId: string) => {
        if (!participantIds.includes(parseInt(userId))) {
          delete newNetworkQuality[parseInt(userId)];
        }
      });

      return newNetworkQuality;
    });
  };

  useEffect(() => {
    if (!stream || !zoomClient) return;

    const userAddedHandler = () => {
      const participants = zoomClient.getAllUser();

      setParticipants(removeDuplicateMe(participants));
    };

    const userUpdatedHandler = async () => {
      const participants = zoomClient.getAllUser();
      const updatedSpeakingParticipants: Record<string, boolean> = {
        ...speakingParticipants,
      };

      participants.forEach((participant) => {
        if (speakingParticipants[participant.userId] && participant.muted) {
          updatedSpeakingParticipants[participant.userId] = false;
        }
      });

      try {
        const raisedHand = {
          type: 'hand-raise',
          isRaised: isHandRaised,
        };

        const stringifiedRaisedHand = JSON.stringify(raisedHand);

        await commandChannelRef.current?.send(stringifiedRaisedHand);
      } catch (error) {
        console.log({ error });
      }

      setParticipants(removeDuplicateMe(participants));
      setSpeakingParticipants(updatedSpeakingParticipants);
    };

    const userRemovedHandler = () => {
      const participants = zoomClient.getAllUser();

      setParticipants(removeDuplicateMe(participants));
    };

    const activeSpeakerHandler = (speakingUsers: { userId: number }[]) => {
      const updatedSpeakingParticipants = { ...speakingParticipants };

      for (const speakingUser of speakingUsers) {
        const userId = speakingUser.userId;

        updatedSpeakingParticipants[userId] = true;

        if (!!timeoutRefs && timeoutRefs.current[userId]) {
          clearTimeout(timeoutRefs.current[userId]);
        }

        timeoutRefs.current[userId] = setTimeout(() => {
          setSpeakingParticipants((prevParticipants) => ({
            ...prevParticipants,
            [userId]: false,
          }));
          timeoutRefs.current[userId] = undefined;
        }, 3000);
      }

      setSpeakingParticipants(updatedSpeakingParticipants);
    };

    const commandChannelHandler = async (payload: CommandChannelMsg) => {
      try {
        const parsedPayload = JSON.parse(payload.text);

        switch (parsedPayload.type) {
          case 'network-quality-change':
            setNetworkQuality((prev) => ({
              ...prev,
              [payload.senderId]: {
                ...prev[payload.senderId],
                [parsedPayload.direction]: parsedPayload.score,
              },
            }));
            break;
          case 'hand-raise': {
            onRaiseHandClick(payload.senderId, parsedPayload.isRaised, parsedPayload.playSound);
            break;
          }
        }
      } catch (error) {
        switch (payload.text) {
          case 'thumbs-up':
            onThumbsUpClick(payload.senderId, payload?.senderName || '', payload.timestamp);
            break;

          case 'reconnect':
            cleanupNetworkQuality();
            break;
        }
      }
    };

    const shareScreenHandler = async (payload: {
      state: 'Active' | 'Inactive';
      userId: number;
    }) => {
      if (!screenShareViewCanvasContainerRef.current) return;

      if (payload.state === 'Active') {
        try {
          setIsScreenShareLoading(true);
          setIsViewingScreenShare(true);

          await stream.startShareView(screenShareViewCanvasContainerRef.current, payload.userId);

          setIsScreenShareLoading(false);
        } catch (error: any) {
          setIsViewingScreenShare(false);
          setIsScreenShareLoading(false);

          trackEvent(ANALYTICS_EVENT_NAMES.SHARE_SCREEN_VIEW_FAIL, error.reason);

          toast.error('Cannot view share screen.');

          console.log(error);
        }
      } else if (payload.state === 'Inactive') {
        try {
          await stream.stopShareView();

          if (document.fullscreenElement) {
            try {
              await document.exitFullscreen();
            } catch (error) {}
          }

          setIsViewingScreenShare(false);
        } catch (error) {
          console.log(error);
        }
      }
    };
    // New chat message handler
    // NOTE: The 'chat-on-message' will also log when the current user sends a message
    // Thus this method will be called no matter who sent a new message.
    const newMessageHandler = (payload: ZoomChatMessage) => {
      const { sender, message, timestamp } = payload;

      const newChatMessage: ChatMessage = {
        author: sender.name,
        timeStamp: timestamp,
        message: message ?? '',
      };

      setChatMessages((prevMessages) => [...prevMessages, newChatMessage]);

      if (pageName !== 'chat') {
        setUnreadCount((prevCount) => prevCount + 1);
      }
    };

    const connectionChangeHandler = async (event: ConnectionChangePayload) => {
      setConnectionState(event.state);

      if (event.state === ConnectionState.Connected) {
        shouldRestartCameraRef.current = true;
      }

      if (event.reason === 'ended by host') {
        params.delete('inMeeting');

        history.replace({
          pathname: location.pathname,
          search: params.toString(),
        });

        closeWebsocketConnection();

        onCloseSidebar();

        onShowFeedbackModal();

        onCloseModal();
      }
    };

    const networkQualityChangeHandler = (payload: any) => {
      setNetworkQuality((prev) => ({
        ...prev,
        [payload.userId]: {
          ...prev[payload.userId],
          [payload.type]: payload.level,
        },
      }));

      const myUserId = zoomClient?.getSessionInfo().userId;

      if (payload.userId === myUserId) {
        const direction = payload.type;

        const networkQuality = {
          type: 'network-quality-change',
          direction,
          score: payload.level,
        };

        const stringifiedNetworkQuality = JSON.stringify(networkQuality);

        commandChannelRef.current?.send(stringifiedNetworkQuality);
      }
    };

    const commandChannelStatus = async (event: string) => {
      if (shouldRestartCameraRef.current && event === 'Connected') {
        //After reconnection, the userId changes, so we need to delete the old entry
        cleanupNetworkQuality();

        try {
          commandChannelRef.current?.send('reconnect');
          shouldRestartCameraRef.current = false;
        } catch (error) {
          console.log(error);
        }

        try {
          await stream.startAudio({
            microphoneId: activeMicrophone,
            backgroundNoiseSuppression: backgroundSuppression,
          });

          if (!isMicrophoneActive) {
            await stream.muteAudio();
          }
        } catch (error: any) {
          console.log(error);
        }

        reattachVideos();
        reinitializeStatisticsListeners();
        renderShareScreen(stream);
      }
    };

    const shareAudioChangeHandler = async () => {
      if (!shouldMuteShareAudioRef.current) return;

      shouldMuteShareAudioRef.current = false;

      const isMicrophoneAndShareAudioSimultaneouslySupported =
        !!stream?.isSupportMicrophoneAndShareAudioSimultaneously();

      const shareAudioStatus = stream?.getShareAudioStatus();

      if (
        shareAudioStatus?.isShareAudioEnabled &&
        !isMicrophoneAndShareAudioSimultaneouslySupported
      ) {
        if (shareAudioStatus?.isSharingAudio) {
          try {
            await stream?.muteShareAudio();
          } catch (error) {
            console.log({ error });
          }
        }

        toast.warning(
          'Tab audio and microphone audio cannot be shared simultaneously on this device/browser. To enable tab audio, open the settings modal. Note: While tab audio is active, your microphone will be muted for participants.'
        );
      }
    };

    const activeMediaFailedHandler = (payload: any) => {
      toast.error(payload.message);
    };

    zoomClient.on('active-media-failed', activeMediaFailedHandler);
    zoomClient.on('user-added', userAddedHandler);
    zoomClient.on('user-updated', userUpdatedHandler);
    zoomClient.on('user-removed', userRemovedHandler);
    zoomClient.on('active-speaker', activeSpeakerHandler);
    zoomClient.on(`command-channel-message`, commandChannelHandler);
    zoomClient.on(`command-channel-status`, commandChannelStatus);
    zoomClient.on('active-share-change', shareScreenHandler);
    zoomClient.on('chat-on-message', newMessageHandler);
    zoomClient.on('connection-change', connectionChangeHandler);
    zoomClient.on('network-quality-change', networkQualityChangeHandler);
    zoomClient.on('share-audio-change', shareAudioChangeHandler);

    return () => {
      zoomClient.off('active-media-failed', activeMediaFailedHandler);
      zoomClient.off('user-added', userAddedHandler);
      zoomClient.off('user-updated', userUpdatedHandler);
      zoomClient.off('user-removed', userRemovedHandler);
      zoomClient.off('active-speaker', activeSpeakerHandler);
      zoomClient.off(`command-channel-message`, commandChannelHandler);
      zoomClient.off(`command-channel-status`, commandChannelStatus);
      zoomClient.off(`active-share-change`, shareScreenHandler);
      zoomClient.off('chat-on-message', newMessageHandler);
      zoomClient.off('connection-change', connectionChangeHandler);
      zoomClient.off('network-quality-change', networkQualityChangeHandler);
      zoomClient.off('share-audio-change', shareAudioChangeHandler);
    };
  }, [
    isHandRaised,
    stream,
    connectionState,
    isCameraActive,
    isMicrophoneActive,
    location.pathname,
    isCameraStateSwitchLoading,
    activeMicrophone,
    backgroundSuppression,
  ]);

  useEffect(() => {
    if (!zoomClient || !stream) return;

    const deviceChangeHandler = async () => {
      const {
        cameras,
        microphones,
        stream: mountDevicesStream,
      } = await mountDevices(streamRef.current);

      streamRef.current = mountDevicesStream;

      if (microphones.length !== micList.length) {
        if (microphones.length < micList.length) {
          const removedMicrophone = findRemovedDevicesById(micList, microphones);
          if (activeMicrophone === removedMicrophone) {
            try {
              await stream.switchMicrophone(microphones?.[0].deviceId);
            } catch (error: any) {
              toast.error(error.reason);
            }
            setActiveMicrophone(microphones?.[0].deviceId);
          }
        }
        setMicList(microphones);
      }

      if (cameras.length !== cameraList.length) {
        if (cameras.length < cameraList.length) {
          const removedCamera = findRemovedDevicesById(cameraList, cameras);

          if (activeCamera === removedCamera) {
            setIsCameraActive(false);
            setActiveCamera(cameras?.[0].deviceId);
          }
        }
        setCameraList(cameras);
      }
    };

    zoomClient.on('device-change', deviceChangeHandler);

    return () => {
      stopTracks(streamRef.current);

      zoomClient.off('device-change', deviceChangeHandler);
    };
  }, [stream, cameraList, micList, activeCamera, activeMicrophone]);

  return { connectionState, speakingParticipants, chatMessages };
};

export default useEventListeners;
