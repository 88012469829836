import { Empty } from 'antd';
import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';
import { COLORS } from '../../../v3/global';
import './nps-distribution-card.styles.scss';
import { checkNaN } from 'apps/agora/src/utils/helpers';

const RADIAN = Math.PI / 180;

type Props = {
  problemTitle: string;
  problemRole: string;
  statistics: any;
  problemType: string;
};

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}) => {
  if (percent === 0) return null;

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      color={COLORS.TEXT_PRIMARY}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      className="nps-distribution-pie-chart-label"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PIE_COLORS = ['#DA4E18', '#E6CF68', '#60AD4E'];

const NPSDistributionCard = ({ statistics, problemTitle, problemRole, problemType }: Props) => {
  const rawData = [
    {
      name: 'Detractors',
      value: checkNaN(Number((statistics?.npsData?.detractors?.percentage * 100).toFixed(2))),
    },
    {
      name: 'Passives',
      value: checkNaN(Number((statistics?.npsData?.passives?.percentage * 100).toFixed(2))),
    },
    {
      name: 'Promoters',
      value: checkNaN(Number((statistics?.npsData?.promoters?.percentage * 100).toFixed(2))),
    },
  ];

  return (
    <div
      style={{
        minWidth: '224px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: `${COLORS.COMPONENT_BACKGROUND} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 1px 4px #15223214',
        padding: '16px 17px 4px 17px',
      }}
    >
      <div
        style={{
          width: 'calc(100% - 10px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/*FIRST ROW*/}
        <h4
          style={{ color: COLORS.TEXT_SECONDARY, marginBottom: '24px' }}
          className="nps-distribution-card-title"
        >
          {problemTitle} <span style={{ color: COLORS.TEXT_PRIMARY }}>{problemRole}</span>{' '}
          Distribution
        </h4>

        {/*GRAPH*/}
        <div style={{ marginBottom: '24px' }}>
          {rawData?.length === 0 || rawData.every((entry) => entry.value === 0) ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Empty description="No Data" />
            </div>
          ) : (
            <ResponsiveContainer width="100%" height={180}>
              <PieChart>
                <Pie
                  data={rawData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="transparent"
                  labelLine={false}
                >
                  {rawData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      stroke={PIE_COLORS[index % PIE_COLORS.length]}
                      strokeWidth={2}
                      fill={PIE_COLORS[index % PIE_COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>

        {/*GRAPH DETAILS*/}
        <div>
          {rawData.map((data, index) => {
            const colorIndex =
              index < COLORS.NPS_DETAILS.length ? index : COLORS.NPS_DETAILS.length - 1;

            return (
              <div
                key={`detail-${index}`}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginBottom: '16px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <div
                    style={{
                      minWidth: '12px',
                      maxWidth: '12px',
                      minHeight: '12px',
                      maxHeight: '12px',
                      borderRadius: '2px',
                      backgroundColor: PIE_COLORS[colorIndex],
                      marginRight: '8px',
                      marginTop: '4px',
                    }}
                  ></div>
                  <div>
                    <span
                      style={{ color: COLORS.TEXT_SECONDARY }}
                      className="nps-distribution-details-text"
                    >
                      {data.name}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <div style={{ marginBottom: '4px' }}>
                    <span
                      style={{
                        color: COLORS.TEXT_PRIMARY,
                        whiteSpace: 'nowrap',
                      }}
                      className="nps-distribution-details-value"
                    >
                      {data.value}%
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NPSDistributionCard;
