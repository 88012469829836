import CheckList from '@editorjs/checklist';
import Code from '@editorjs/code';
import Delimiter from '@editorjs/delimiter';
import Embed from '@editorjs/embed';
import Header from '@editorjs/header';
import InlineCode from '@editorjs/inline-code';
import LinkTool from '@editorjs/link';
import List from '@editorjs/list';
import Marker from '@editorjs/marker';
import Paragraph from '@editorjs/paragraph';
import Quote from '@editorjs/quote';
import Raw from '@editorjs/raw';
import SimpleImage from '@editorjs/simple-image';
import Table from '@editorjs/table';
import Warning from '@editorjs/warning';
import { message } from 'antd';
import ColorPlugin from 'editorjs-text-color-plugin';

const defaultQuiz = `{
"title": "Edit me",
  "correctAnswers": 1,
  "choices": [
    "First Choice",
    "Second Choice",
    "Third Choice"
  ]
}
`;

const validObject = (obj) => {
  return (
    typeof obj === 'object' &&
    typeof obj.title === 'string' &&
    typeof obj.correctAnswers === 'number' &&
    Array.isArray(obj.choices) &&
    obj.choices.length >= obj.correctAnswers &&
    obj.choices.every((el) => typeof el === 'string')
  );
};

class SimpleQuiz extends Raw {
  static get toolbox() {
    return {
      title: 'Simple Quiz',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/></svg>',
    };
  }

  constructor(props) {
    super(props);

    const { data } = props;
    this.data.html = data.code || defaultQuiz;

    if (validObject(data)) this.data.html = JSON.stringify(props.data, null, 4);
  }

  save(codeWrapper) {
    const value = codeWrapper.querySelector('textarea').value;
    try {
      return JSON.parse(value);
    } catch (e) {
      message.error('Please check the quiz format');
      return {
        code: value,
      };
    }
  }

  static get DEFAULT_PLACEHOLDER() {
    return defaultQuiz;
  }
}

export const EDITOR_JS_TOOLS = {
  embed: Embed,
  table: Table,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  list: List,
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  raw: Raw,
  header: Header,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
  Color: {
    class: ColorPlugin,
    config: {
      colorCollections: [
        '#FF1300',
        '#EC7878',
        '#9C27B0',
        '#673AB7',
        '#3F51B5',
        '#0070FF',
        '#03A9F4',
        '#00BCD4',
        '#4CAF50',
        '#8BC34A',
        '#CDDC39',
        '#FFF',
        '#000',
      ],
      defaultColor: '#FF1300',
      type: 'text',
    },
  },
  simpleQuiz: SimpleQuiz,
};
