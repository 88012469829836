import Pill from '@components/V4/Pill';
import { BasicProps } from 'apps/agora/src/utils/types';

interface DetailsSectionPillsProps extends BasicProps {
  pills: { text: string; className: string }[];
}

const DetailsSectionPills = (props: DetailsSectionPillsProps) => {
  const { pills } = props;

  if (!pills.length) {
    return <></>;
  }

  return (
    <div className="flex gap-4 w-full mt-4">
      {pills.map((pill) => (
        <Pill key={pill.text} isReadOnly size="small" text={pill.text} className={pill.className} />
      ))}
    </div>
  );
};

export default DetailsSectionPills;
