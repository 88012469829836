import { useContext, useEffect, useRef } from 'react';
import { ZoomContext } from '../contexts/ZoomContext';
import { Participant } from '@zoom/videosdk';
import useToast from 'apps/agora/src/hooks/useToast';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';

interface UsePiPProps {
  participants: Participant[];
}

const usePiP = (props: UsePiPProps) => {
  const { participants } = props;

  const { zoomClient } = useContext(ZoomContext);

  const videoPIPRef = useRef<HTMLVideoElement | null>(null);
  const pipCanvasStreamRef = useRef<MediaStream | null>(null);

  const toast = useToast();
  const { trackEvent } = useAnalytics();

  const participantsLength = zoomClient?.getAllUser()?.length || 0;

  const getPiPParticipants = () => {
    return participants.filter(
      (participant) => participant.userId !== zoomClient?.getSessionInfo()?.userId
    );
  };

  const captureCanvasStream = () => {
    if (participantsLength <= 1) return null;

    const otherParticipants = getPiPParticipants();

    try {
      const container = document.getElementById(
        `video-player-container-${otherParticipants?.[0]?.userId}`
      );

      if (!container) {
        return null;
      }

      const canvas = container.shadowRoot?.querySelector('canvas');

      if (!canvas) {
        return null;
      }

      if (typeof canvas.captureStream === 'function') {
        return canvas.captureStream();
      }
    } catch (error) {
      toast.error('Error starting Picture-In-Picture. (1)');
    }
    return null;
  };

  const pipCleanup = () => {
    videoPIPRef.current?.remove();
    pipCanvasStreamRef.current?.getTracks()?.forEach((track) => track?.stop());
  };

  const preparePIP = async () => {
    if (participantsLength <= 1) return;

    pipCanvasStreamRef.current = captureCanvasStream();

    if (!pipCanvasStreamRef.current) {
      document.exitPictureInPicture();
      return;
    }

    videoPIPRef.current = document.getElementById('videoForPiP') as HTMLVideoElement;

    if (!videoPIPRef.current) {
      const contentWrapper = document.querySelector('#video-content-wrapper');
      videoPIPRef.current = document.createElement('video');
      videoPIPRef.current.id = 'videoForPiP';
      videoPIPRef.current.className = `absolute left-0 top-0 -translate-y-1/2 opacity-0`;

      (videoPIPRef.current as HTMLVideoElement).autoplay = true;
      if (contentWrapper) contentWrapper.appendChild(videoPIPRef.current);
    }

    if (videoPIPRef.current instanceof HTMLVideoElement) {
      videoPIPRef.current.srcObject = pipCanvasStreamRef.current;

      if (!videoPIPRef.current) return;

      try {
        await videoPIPRef.current.play();
        videoPIPRef.current.addEventListener('leavepictureinpicture', pipCleanup, {
          once: true,
        });
      } catch (error) {
        pipCleanup();
        console.log(error);
      }
    } else {
      toast.error('Error starting Picture-In-Picture. (2))');
    }
  };

  const startPictureInPictureHandler = async () => {
    const video = document.getElementById('videoForPiP') as HTMLVideoElement;

    if (video) {
      try {
        await video.requestPictureInPicture();
      } catch (error: any) {
        trackEvent(ANALYTICS_EVENT_NAMES.PIP_FAIL, error.message);
        console.log({ error });
      }
    }
  };

  useEffect(() => {
    if (!zoomClient) return;

    const handleVideoChange = async (payload: { state: 'Active' | 'Inactive'; userId: string }) => {
      if (document.pictureInPictureElement && payload.state === 'Active') {
        try {
          await preparePIP();
          await startPictureInPictureHandler();
        } catch (error) {
          console.log({ error });
        }
      }
    };

    zoomClient.on('video-active-change', handleVideoChange);

    return () => {
      zoomClient.off('video-active-change', handleVideoChange);
    };
  }, [zoomClient, participants, participantsLength]);

  return { getPiPParticipants, preparePIP, startPictureInPictureHandler };
};

export default usePiP;
