import { userManagementApis } from '@shared/apis';
import { genericMutation, genericQueryWithParams } from './generics';

export const useSetActiveStatus = genericMutation<userManagementApis.SetActiveStatus>(
  userManagementApis.setActiveStatus
);

export const useChangeUserTestState = genericMutation<userManagementApis.ChangeUserTestState>(
  userManagementApis.changeUserTestState
);

export const useSendActivationLink = genericMutation<userManagementApis.SendActivationLink>(
  userManagementApis.sendActivationLink
);

export const useCheckDriveStatus = genericQueryWithParams<userManagementApis.CheckDriveStatus>(
  userManagementApis.checkDriveStatus
);
