import { LanguageExam } from '@shared/common';
import { useContext, useState } from 'react';
import { useDeleteProfileEntry } from '../../../hooks/useDeleteProfileEntry';
import ProfileMainSection from '../../ResumeSection';
import PastExamsCard from './PastExamsCard';
import PastExamsForm from './PastExamsForm';
import SkeletonBox from '@components/V4/SkeletonBox';
import { ProfileContext } from '../../../AccountProfile';

interface PastExamsSectionProps {
  languageExams: LanguageExam[];
  userId: string;
}

const PastExamsSection = (props: PastExamsSectionProps) => {
  const { languageExams, userId } = props;

  const { isLoading } = useContext(ProfileContext);

  const [isAddNewItem, setIsAddNewItem] = useState(false);

  const [deleteEntryHandler] = useDeleteProfileEntry({
    userId,
    profileProperty: 'languageExams',
  });

  const addNewItemCancelHandler = () => {
    setIsAddNewItem(false);
  };

  return (
    <ProfileMainSection
      title="Past Exams"
      onClick={() => setIsAddNewItem((prev) => !prev)}
      isButtonActive={isAddNewItem}
    >
      {isLoading ? (
        <SkeletonBox className="w-full h-[78px]" />
      ) : (
        <>
          {isAddNewItem && (
            <PastExamsForm userId={userId} isModeAddNew onCancel={addNewItemCancelHandler} />
          )}

          {!!languageExams.length &&
            languageExams.map((exam) => (
              <PastExamsCard
                key={exam._id}
                userId={userId}
                languageExam={exam}
                onDeleteEntry={deleteEntryHandler}
              />
            ))}
        </>
      )}
    </ProfileMainSection>
  );
};

export default PastExamsSection;
