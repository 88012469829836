import { LoadingOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { HoverShinyEffect } from '@components';
import { ErrorCodes } from '@shared/constants';
import { BASE_URL, USE_AUTH0 } from '@shared/frontendEnv';
import v3global from '@styles/v3/global-v3.module.scss';
import { Input, Alert } from 'antd';

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../../v3/global';
import apiClient from '@contexts/AuthContext/apiClient';
import useToast from '../../hooks/useToast';

const PreOnboardingPage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [id, setId] = useState<any>('');
  const [registrationToken, setRegistrationToken] = useState('');
  const [isRegistrationTokenValidated, setIsRegistrationTokenValidated] = useState(Boolean);
  const [error, setError] = useState();

  const toast = useToast();

  const [authFlag] = useState<boolean>(USE_AUTH0 === '1');

  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);

  const validatePassword = (password: string): string[] => {
    const errors: string[] = [];

    if (!password.trim()) {
      errors.push('"Password" cannot be empty.');
    }

    if (!confirmPassword.trim()) {
      errors.push('"Confirm Password" cannot be empty.');
    }

    if (password !== confirmPassword) {
      errors.push('Passwords do not match');
    }

    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long.');
    }

    // Check if the password contains at least one lowercase letter, one uppercase letter, one number, and one special character
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /\d/;
    const specialCharacterRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;

    if (!lowercaseRegex.test(password)) {
      errors.push('Password must contain at least one lowercase letter.');
    }

    if (!uppercaseRegex.test(password)) {
      errors.push('Password must contain at least one uppercase letter.');
    }

    if (!numberRegex.test(password)) {
      errors.push('Password must contain at least one number.');
    }

    if (!specialCharacterRegex.test(password)) {
      errors.push('Password must contain at least one special character.');
    }

    return errors;
  };

  const handlePasswordChange = (newPassword: string) => {
    setPassword(newPassword);
  };

  const checkURLquery = async () => {
    const params = new URLSearchParams(window.location.search);

    const id = params.get('id');
    const token = params.get('token');

    if (!id || !token) return;

    setId(id);
    setRegistrationToken(token);

    try {
      const response = await apiClient.get('/auth/validateRegistrationToken', {
        params: { id, token },
      });
      setRegistrationToken(response.data.registrationToken);
    } catch (err: any) {
      setRegistrationToken('');
      setError(err.response?.data?.error);
      toast.error(err.response?.data?.error?.message || err.message);
      console.error(err);
    } finally {
      setIsRegistrationTokenValidated(true);
    }
  };

  useEffect(() => {
    checkURLquery();
  }, []);

  const submitData = () => {
    const errors = validatePassword(password);
    setPasswordErrors(errors);

    if (errors.length > 0) {
      return;
    }

    setLoading(true);

    const data = {
      password,
      id,
      registrationToken,
    };

    apiClient
      .post('/auth/signup', data)
      .then(() => {
        if (!authFlag) {
          history.push('/');
        } else {
          toast.success('Account created successfully. Redirecting to login...');
          setTimeout(() => {
            history.push('/');
          }, 500);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || err.message);
        console.error(err);
        setLoading(false);
      });
  };

  const action = loading ? (
    <LoadingOutlined />
  ) : (
    <button
      className={`${v3global.buttonPrimary} onboarding-btn`}
      onClick={submitData}
      style={{
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span>Get started</span>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '8px',
        }}
      >
        <ArrowRightOutlined /> <HoverShinyEffect />
      </span>
    </button>
  );

  if (!isRegistrationTokenValidated) {
    return (
      <div id="onboarding-container">
        <div id="onboarding-info">
          <h3 className="mb-8">Validating registration token...</h3>
        </div>
      </div>
    );
  }

  if (error || !registrationToken) {
    return ErrorPage(error);
  }

  return (
    <div className={v3global.background} style={{ minHeight: '100vh' }} id="onboarding-container">
      <div id="onboarding-info">
        <h2 className={v3global.text}>Welcome to Agora!</h2>
        <h3 className={v3global.text}>We're glad to have you here!</h3>
        <div id="onboarding-form" style={{ textAlign: 'left', marginTop: '30px' }}>
          <span>*Password:</span>
          <Input.Password
            style={{ marginBottom: '16px' }}
            placeholder="Password"
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
          />

          <span>*Confirm password:</span>
          <Input.Password
            style={{ marginBottom: '16px' }}
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          {passwordErrors.length > 0 && (
            <ul>
              {passwordErrors.map((error, index) => (
                <li style={{ color: COLORS.ERROR }} key={index}>
                  {error}
                </li>
              ))}
            </ul>
          )}
        </div>
        <br style={{ marginBottom: 10 }} />
        {action}
        <br style={{ marginBottom: 50 }} />
        <Alert
          type="info"
          description={
            <>
              If you're having trouble creating your account and need urgent access, please get in
              touch with us and we'll sort it.
            </>
          }
          showIcon
        />
      </div>
    </div>
  );
};

const ErrorPage = (error: any) => {
  if (error?.code === ErrorCodes.USER_ALREADY_ACTIVATED) {
    return (
      <div id="onboarding-container">
        <div id="onboarding-info">
          <h3 className="mb-8">
            <a href={`${BASE_URL}`}>
              The account has already been activated. Please try signing in with your password.
            </a>
          </h3>
          <Alert
            type="info"
            description={
              <>
                If you're having trouble logging in and need to urgently access your account, please
                get in touch with us and we'll sort it.
              </>
            }
            showIcon
          />
        </div>
      </div>
    );
  }

  if (error?.code === ErrorCodes.USER_IS_DEACTIVATED) {
    return (
      <div id="onboarding-container">
        <div id="onboarding-info">
          <h3 className="mb-8">
            <a href={`${BASE_URL}`}>
              The account has been deactivated. Please get in touch with us to reactivate your
              account.
            </a>
          </h3>
        </div>
      </div>
    );
  }

  if (error.code === ErrorCodes.USER_NOT_FOUND) {
    return (
      <div id="onboarding-container">
        <div id="onboarding-info">
          <h3 className="mb-8">
            <a href={`${BASE_URL}`}>
              The user account does not exist. Please try with a valid link.
            </a>
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div id="onboarding-container">
      <div id="onboarding-info">
        <h3 className="mb-8">
          <a href={`${BASE_URL}`}>
            There was an error while processing your request. Please try again later.
          </a>
        </h3>
      </div>
    </div>
  );
};

export default PreOnboardingPage;
