import Select from '@components/V4/Select/Select';
import { SESSION_STATUSES } from '@shared/constants';
import { useAuthState } from '@contexts/AuthContext';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { statusTypes } from '../constants';
import { SessionHistoryFiltersChildProps } from './SessionHistoryFilters';
import SkeletonBox from '@components/V4/SkeletonBox';

const SessionHistoryFiltersDesktop = (props: SessionHistoryFiltersChildProps) => {
  const {
    isLoading,
    courseFilterOptions,
    searchParams,
    courseChangeHandler,
    studentHandler,
    statusHandler,
    studentsFilterOptions,
    getSelectedStudent,
  } = props;

  const { isMentor } = useAuthState();

  return (
    <>
      {!isMentor && (
        <div className="hidden flex-wrap gap-4 tablet:flex">
          {isLoading ? (
            <>
              {[...Array(10).keys()].map((_, index) => (
                <SkeletonBox key={index} className="h-[29px] w-36 rounded-full" />
              ))}
            </>
          ) : (
            <>
              {courseFilterOptions.map((filter) => (
                <p
                  key={filter._id}
                  onClick={() => courseChangeHandler(filter._id)}
                  className={mergeClassNames(
                    'cursor-pointer py-1 px-4 bg-surfaceHover rounded-full',
                    {
                      'bg-customPrimary text-black':
                        filter._id === searchParams.course ||
                        (filter._id === '' && searchParams.course === null),
                    }
                  )}
                >
                  {filter.title}
                </p>
              ))}
            </>
          )}
        </div>
      )}
      <section
        className={mergeClassNames('hidden tablet:flex gap-4 tablet:flex-row tablet:flex-wrap', {
          'tablet:hidden': !isMentor,
        })}
      >
        {isMentor && (
          <>
            <Select
              variant="secondary"
              className="tablet:max-w-64 tablet:min-w-52"
              label="Course"
              options={courseFilterOptions.map(({ _id, title }) => ({
                label: title,
                value: _id,
              }))}
              value={
                searchParams.course ?? {
                  value: courseFilterOptions[0]._id,
                  label: courseFilterOptions[0].title,
                }
              }
              onSelect={(value) => courseChangeHandler(value as string)}
            />
            <Select
              variant="secondary"
              className="tablet:max-w-64 tablet:min-w-52"
              label="Students"
              options={studentsFilterOptions.map(({ _id, fullName }) => ({
                value: _id,
                label: fullName,
              }))}
              value={getSelectedStudent(searchParams.student)?.fullName ?? 'All Students'}
              onSelect={(value) => studentHandler(value as string)}
            />
            <Select
              variant="secondary"
              className="tablet:max-w-64 tablet:min-w-52"
              label="Session Status"
              options={statusTypes}
              value={SESSION_STATUSES[searchParams.status] ?? statusTypes[0]}
              onSelect={(value) => statusHandler(value as number)}
            />
          </>
        )}
      </section>
    </>
  );
};

export default SessionHistoryFiltersDesktop;
